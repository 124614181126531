import React from "react";
import { View, StyleSheet } from "react-native";

import * as Animatable from 'react-native-animatable'


const CardPostUserPlaceholder = ({ withImage }) => {

  const loadP = { 0: { opacity: 1 }, .5: { opacity: .4 }, 1: { opacity: 1 } }

  return (
    <Animatable.View animation={loadP} iterationCount={"infinite"} direction="alternate" style={[styles.shadow, { marginTop: 20, width: "100%" }]}
    >
      <View style={styles.card}>
        <View style={styles.userInfoContainer}>
          <View style={{ flexDirection: "row", alignItems: "center", flex: 1 }}>

            <Animatable.View duration={1000} animation={loadP} easing="linear" iterationCount="infinite" style={[styles.userImage, { backgroundColor: "gainsboro" }]} />

            <View style={{ marginLeft: 10, justifyContent: "center" }}>
              <Animatable.View duration={1000} animation={loadP} easing="linear" iterationCount="infinite" style={{ height: 40, marginHorizontal: 10, borderRadius: 35, backgroundColor: "gainsboro" }} />
              <Animatable.View duration={1000} animation={loadP} easing="linear" iterationCount="infinite" style={{ height: 20, marginTop: 5, marginHorizontal: 10, borderRadius: 7, backgroundColor: "gainsboro" }} />
            </View>
          </View>
        </View>


        {withImage && <Animatable.View duration={1000} animation={loadP} easing="linear" iterationCount="infinite" style={{ height: 200, marginTop: 5, marginHorizontal: 10, borderRadius: 7, backgroundColor: "gainsboro" }} />}

        {/* Contenido del Post */}
        <View style={{ paddingHorizontal: 10, paddingVertical: 20 }}>
          <Animatable.View duration={1000} animation={loadP} easing="linear" iterationCount="infinite" style={{ height: 15, marginTop: 5, marginHorizontal: 10, borderRadius: 7, backgroundColor: "gainsboro" }} />
          <Animatable.View duration={1000} animation={loadP} easing="linear" iterationCount="infinite" style={{ height: 15, marginTop: 5, marginRight: 30, borderRadius: 7, backgroundColor: "gainsboro" }} />
          <Animatable.View duration={1000} animation={loadP} easing="linear" iterationCount="infinite" style={{ height: 15, marginTop: 5, marginLeft: 35, borderRadius: 7, backgroundColor: "gainsboro" }} />
        </View>

      </View>
    </Animatable.View>
  );
};

export default CardPostUserPlaceholder;

const styles = StyleSheet.create({

  shadow: {
    elevation: 10,
    borderRadius: 15,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 3
    },
    borderWidth: 0,
    shadowOpacity: 0.15,
    shadowRadius: 7,
  },
  userInfoContainer: {
    padding: 10,
    flexDirection: "row",
    height: 60,
    borderBottomColor: "gainsboro",
    justifyContent: "space-between",
    borderBottomWidth: 1,
  },
  userImage: {
    borderRadius: 20,
    height: 40,
    width: 40
  },
  card: {
    width: "100%",
    backgroundColor: "white",
    borderRadius: 12
  },

});
