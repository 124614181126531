import React, { useState, useContext } from "react";
import {
  View,
  TouchableOpacity,
  Image,
  TextInput,
  Platform,
  StyleSheet,
  Text,
  Dimensions
} from "react-native";
import ImageRel from '../common/ImageRel'
import { Ionicons } from "@expo/vector-icons";
import AuthContext from "../../context/AuthContext";

let width = Dimensions.get("window").width;

const EditProfileTraining = ({

  userImage,
  form,
  setForm,
  writingCallback,
  imageCallback,
  imageProfile,
  team,
  position,
  bio,
  skills,
  hobbies, 
  disabled
}) => {

  const {customization} = useContext(AuthContext)
  const [sk, setSk] = useState("")
  const [hb, setHb] = useState("")

  return (
    <View style={{ flex: 1 }}>


      <View style={{ flex: 1, paddingTop: 0, paddingBottom: 50 }}>
        {imageProfile &&
          <TouchableOpacity
            onPress={() => imageCallback()}
            style={[
              styles.shadow,
              {
                backgroundColor: "white",
                marginVertical: 10,
                borderRadius: 15,
                width: Platform.OS === "web" ? "100%" : null,
                maxWidth: 430
              }
            ]}
          >
            <ImageRel
              style={{ width: null, height: 200, borderRadius: 15 }}
              image={userImage}
            />
            <View
              style={{
                position: "absolute",
                alignItems: "center",
                justifyContent: "center",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                borderRadius: 15,
                backgroundColor: "rgba(0,0,0,.4)"
              }}
            >
              <Ionicons size={80} color={"white"} name={"md-images"} />
              <Text style={{ fontFamily: "DemiBold", color: "white", fontSize: 18 }}>Cambiar Imagen</Text>
            </View>
          </TouchableOpacity>
        }

        <View style={{ borderRadius: 20, backgroundColor: "#f5f5f5", paddingHorizontal: 10, paddingVertical: !team && !bio && !imageProfile && !position ? 0 : 20 }}>
          {/* <TextInput
            placeholder="Nombre"
            value={form.name}
            onChangeText={name => setForm({ ...form, name })}
            style={styles.input}
          />
          <TextInput
            placeholder="Apellidos"
            value={form.surname}
            onChangeText={surname => setForm({ ...form, surname })}
            style={styles.input}
          /> */}
          {team &&
            <View>
              <Text style={{ marginLeft: 10, fontSize: 16, fontFamily: "DemiBold", marginBottom: 10 }}>Equipo</Text>
              <TextInput
                editable={disabled}
                placeholder="Equipo"
                value={form.team}
                onChangeText={team => setForm({ ...form, team })}
                style={styles.input}
              />
            </View>
          }
          {position &&
            <View>
              <Text style={{ marginLeft: 10, fontSize: 16, fontFamily: "DemiBold", marginVertical: 10 }}>Puesto</Text>
              <TextInput
                editable={disabled}
                placeholder="Puesto"
                value={form.position}
                onChangeText={position => setForm({ ...form, position })}
                style={styles.input}
              />
            </View>
          }
          {bio &&
            <View>
              <Text style={{ marginLeft: 10, fontSize: 16, fontFamily: "DemiBold", marginVertical: 10 }}>Sobre Mi</Text>
              <TextInput
                editable={disabled}
                placeholder="bio"
                value={form.bio}
                multiline
                maxLength={250}
                onChangeText={bio => setForm({ ...form, bio })}
                style={[styles.input, { height: 130, paddingTop: 15, lineHeight: 20 }]}
              />
              <Text style={{ fontFamily: "Regular", fontSize: 10, textAlign: "right", marginTop: 5, color: form.bio ? form.bio.length <= 200 ? "gray" : form.bio.length >= 225 ? "red" : "orange" : "gray", marginRight: 10 }}>{form.bio ? form.bio.length : 0} de 250</Text>
            </View>
          }
        </View>

        <View style={{ paddingHorizontal:10, paddingTop: !team && !bio && !imageProfile && !position ? 30 : 0 }}>
          {skills &&
            <View>
              <Text style={{ marginLeft: 10, fontSize: 16, fontFamily: "DemiBold", marginVertical: 10 }}>Mis Habilidades</Text>
              <View style={{ flexDirection: "row", marginTop: 10, alignItems: "center" }}>
                <TextInput
                  editable={disabled}
                  placeholder="Habilidades"
                  value={sk}
                  onFocus={ev => writingCallback(true)}
                  onBlur={ev => writingCallback(false)}
                  onChangeText={ev => setSk(ev)}
                  onSubmitEditing={() => {
                    setForm({ ...form, skills: (typeof form.skills === "string" ? form.skills : "") + (["", null].includes(form.skills) ? "" : ",") + sk.trim() }),
                      setSk("")
                  }}
                  style={[styles.input, { marginTop: 0, flex: 1 }]}
                />
                <TouchableOpacity onPress={() => {
                  sk !== "" && (
                    setForm({ ...form, skills: form.skills + (form.skills === "" ? "" : ",") + sk }),
                    setSk("")
                  )
                }}
                  style={{ height: 50, width: 50, alignItems: "center", justifyContent: "center" }}>
                  <Ionicons name="md-add-circle" color="gray" size={40} />
                </TouchableOpacity>
              </View>
              <View style={{ flexDirection: "row", flexWrap: "wrap", marginTop: 10, marginBottom: 20 }}>
                {(form && form.skills) ? form.skills.split(',').map((el, i) => {
                  return (
                    <TouchableOpacity onPress={() => setForm({ ...form, skills: form.skills.replace([form.skills.split(',').length === 1 ? `${el}` : i === 0 ? `${el},` : `,${el}`], "") })} key={i} style={{ flexDirection: "row", height: 35, alignItems: "center", margin: 5, borderRadius: 18, justifyContent: "center", backgroundColor: customization.buttonMainColor, paddingHorizontal: 15, }}>
                      <Text style={{ fontSize: 16, fontFamily: "DemiBold", color: customization.buttonMainColorText }}>{el}</Text>
                      <Ionicons name="md-close" color={customization.buttonMainColorText} style={{ marginLeft: 10, marginTop: 2 }} size={20} />
                    </TouchableOpacity>
                  )
                }) : <View style={{ height: 35, marginLeft: 10, alignItems: "center", paddingHorizontal: 20, borderRadius: 18, backgroundColor: "darkgray", justifyContent: "center" }}><Text style={{ color: "white", fontSize: 16, fontFamily: "Regular" }}>Sin Aficiones</Text></View>
                }
              </View>
            </View>
          }
          {hobbies &&
            <View>
              <Text style={{ marginLeft: 10, fontSize: 16, fontFamily: "DemiBold", marginVertical: 10 }}>Mis Hobbies</Text>
              <View style={{ flexDirection: "row", marginTop: 10, alignItems: "center" }}>
                <TextInput
                  placeholder="Aficiones"
                  value={hb}
                  editable={disabled}
                  onFocus={ev => writingCallback(true)}
                  onBlur={ev => writingCallback(false)}
                  onChangeText={ev => setHb(ev)}
                  onSubmitEditing={() => {
                    setForm({ ...form, hobbies: (typeof form.hobbies === "string" ? form.hobbies : "") + (["", null].includes(form.hobbies) ? "" : ",") + hb.trim() }),
                      setHb("")
                  }}
                  style={[styles.input, { marginTop: 0, flex: 1 }]}
                />
                <TouchableOpacity onPress={() => {
                  hb !== "" && (
                    setForm({ ...form, hobbies: form.hobbies + (form.hobbies === "" ? "" : ",") + hb.trim() }),
                    setHb("")
                  )
                }}
                  style={{ height: 50, width: 50, alignItems: "center", justifyContent: "center" }}>
                  <Ionicons name="md-add-circle" color="gray" size={40} />
                </TouchableOpacity>
              </View>

              <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
                {(form && form.hobbies) ? form.hobbies.split(',').map((el, i) => {
                  return (
                    <TouchableOpacity onPress={() => setForm({ ...form, hobbies: form.hobbies.replace([form.hobbies.split(',').length === 1 ? `${el}` : i === 0 ? `${el},` : `,${el}`], "") })} key={i} style={{ flexDirection: "row", height: 35, alignItems: "center", margin: 5, borderRadius: 18, justifyContent: "center", backgroundColor: customization.buttonMainColor, paddingHorizontal: 15, }}>
                      <Text style={{ fontSize: 16, fontFamily: "DemiBold", color: customization.buttonMainColorText }}>{el}</Text>
                      <Ionicons name="md-close" color={customization.buttonMainColorText} style={{ marginLeft: 10, marginTop: 2 }} size={20} />
                    </TouchableOpacity>
                  )
                }) : <View style={{ height: 35, marginTop: 10, marginLeft: 10, alignItems: "center", paddingHorizontal: 20, borderRadius: 18, backgroundColor: "darkgray", justifyContent: "center" }}><Text style={{ color: "white", fontSize: 16, fontFamily: "Regular" }}>Sin Hobbies</Text></View>
                }
              </View>
            </View>
          }
        </View>
      </View>
    </View >
  );
};

const styles = StyleSheet.create({
  input: {
    borderWidth: 1,
    borderColor: "#C6C6C6",
    height: 50,
    backgroundColor: "white",
    borderRadius: 25,
    alignItems: "center",
    paddingHorizontal: 15,
    marginVertical: 5,
  },
  shadow: {
    shadowColor: "rgba(0, 0, 0, 0.3)",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.4,
    shadowRadius: 3,
    elevation: 10,
    backgroundColor: "white",
    borderRadius: 15
  }
});

export default EditProfileTraining;
