import React, { useContext, useEffect, useState } from "react";

import Svg, { Text as TextSvg, Line as LineSvg, Circle } from "react-native-svg";
import { View, Text, Platform, Image, StyleSheet, Dimensions, TouchableOpacity, InteractionManager, unstable_batchedUpdates } from "react-native";
import LinearBarIndicator from "../LinearBarIndicator";
import { firestore } from "firebase";

import HTML from "react-native-render-html";
import Modal from "../common/Modal";
import { Ionicons } from "@expo/vector-icons";
import { CurrentRenderContext, useNavigation } from "@react-navigation/native";
import { ScrollView } from "react-native-gesture-handler";
import AuthContext from "../../context/AuthContext";

const PathContainer = ({ data }) => {
	const {customization} = useContext(AuthContext)
	const [pathWidth, setPathWidth] = useState(0);
	const [visible, setVisible] = useState(false);

	const [loading, setLoading] = useState(false);
	const [percentage, setPercentage] = useState(0);

	const navigation = useNavigation();

	const [selectedSection, setSelectedSection] = useState({
		name: "",
		description: "",
		content: [],
		sectionIndex: 0,
		advanceRef: data?.advanceID || "",
	});

	const handleActiveModal = (selected, index) => {
		const s = {
			name: selected.name,
			description: selected.description,
			contents: selected.contents,
			image: selected.image,
			sectionIndex: index,
			advanceRef: data?.id,
		};
		setSelectedSection(s);
		setVisible(true);
	};

	const checkIfCompleted = (section) => {
		return data?.userAdvance?.some((completed) => section?.contents?.some((section) => section.id === completed?.id));
	};

	const swapIcon = (iconName) => {
		switch (iconName) {
			case "TEXT":
				return { icon: "book-outline", name: "Lectura" };
			case "LINK":
				return { icon: "link-outline", name: "Enlace" };
			case "VIDEO":
				return { icon: "videocam-outline", name: "Video" };
			case "EMBEDDED":
				return { icon: "logo-youtube", name: "Youtube" };
			case "AUDIO":
				return { icon: "ear-outline", name: "Audio" };
			case "WEBINAR":
				return { icon: "desktop-sharp", name: "Webinar" };
			case "EVENT":
				return { icon: "calendar-sharp", name: "Evento" };
			case "UPLOAD":
				return { icon: "cloud-upload", name: "Subir" };
			case "TEST":
				return { icon: "document-text", name: "Encuesta" };
			case "EXAM":
				return { icon: "medal", name: "Cuestionario" };
			default:
				return { icon: "medal", name: "Cuestionario" };
		}
	};

	console.log(selectedSection)

	if (loading) {
		return null;
	}

	return (
		<>
			<View style={[styles.shadow, { width: "95%", alignSelf: "center", backgroundColor: "white", borderRadius: 7, borderTopColor: "#644274", borderTopWidth: 8, padding: 15, justifyContent: "space-between" }]}>
				<Text style={{ color: "black", fontSize: 20, fontFamily: "DemiBold" }}>{data?.name}</Text>
				<HTML baseFontStyle={{ fontFamily: "Regular", margin: 0, fontSize: 14, color: customization.mainColor }} html={`<p>${data?.description}</p>`} />
				<View style={{ flexDirection: "row", alignItems: "center" }}>
					<Text style={{ fontFamily: "DemiBold", fontSize: 14, color: "gray", marginRight: 10 }}>Progreso</Text>
					<View style={{ flex: 1 }}>
						<LinearBarIndicator backgroundColor="gainsboro" width={"100%"} rounded={8} height={6} gradient={["#B4EC51", "#429321"]} percentage={percentage} />
					</View>
				</View>
			</View>
			<View
				style={{ marginTop: 50, marginHorizontal: 20 }}
				onLayout={({
					nativeEvent: {
						layout: { width },
					},
				}) => setPathWidth(width)}>
				{data?.contentDetails.length &&
					data?.contentDetails?.map((section, i) => {
						const { name } = section;
						return (
							<View key={i} style={{ width: "100%", height: 170, marginTop: -10, position: "relative" }}>
								<View style={{ width: "100%", height: 170, position: "relative" }}>
									<Svg width="100%" height="180px" style={{ position: "absolute" }}>
										<LineSvg x1="85" y1="5" x2={pathWidth - (i % 2 == 1 ? 75 : 85)} y2="5" stroke={checkIfCompleted(section) ? customization.mainColor : "gainsboro"} strokeDasharray={"1 17"} strokeLinecap="round" strokeWidth="10" fill="none" />
										<Svg width="85" height="100%" x={i % 2 == 1 ? pathWidth - 90 : null}>
											<Circle cx={i % 2 == 1 ? "0" : "85"} cy="85" r="80" stroke={checkIfCompleted(section) ? customization.mainColor : "gainsboro"} strokeDasharray={"1 17"} strokeLinecap="round" strokeWidth="10" fill="none" />
										</Svg>
									</Svg>
									<TouchableOpacity onPress={() => handleActiveModal(section, i)} style={{ flexDirection: "row", alignItems: "center", flex: 1 }}>
										{i % 2 == 0 ? (
											<Image key={i} style={{ marginLeft: 30, height: 120, width: 120, borderRadius: 60 }} source={{ uri: section.image ? section.image : "https://images.pexels.com/photos/4069292/pexels-photo-4069292.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" }} />
										) : null}
										<View style={{ marginHorizontal: 10, flex: 1 }}>
											<Text style={i % 2 == 1 ? { marginRight: 10, fontFamily: "Bold", fontSize: 20, textAlign: "right" } : { marginRight: 10, fontFamily: "Bold", fontSize: 20 }}>{name}</Text>
											<Text style={i % 2 == 1 ? { marginRight: 15, fontFamily: "Bold", color: "grey", fontSize: 18, marginTop: 5, textAlign: "right" } : { marginRight: 15, fontFamily: "Bold", color: "grey", fontSize: 18, marginTop: 5 }}>
												{section.contents.reduce((acc, cur) => acc + (cur.sectionPoints ? parseInt(cur.sectionPoints) : 0), 0)} puntos
											</Text>
										</View>
										{i % 2 == 1 ? (
											<Image key={i} style={{ marginRight: 40, height: 120, width: 120, borderRadius: 60 }} source={{ uri: section.image ? section.image : "https://images.pexels.com/photos/4069292/pexels-photo-4069292.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" }} />
										) : null}
									</TouchableOpacity>
								</View>
							</View>
						);
					})}
			</View>

			{visible && (
				<Modal onClickOutside={() => setVisible(false)} padding={0}>
					<View style={{ maxWidth: 580, width: "90%", paddingBottom: 30 }}>
						<Image style={{ resizeMode: "cover", borderTopLeftRadius: 10, borderTopRightRadius: 15, width: 580, height: 230 }} source={{ uri: selectedSection.image ? selectedSection.image : "https://images.pexels.com/photos/4069292/pexels-photo-4069292.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" }} />
						<View style={{ padding: 15, paddingHorizontal: 25 }}>
							<Text style={{ marginTop: 10, marginBottom: 20, fontFamily: "Bold", fontSize: 24 }}>{selectedSection?.name}</Text>
							<ScrollView style={{ maxHeight: 300 }}>
								<HTML
									baseFontStyle={{ fontFamily: "Regular", fontSize: 16, color: "#303030" }}
									html={selectedSection.description ? selectedSection.description : "<p></p>"}
									imagesMaxWidth={800}
									staticContentMaxWidth={800}
									onLinkPress={async (evt, href) => await WebBrowser.openBrowserAsync(href.toLowerCase().includes("://") ? href : `https://${href}`)}
								/>
							</ScrollView>
							<View style={{ marginTop: 35, marginBottom: 20 }}>
								{selectedSection?.contents?.map((section, i) => {
									return (
										<View key={i} style={{ flexDirection: "row", marginVertical: 5, alignItems: "center" }}>
											<View style={{ height: 35, flexDirection: "row", marginRight: 10, backgroundColor: "#F66653", alignItems: "center", justifyContent: "center", width: 150, borderRadius: 7 }}>
												<Ionicons name={swapIcon(section?.contentType).icon} color="white" size={24} style={{ marginRight: 5 }} />
												<Text style={{ fontFamily: "DemiBold", color: "white" }}>{swapIcon(section?.contentType).name}</Text>
											</View>
											<Text style={{ fontFamily: "DemiBold", fontSize: 16 }}>{section.name}</Text>
										</View>
									);
								})}
							</View>
						</View>
					</View>
					<View style={{ width: "100%", alignItems: "center", justifyContent: "center" }}>
						<TouchableOpacity
							onPress={() => {
								selectedSection?.contents[0]?.contentType !== "EXAM"
									? navigation.navigate("ActivityGroup", { id: data?.id, index: selectedSection?.sectionIndex })
									: // curso/:advanceID/exam/:id/
									  navigation.navigate("Exam", { id: selectedSection.contents[0].id, index: selectedSection?.sectionIndex });
								setVisible(false);
							}}
							style={{ marginBottom: -25, width: 200, height: 50, alignItems: "center", justifyContent: "center", borderRadius: 7, backgroundColor: "#1d527f" }}>
							<Text style={{ fontFamily: "DemiBold", color: "white", fontSize: 16 }}>Comenzar</Text>
						</TouchableOpacity>
					</View>
				</Modal>
			)}
		</>
	);
};

export default PathContainer;

const styles = StyleSheet.create({
	shadow: {
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 5,
		},
		shadowOpacity: 0.1,
		shadowRadius: 6.68,

		elevation: 11,
	},
});
