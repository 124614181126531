import React, { useState, useEffect, useContext } from 'react';
import { View, Text, Dimensions, Platform, Image } from 'react-native'
import CircularIndicator from './CircularIndicator'
import CourseContentStep from './CourseContentStep'
import { Ionicons } from '@expo/vector-icons'
import AuthContext from '../context/AuthContext';

const CourseSection = ({ content, onlyDisplay, image, active, advance, id, initDate, endDate, arrayContents, navigation }) => {

    const { dimensions, customization } = useContext(AuthContext)
    const [totalContents, setTotalContents] = useState(content.contents.length)
    const [completed, setCompleted] = useState(content.contents.filter((content) => content.status === "COMPLETED").length)
    const [percentage, setPercentage] = useState(0)
    const [percentageExam, setPercentageExam] = useState(0)

    useEffect(() => {
        const l = content.contents.filter((content) => content.status === "COMPLETED").length
        setTotalContents(content.contents.length)
        setCompleted(l)
        setPercentage(completed / totalContents * 100)
        !onlyDisplay && content.contentType === "TEST" && setPercentageExam(content.contents[0] && content.contents[0].lastWatched / content.contents[0].contentLength * 100)
    })


    return (
        <View style={{ flexDirection: "row", marginVertical: 10 }}>
            {Platform.OS === "web" && dimensions.width > 760 &&
                <View style={{backgroundColor: "gainsboro", borderRadius:15}}>
                    <Image style={{ height: "100%", width: 250, borderRadius: 15, resizeMode: "cover", }} source={{ uri: content.image || "https://source.unsplash.com/random" }} />
                </View>
            }
            <View
                style={{
                    marginLeft: Platform.OS === "web" && dimensions.width > 760 ? -20 : null,
                    paddingHorizontal: Platform.OS === "web" ? 15 : 10,
                    backgroundColor: '#F7F8FB',
                    alignSelf: "center",
                    borderRadius: 15,
                    flex: 1
                }}
            >

                <View style={{ width: "100%", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginVertical: 10, paddingHorizontal: 5 }}>
                    <View style={{ flex: 1 }}>
                        <Text style={{ fontSize: 22, textAlign: 'left', fontFamily: 'DemiBold' }} >{content.name}</Text>

                    </View>
                    <View style={{ width: 40, alignItems: "center", justifyContent: "center", height: 40, marginLeft: 20 }}>
                        {content.status !== null && content.status === "NOT INITIATED" && <Ionicons size={30} color={"black"} name="md-lock-closed" />}
                        {content.contentType !== "TEST" && (content.status === "COMPLETED" || content.status === "INITIATED") && <CircularIndicator r={15} strokeWidth={8} percentage={percentage} />}
                        {content.contentType === "TEST" && (content.status === "COMPLETED" || content.status === "INITIATED") && <CircularIndicator r={15} strokeWidth={8} percentage={percentageExam} />}
                    </View>
                </View>

                <View style={{ paddingHorizontal: 5, marginBottom: 15 }}>
                    {content.description ? <Text style={{ fontSize: 16, textAlign: 'left', fontFamily: 'Regular' }} >{content.description}</Text> : null}
                </View>
                {content.contents.map((step, i) => {
                    return (
                        <CourseContentStep
                            key={i}
                            locked={(step.status === null ? false : step.status !== "NOT INITIATED") ? false : true}
                            type={step.contentType}
                            name={step.name}
                            id={step.id}
                            length={step.contentLength}
                            currentContent={step.status === "INITIATED"}
                            lastWatched={step.lastWatched}
                            status={step.status}
                            active={step.status ? (step.status !== "NOT INITIATED") : false}
                            clickCallback={() => {
                                navigation.navigate(step.contentType === "EXAM" ? 'Exam' : 'PostSmart',
                                    {
                                        advanceID: id,
                                        id: step.id
                                    }
                                )
                            }}
                        />
                    )
                })}
            </View>
        </View>
    )
}

export default CourseSection;