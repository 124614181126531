import React, { useState, useContext } from "react";
import { View, Text, ActivityIndicator, TouchableOpacity, ScrollView } from "react-native";

import { useSafeArea } from 'react-native-safe-area-context';
import * as Animatable from 'react-native-animatable'
import AuthContext from "../../context/AuthContext";
import { decideColor } from "../../utils/functions"
import Button from "../common/Button";

const TestMission = ({ data, advance = {}, userResponse, loader, responseCallback, update }) => {

  const insets = useSafeArea();
  const [selected, setSelected] = useState(null)

  const { customization } = useContext(AuthContext)

  const zoomIn = {
    0: {
      scale: 1,
    },
    1: {
      scale: 1.06,
    },
  };

  return (
    <View
      style={{ flex: 1, backgroundColor: customization.mainColor }}
    >
      <ScrollView showsVerticalScrollIndicator={false}
        contentContainerStyle={{ flexGrow: 1, paddingTop: insets.top + 100, paddingHorizontal: 15, paddingBottom: 20 }}>

        <View style={{ flex: 1 }}>

          <View >
            {/* <Text style={{ opacity: .5, fontFamily: "DemiBold", fontSize: 22, color: "white", marginBottom: 10 }}>Enunciado Pregunta</Text> */}
            <Text style={{ fontFamily: "DemiBold", fontSize: data.content.question.length > 130 ? 18 : 24, color: decideColor(customization.mainColor), marginBottom: 10 }}>
              {data.content?.question}
            </Text>
          </View>

          <View style={{ flex: 1, justifyContent: "center", marginVertical: 30 }}>
            {data.content?.answers.map((answer, i) => {

              const backgroundColor =
                !userResponse ?
                  (selected === i ? "#f5f5f5" : "transparent")
                  :
                  answer.correctAnswer ?
                    "#25B714"
                    :
                    (userResponse === answer.text ?
                      (answer.correctAnswer ? "#25B714" : "#D52625")
                      :
                      "transparent"
                    )


              let color = decideColor(customization.mainColor)
              if (selected === null || selected !== i) {
                color = decideColor(customization.mainColor)
              } else {
                color = "black"
              }
              if (userResponse !== undefined) {
                if (answer.correctAnswer) {
                  if (answer.text === userResponse) { color = "white" }
                  else { color = "#25B714" } //verde
                }
                else {
                  if (answer.text === userResponse) { color = "white" }
                  else { color = "white" } //rojo
                }
                if (answer.correctAnswer) {
                  color = "white"
                }
              }


              const borderColor = !userResponse
                ? selected === null ? decideColor(customization.mainColor) : decideColor(customization.mainColor)
                : userResponse.userResponse === answer.text
                  ? answer.correctAnswer
                    ? "#41C714"
                    : "#017676"
                  : answer.correctAnswer
                    ? "#41C714"
                    : "#f0f0f0"

              return (
                <Animatable.View
                  duration={100}
                  opacity={!userResponse ? selected === null ? 1 : selected === i ? 1 : .4 : userResponse === answer.text || answer.correctAnswer ? 1 : .6}
                  animation={!userResponse ? selected === null ? null : selected === i ? zoomIn : null : (userResponse === answer.text || answer.correctAnswer) ? zoomIn : null}
                  key={i}
                >
                  {/* INDICADOR DE PUNTOS */}
                  {userResponse && userResponse === answer.text &&
                    <View style={{
                      borderBottomLeftRadius: (userResponse === answer.text && userResponse) ? 0 : 10,
                      borderBottomRightRadius: 0,
                      backgroundColor: answer.correctAnswer ? "#41C714" : "#f5f5f5",
                      marginTop: 0,
                      marginLeft: 10,
                      paddingHorizontal: 10,
                      alignSelf: "flex-start",
                      paddingTop: 5,
                      borderRadius: 7
                    }}>
                      <Text style={{ color: answer.correctAnswer ? "white" : "black", fontFamily: "DemiBold", fontSize: 14 }}>
                        {answer.points ? answer.points : 0} Puntos
                      </Text>
                    </View>
                  }

                  <TouchableOpacity
                    onPress={() => setSelected(i)}
                    disabled={userResponse ? true : false}
                    style={{
                      minHeight: 70,
                      justifyContent: "center",
                      marginHorizontal: 10,
                      padding: 10,
                      borderWidth: 4,
                      borderColor: borderColor,
                      backgroundColor: backgroundColor,
                      shadowColor: "rgba(0, 0, 0, 0.3)",
                      shadowOffset: { width: 0, height: 2 },
                      shadowOpacity: 0.8,
                      shadowRadius: 5,
                      elevation: selected === i ? 10 : 0,
                      borderTopLeftRadius: (userResponse === answer.text && userResponse) ? 0 : 15,
                      borderRadius: 15,
                      marginBottom: userResponse === answer.text ? 15 : 10
                    }}
                  >
                    <Text style={{
                      fontFamily: selected === null ? "Regular" : selected === i ? "DemiBold" : "Regular",
                      fontSize: answer.text.length > 130 ? 12 : 16,
                      color: color
                    }}>{answer.text}</Text>
                  </TouchableOpacity>
                </Animatable.View>
              )
            })}
          </View>
        </View>
      </ScrollView>

      <Button loading={loader} width="95%" color={decideColor(customization.mainColor)} label="Comprobar"  disabled={selected === null} style={{ position: "absolute", bottom: insets.bottom, alignSelf:"center" }} onPress={() => responseCallback(data.content.answers[selected].text)} />

      {/* {!userResponse && <View style={{ position: "absolute", top: 0, right: -20, marginBottom: insets.bottom + 10, marginTop: insets.top + 15, opacity: selected === null ? .5 : 1, }}>
        <TouchableOpacity onPress={() => responseCallback(data.content.answers[selected].text)} disabled={selected === null} style={{ backgroundColor: "white", height: 50, width: 200, borderRadius: 25, alignItems: "center", justifyContent: "center" }}>
          {loader ?
            <ActivityIndicator color="black" size="small" />
            :
            <Text style={{
              fontFamily: "DemiBold",
              fontSize: 16,
              color: "black"
            }}>Comprobar</Text>
          }
        </TouchableOpacity>
      </View>} */}
    </View>

  );
};

export default TestMission;
