import React, { useContext } from "react";
import { Platform } from "react-native";
import Header from "../components/AppHeader";
import HeaderWeb from "../components/HeaderWeb";

import SmartHome from "../screens/stackSmartNew/SmartHome";
import Course from "../screens/stackSmartNew/Course";
import CourseActive from "../screens/stackSmartNew/CourseActive";
import CompleteCourse from "../screens/stackSmartNew/CompleteCourse";
import MyCourses from "../screens/stackSmartNew/MyCourses";
import PostSmart from "../screens/stackSmartNew/PostSmart";
import Exam from "../screens/stackSmartNew/Exam";

import { createStackNavigator } from "@react-navigation/stack";
import ActivityGroup from "../screens/stackSmartNew/ActivityGroup";
import AuthContext from "../context/AuthContext";


const Stack = createStackNavigator();

const StackSmartNew = ({ index }) => {
    const {userData} = useContext(AuthContext)
    if (!userData.id) return null
	return (
		<Stack.Navigator
			headerMode="screen"
			screenOptions={{
				title: "Cursos",
				cardStyle: { flex: 1 },
				header: ({ scene, previous, navigation }) => {
					// console.log("scene", previous)
					return Platform.OS === "web" ? <HeaderWeb index={index} /> : <Header logo={previous ? false : true} backCallback={() => navigation.goBack()} backButton={previous ? true : false} />;
				},
			}}>
			<Stack.Screen name="SmartHome" component={SmartHome} />
			<Stack.Screen name="ActivityGroup" component={ActivityGroup} />
			<Stack.Screen name="CompleteCourse" component={CompleteCourse} options={{ header: () => null }} />
			<Stack.Screen
				name="Exam"
				component={Exam}
				options={{
					headerTransparent: true,
					header: ({ scene, previous, navigation }) => <Header backButton={true} backCallback={() => navigation.goBack()} minimal />,
				}}
			/>
		</Stack.Navigator>
	);
};

export default StackSmartNew;
