import React from "react";
import {
  View,
  Text,
  Image,
  TouchableHighlight,
  TouchableOpacity,
  StyleSheet,
  Dimensions
} from "react-native";
import URL from '../constants/Servers'
import * as Animatable from "react-native-animatable";
import Element from '../constants/Elements'
import moment from 'moment'
import ImageRel from "./common/ImageRel";

const CardPost = ({ clickCallback, position, categories, id, title, image, publishDate }) => {

  const date = moment(publishDate).format("DD [de] MMMM YY")
  const placeholder = Element('placeholder')

  return (
    <TouchableOpacity
      key={id}
      style={{ minHeight: 100, width: "100%", maxWidth:380, marginVertical: 10, marginHorizontal: 10, borderRadius: 15 }}
      onPress={() => { clickCallback() }}
    >

      <Animatable.View
        ref={ref => (position = ref)}
        delay={position * 300}
        animation="fadeInLeft"
        style={{ flex: 1 }}
      >

        <View style={{ flex: 1, flexDirection: "row" }} >
          <View style={{ flex: 5, paddingRight: 10 }}>
            <View style={{ flexDirection: 'row', flexWrap: 'wrap-reverse' }}>
              {categories && categories.map((category) =>
                <View key={category.id} style={{ marginBottom: 5, marginRight: 5, backgroundColor: 'black', alignSelf: 'flex-start', borderRadius: 15, paddingVertical: 5, paddingHorizontal: 10 }}>
                  <Text style={{ color: 'white', fontSize: 10, fontFamily: 'DemiBold' }}>
                    {category.name}
                  </Text>
                </View>
              )}
            </View>

            <Text style={{ marginTop: 10, fontSize: 16, fontFamily: 'DemiBold' }}>{title}</Text>
            <Text style={{ marginTop: 10, fontSize: 12, color: 'gray', fontFamily: 'Regular' }}>{date}</Text>
          </View>

          <View style={{ flex: 3 }}>
            <ImageRel
              style={{ flex: 1, borderRadius: 8, resizeMode: 'cover' }}
              resizeMethod="scale"
              source={{ uri: image === null ? placeholder : image.url === undefined ? placeholder : image.url.includes('http') ? image.url : URL.URL_API + image.url }}
            />

          </View>
        </View>

      </Animatable.View>
    </TouchableOpacity>
  )
}

export default CardPost;

const styles = StyleSheet.create({
  // shadow: {
  //   shadowColor: "rgba(0, 0, 0, 0.3)",
  //   shadowOffset: { width: 0, height: 2 },
  //   shadowOpacity: 0.4,
  //   shadowRadius: 3,
  //   elevation: 10
  // }
});
