import React from "react";
import {
  View,
  Text,
  TouchableHighlight,
  StyleSheet,
  Dimensions
} from "react-native";
import * as Animatable from "react-native-animatable";
import moment from "moment";

let SCREEN_WIDTH = Dimensions.get("window").width;
let SCREEN_HEIGHT = Dimensions.get("window").height;

const CardNoti = ({ position, navigation, id, title, body, date, data }) => (
  <Animatable.View
    delay={position}
    animation="fadeInDown"
    style={[
      styles.shadow,
      {
        height: (SCREEN_HEIGHT - 135) / 6,
        marginVertical: 5,
        marginHorizontal: 10,
        borderRadius: 15
      }
    ]}
  >
    <TouchableHighlight
      style={{ flex: 1, borderRadius: 15 }}
      onPress={() => {
        let pagina = Object.keys(data)[0];
        if (pagina === "avid") {
          navigation.navigate("RetoProfile", {
            avid: data.avid,
            id: data.reto
          });
        } else if (pagina === "acceptwebinar") {
          navigation.navigate("AcceptWebinar", {
            id: data.acceptwebinar
          });
        } else if (pagina === "prescriptor") {
          navigation.navigate("DetailsPresciptor", {
            id_farming: data.prescriptor
          });
        } else if (pagina === "inmueble") {
          navigation.navigate("DetailsFarming", {
            id_farming: data.inmueble
          });
        } else if (pagina === "webinar") {
          navigation.navigate("Webinar", {
            webinar: data.webinar
          });
        } else if (pagina === "entrada") {
          navigation.navigate("Post", {
            id: data.entrada
          });
        } else if (pagina === "curso") {
          navigation.navigate("IndexCurso", {
            data: data.curso
          });
        }
      }}
    >
      <View
        style={[
          {
            flex: 1,
            flexDirection: "column",
            borderRadius: 15,
            backgroundColor: "#fff",
            alignContent: "center",
            alignItems: "flex-start"
          }
        ]}
      >
        <Text
          style={{ flex: 2, paddingLeft: 10, fontSize: 15, fontFamily:"DemiBold" }}
        >
          {title}
        </Text>
        <Text style={{ flex: 5, paddingLeft: 10, fontSize: 14 }}>{body}</Text>
        <Text
          style={{ flex: 2, paddingLeft: 10, fontSize: 11, color: "#bfbfbf" }}
        >
          {moment().diff(date, "hours") <= 23
            ? moment(date).fromNow()
            : moment(date).format()}
        </Text>
      </View>
    </TouchableHighlight>
  </Animatable.View>
);

export default CardNoti;

const styles = StyleSheet.create({
  shadow: {
    shadowColor: "rgba(0, 0, 0, 0.3)",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.4,
    shadowRadius: 3,
    elevation: 10
  }
});
