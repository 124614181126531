import React, { useState, useEffect, useRef, useContext } from "react";
import { View, Text, StyleSheet, TouchableOpacity, Image, FlatList, Platform } from "react-native";
import * as Animatable from 'react-native-animatable'
import { Ionicons } from '@expo/vector-icons'
import AuthContext from "../../context/AuthContext";
import {decideColor} from "../../utils/functions"


const CourseItemsWeb = ({ items, toogled, itemCallback, lastCompleted, activecontent, navigation, arrayContents }) => {


    const { customization, dimensions } = useContext(AuthContext)

    let _scrollView = useRef()
    const itemsContainer = useRef()

    const open = {
        0: { width: 0 },
        1: { width: 270 }
    }
    const close = {
        0: { width: 270 },
        1: { width: 0 }
    }

    const zoomIn = {
        0: { scale: 1, elevation: 0 },
        1: { scale: 1.05, elevation: 20 }
    };

    const scrollToItem = async (sectionIndex, contentID) => {
        _scrollView.current && await _scrollView.current.scrollToOffset({
            offset: (150 * arrayContents.findIndex(el => el.id === contentID)) + (sectionIndex === 0 ? -40 : sectionIndex * 40),
            animated: true
        })
        return true
    }

    useEffect(() => {
        _scrollView.current.scrollToOffset({ offset: 130 * lastCompleted, animated: true })
    }, [])
    // useEffect(() => {
    //     itemsContainer.current && toogled === true && itemsContainer.current.animate(open)
    //     itemsContainer.current && toogled === false && itemsContainer.current.animate(close)
    //     _scrollView.current && toogled === true && _scrollView.current.scrollToOffset({ offset: 200 * lastCompleted, animated: true })
    // }, [toogled])

    // useEffect(() => {
    //     // _scrollView.current && toogled === true && _scrollView.current
    //     //     // arrayContents.findIndex(el => el.id === activecontent))
    //     //     .scrollToOffset({
    //     //         offset: (200 * arrayContents.findIndex(el => el.id === activecontent)),
    //     //         animated: true
    //     //     })
    //     _scrollView.current.scrollToOffset({ offset: 130 * lastCompleted, animated: true })
    // }, [activecontent])


    return (

        <View ref={itemsContainer} style={{ zIndex: 2, 
        // height: dimensions.width >= 1000 ? dimensions.height - 100 : null, 
        width: 300, borderTopLeftRadius: 15, borderBottomLeftRadius: 15, overflow: "hidden", backgroundColor: customization.mainColor }}>
            <FlatList
                data={items}
                ref={_scrollView}
                getItemLayout={(data, index) => ({ length: items.length, offset: (20 * index ), index })}
                keyExtractor={(item, index) => index.toString()}
                ItemSeparatorComponent={() => <View style={{ width: 20 }} />}
                ListFooterComponent={() => <View style={{ width: 20 }} />}
                ListHeaderComponent={() => <View style={{ width: 20 }} />}
                showsVerticalScrollIndicator={false}
                contentContainerStyle={{ paddingVertical: 15, paddingHorizontal: 10, backgroundColor: customization.mainColor }}
                renderItem={({ item, index }) => {

                    const sectionIndex = index

                    return (
                        <View style={{ padding: 10, marginBottom: 10, paddingHorizontal: 20, backgroundColor: 'rgba(255,255,255,.1)', borderRadius: 10 }}>

                            <Text style={{ fontFamily: 'DemiBold', fontSize: 18, color: decideColor(customization.mainColor), marginBottom: 10 }}>{item.name}</Text>

                            <View style={{ marginTop: 20 }}>
                                {item.contents.map((content, i) => {

                                    return (
                                        <Animatable.View
                                            key={i}
                                            duration={300}
                                            animation={content.id === activecontent ? zoomIn : null}
                                            style={[styles.shadow, { elevation: 2, zIndex: activecontent === content.id ? 10 : 1, opacity: content.status === "NOT INITIATED" ? .5 : 1, borderRadius: 7, marginBottom: 10, backgroundColor: 'white', height: 100 }]}
                                        >
                                            <TouchableOpacity
                                                disabled={content.status === "NOT INITIATED" && true}
                                                onPress={() => {
                                                    scrollToItem(sectionIndex, content.id).then(() => itemCallback(content.id))

                                                }}
                                                style={{ flex: 1 }}
                                            >

                                                <View style={{
                                                    backgroundColor: content.status === "COMPLETED" ? '#3CAF29' : "#f2f2f2",
                                                    width: 40, height: 40,
                                                    borderTopLeftRadius: 7,
                                                    borderBottomRightRadius: 7,
                                                    position: 'absolute', right: 0, bottom: 0, zIndex: 2,
                                                    alignItems: 'center', justifyContent: 'center',
                                                }}>
                                                    <Ionicons name={content.status === "COMPLETED" ? "ios-checkmark" : content.contentType === "VIDEO" ? "ios-play" : content.contentType === "AUDIO" ? "ios-volume-high" : content.contentType === "DOCUMENT" ? "ios-document" : content.contentType === "EXAM" ? "ios-checkmark-circle-outline" : "md-list"}
                                                        size={content.status === "COMPLETED" ? 40 : 30} color={content.status === "COMPLETED" ? "white" : "black"}
                                                    />
                                                </View>

                                                <View style={{ width: "100%", flexDirection: "row", padding: 10 }}>
                                                    <Image source={{ uri: content.image }} style={{ marginLeft: -20, marginRight:10, borderRadius: 15, width: 80, height: 80 }} />
                                                    <Text style={{ fontFamily: 'DemiBold', fontSize: 14 }}>{content.name}</Text>
                                                </View>

                                            </TouchableOpacity>
                                        </Animatable.View>
                                    )
                                })}
                            </View>


                        </View>)
                }}

            />
        </View>
    );
}

export default CourseItemsWeb;

const styles = StyleSheet.create({
    shadow: {
        shadowColor: "rgba(0, 0, 0, 0.3)",
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: .4,
        shadowRadius: 10,
    }
});
