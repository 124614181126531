import React, { useContext } from 'react';
import { View, Text, TouchableOpacity, Image, Platform } from 'react-native'
import { Ionicons } from "@expo/vector-icons";
import LinearBarIndicator from '../components/LinearBarIndicator'
import AuthContext from '../context/AuthContext';
import { decideColor } from '../utils/functions';

const CourseContentStep = ({ type, clickCallback, currentContent, lastWatched = 0, locked, active = false, name, length, image = null, status }) => {


    const { customization } = useContext(AuthContext)

    let percentage = Math.round((lastWatched / (length * 1000)) * 100)

    const millisToMinutesAndSeconds = (millis) => {
        if (millis === 0) {
            return null
        } else {
            let minutes = Math.floor(millis / 60000);
            let seconds = ((millis % 60000) / 1000).toFixed(0);
            return (seconds == 60 ? (minutes + 1) + ":00" : minutes + ":" + (seconds < 10 ? "0" : "") + seconds)
        }
    }

    return (

        <TouchableOpacity disabled={!active} onPress={() => clickCallback()} style={{ width: "100%", opacity: (locked ? .3 : 1), marginHorizontal: 5, paddingHorizontal: 10, backgroundColor: currentContent ? "" : "", borderRadius: 20, marginBottom: 10 }}>


            <View style={{ flex: 1, flexDirection: 'row', alignItems: "center" }}>
                {status === "COMPLETED" &&
                    <View style={{ width: 60, height: 60, alignItems: "center", justifyContent: "center", marginRight: 15 }}>
                        <View style={{ width: 50, height: 50, borderRadius: 30, alignItems: "center", justifyContent: "center", backgroundColor: "#429321" }}>
                            <Ionicons name={"ios-checkmark"} size={50} color={"white"} />
                        </View>
                    </View>
                }
                {!image && status === "FAILED" &&
                    <View style={{ width: 60, height: 60, alignItems: "center", justifyContent: "center", marginRight: 15 }}>
                        <View style={{ width: 50, height: 50, borderRadius: 30, alignItems: "center", justifyContent: "center", backgroundColor: "#017676" }}>
                            <Ionicons
                                name={"ios-close"}
                                size={50} color={"white"} />
                        </View>
                    </View>
                }
                {!image && status !== "COMPLETED" && status !== "FAILED" &&
                    <View style={{ width: 60, height: 60, borderRadius: 15, marginRight: 15, alignItems: "center", justifyContent: "center", backgroundColor: customization.mainColor }}>
                        <Ionicons
                            name={type === "VIDEO" ? "ios-play" : type === "AUDIO" ? "ios-volume-high" : type === "TEXT" ? "ios-document" : type === "IMAGE" ? "ios-images" : type === "ANIMATION" ? "ios-git-compare" : type === "EXAM" ? "ios-checkmark-circle-outline" : "md-list"}
                            size={35} color={decideColor(customization.mainColor)} />
                    </View>
                }
                {image !== null && status !== "INITIATED" && <Image source={{ uri: "https://source.unsplash.com/random" }} style={{ height: 60, width: 60, marginRight: 10, borderRadius: 7 }} />}
                <View style={{ flex: 1, height: 80, justifyContent: 'center' }}>
                    <Text truncate="true" numberOfLines={2} style={{ fontFamily: 'DemiBold', fontSize: Platform.OS === "web" ? 22 : 16, color: 'black' }}>{name}</Text>
                    {/* <Text style={{ fontFamily: 'Regular', fontSize: 12, color: 'gray' }}>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</Text> */}
                </View>
                {length !== null && length !== 0 && type !== "EXAM" && <View style={{ marginLeft: 10, backgroundColor: "#efefef", padding: 5, paddingHorizontal: 7, borderRadius: 7 }}>
                    <Text style={{ fontSize: 7, fontFamily: "DemiBold", color: "black" }}>Duración</Text>
                    <Text style={{ fontFamily: 'DemiBold', fontSize: 16, color: 'black' }}>{millisToMinutesAndSeconds(length * 1000)}</Text>
                </View>}
            </View>

            {(type === "VIDEO" || type === "AUDIO") && active && status === "INITIATED" && <LinearBarIndicator width={"100%"} height={10} rounded={5} gradient={['#B4EC51', '#429321']} percentage={percentage} />}
        </TouchableOpacity>
    )
}

export default CourseContentStep;