import React, { useMemo, useReducer, useContext, useEffect, useState } from "react";
import { View, Text, StyleSheet, Platform, Dimensions, unstable_batchedUpdates } from "react-native";

import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { TabRouter, useNavigationState } from "@react-navigation/native";

import MainBottomNavBar from "../components/MainBottomNavBar";
import StackContent from "./StackContent";
import StackProfile from "./StackProfile";
import StackChallenges from "./StackChallenges";
import StackItinerary from "./StackItinerary";
import StackSmart from "./StackSmart";
import StackForum from "./StackForum";
import StackRanking from "./StackRanking";

import { LinearGradient } from "expo-linear-gradient";
import { Ionicons } from "@expo/vector-icons";
import { Audio } from "expo-av";
import * as Haptics from "expo-haptics";
import * as Animatable from "react-native-animatable";

import { firestore } from "firebase";

import PointsContext from "../context/PointsContext";
import AdvanceContext from "../context/AdvanceContext";
import AuthContext from "../context/AuthContext";
import RouteContext from "../context/RouteContext";

import Portal from "@burstware/react-native-portal";
import StackSmartNew from "./StackSmartNew";
import { FULLSCREEN_UPDATE_PLAYER_DID_DISMISS } from "expo-av/build/Video";

const Tab = createBottomTabNavigator();

export default function App() {
	const { userData, dimensions } = useContext(AuthContext);

	const [state, dispatch] = useReducer(
		(prevState, action) => {
			switch (action.type) {
				case "POINTS":
					return {
						...prevState,
						showPoints: action?.showPoints,
						message: action?.message,
						points: action?.points,
						userPoints: action?.userPoints,
					};
				case "ADVANCE":
					return {
						...prevState,
						advance: [...prevState.advance, ...action?.advance],
						advanceRef: action?.advanceRef,
						courseRef: action?.courseRef,
					};
			}
		},
		{
			showPoints: null,
			message: "",
			points: 0,
			advance: [],
			advanceRef: "",
			courseRef: "",
		}
	);

	const pointsContext = useMemo(
		() => ({
			givePoints: async (data, tenantTrophies) => {
				Platform.OS !== "web" && Haptics.impactAsync("medium");
				Platform.OS !== "web" && pointsSound();
				let pointsRef = firestore().collection("users").doc(userData.id);
				pointsRef.update({ points: firestore.FieldValue.increment(data.points) }).then(() => {
					pointsRef.get().then((doc) => {
						const userPoints = doc.get("points");
						const userAchievements = doc.get("achievements");
						const updatedTrophies = checkUserTrophies(tenantTrophies, userPoints);
						if (updatedTrophies.length > userAchievements.length) {
							const lastTrophy = updatedTrophies[updatedTrophies.length - 1];
							const ifTrophy = lastTrophy !== undefined ? "\n" + `Has conseguido el trofeo: ${lastTrophy.name}` + "\n" + `por alcanzar ${lastTrophy.pointsToAchieve} puntos` : null;
							dispatch({ type: "POINTS", showPoints: data.showPoints, points: data.points, message: data.message + ifTrophy, userPoints });
							pointsRef.update({ achievements: firestore.FieldValue.arrayUnion(...updatedTrophies) });
						}
					});
				});
				setTimeout(() => {
					dispatch({ type: "POINTS", showPoints: !data.showPoints, points: data.points, message: data.message });
				}, 3000);
			},
		}),
		[]
	);

	const checkUserTrophies = (trophies, userPoints) => {
		const tenantTrophies = [...trophies];
		const updatedUserTrophies = tenantTrophies.filter((trophy) => parseInt(trophy.pointsToAchieve) <= parseInt(userPoints)).map((trophy) => (!userData.achievements.includes(trophy) ? trophy : null));
		const userTrophies = [...userData?.achievements, ...updatedUserTrophies];
		return userTrophies;
	};

	const pointsSound = async () => {
		const soundObject = new Audio.Sound();
		try {
			await soundObject.loadAsync(require("../assets/sounds/Points.mp3"));
			await soundObject.playAsync();
			// Your sound is playing!
		} catch (error) {
			// An error occurred!
		}
	};

	return (
		<Portal.Host>
			<PointsContext.Provider value={{ pointsContext, state, dispatch }}>
				{userData.zonas && <Tab.Navigator tabBar={(props) => (Platform.OS === "web" && dimensions.width > 1000 ? null : <MainBottomNavBar titles={false} {...props} />)}>
					{/* <Tab.Screen name="StackSmartNew" component={StackSmartNew} /> */}
					{userData?.zonas?.content && <Tab.Screen name="StackContent" component={StackContent} />}
					{userData?.zonas?.challenges && <Tab.Screen name="StackChallenges" component={StackChallenges} />}
					{userData?.zonas?.smartlearning && <Tab.Screen name="StackSmart" component={StackSmart} />}
					{userData?.zonas?.itinerary && <Tab.Screen name="StackItinerary" component={StackItinerary} />}
					{userData?.zonas?.forum && <Tab.Screen name="StackForum" component={StackForum} />}
					{userData?.zonas?.ranking && <Tab.Screen name="StackRanking" component={StackRanking} />}
					{userData?.zonas?.profile && <Tab.Screen name="StackProfile" component={StackProfile} />}
				</Tab.Navigator>}

				{state.showPoints !== null && (
					<Animatable.View duration={300} animation={state?.showPoints === true ? "fadeInDown" : "fadeOutUp"} style={styles.popup}>
						<LinearGradient colors={["#F49D44", "#FED25A"]} style={{ marginRight: 10, height: 30, width: 30, borderRadius: 15, backgroundColor: "#FCB84F", alignItems: "center", justifyContent: "center" }}>
							<Ionicons name={"ios-star"} size={19} style={{ color: "white" }} />
						</LinearGradient>
						<View style={{ flex: 1 }}>
							<Text style={{ fontFamily: "DemiBold", fontSize: 16 }}>{`Ganaste: ${state.points} puntos`}</Text>
							<Text style={{ fontFamily: "DemiBold", fontSize: 16 }}>{state.message}</Text>
						</View>
					</Animatable.View>
				)}
			</PointsContext.Provider>
		</Portal.Host>
	);
}

const styles = StyleSheet.create({
	popup: {
		flexDirection: "row",
		position: "absolute",
		top: 50,
		backgroundColor: "white",
		alignItems: "center",
		width: "95%",
		maxWidth: 400,
		marginLeft: "2.5%",
		paddingVertical: 15,
		paddingHorizontal: 10,
		borderRadius: 10,
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 6,
		},
		shadowOpacity: 0.39,
		shadowRadius: 8.3,

		elevation: 13,
	},
});
