import AppLoading from 'expo-app-loading'
import * as Font from 'expo-font';
import React, { useState, useEffect } from 'react';
import { LogBox, Platform } from 'react-native';


import moment from "moment";
import "moment/locale/es";

import AppNavigator from './navigation/AppNavigator';


// import firebase from 'firebase/app';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyB4ziM1B24Rj4kuKl8stt0QVj_wzmt0sw4",
  authDomain: "apd-formacion.firebaseapp.com",
  databaseURL: "https://apd-formacion.firebaseio.com",
  projectId: "apd-formacion",
  storageBucket: "apd-formacion.appspot.com",
  messagingSenderId: "487214668266",
  appId: "1:487214668266:web:79a4d321399006ded4fa93",
  measurementId: "G-QVGEM8LK41"
};

// const firebaseConfig = {
//   apiKey: "AIzaSyCn8FdzS427iyVc47rTmwW3H5KNRMsDfpQ",
//   authDomain: "uno-work.firebaseapp.com",
//   projectId: "uno-work",
//   storageBucket: "uno-work.appspot.com",
//   messagingSenderId: "970827252335",
//   appId: "1:970827252335:web:ff2b962527f021a654c39a",
//   measurementId: "G-Y0D39T3Y1L"
// };


// Before rendering any navigation stack
import { enableScreens } from 'react-native-screens';
enableScreens();

moment().locale("es");

moment.updateLocale('es', {
  relativeTime: {
    future: 'en %s',
    past: '%s',
    s: 'ahora',
    ss: '%ss',
    m: 'un minuto',
    mm: '%dm',
    h: 'una hora',
    hh: '%dh',
    d: 'un día',
    dd: '%dd',
    M: 'un mes',
    MM: '%dM',
    y: 'un año',
    yy: '%dY'
  }
});




firebase.initializeApp(firebaseConfig)

export default function App(props) {

  const [isLoadingComplete, setLoadingComplete] = useState(false);

  if (!isLoadingComplete && !props.skipLoadingScreen) {

    return (
      <AppLoading
        startAsync={loadResourcesAsync}
        onError={handleLoadingError}
        onFinish={() => handleFinishLoading(setLoadingComplete)}
      />
    );
  } else {
    return (
      <AppNavigator />
    );
  }
}

async function loadResourcesAsync() {
  await Promise.all([
    Font.loadAsync({
      'Regular': require("./assets/fonts/MRR.otf"),
      'DemiBold': require("./assets/fonts/MRSB.otf"),
      'Bold': require("./assets/fonts/MRB.otf"),
    }),
  ]);
}

function handleLoadingError(error) {
  // In this case, you might want to report the error to your error reporting
  // service, for example Sentry
  console.warn(error);
}

function handleFinishLoading(setLoadingComplete) {
  setLoadingComplete(true);
}