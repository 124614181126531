import React, { useContext } from 'react'


import Header from '../components/AppHeader'
import HeaderWeb from '../components/HeaderWeb'

import { createStackNavigator, useHeaderHeight } from '@react-navigation/stack';
import MissionPage from '../screens/stackItinerary/MissionPage';
import TrainingHome from '../screens/stackItinerary/TrainingHome';
import Itinerary from '../screens/stackItinerary/Itinerary';
import { Platform } from 'react-native';

const Stack = createStackNavigator();


const StackContent = () => {


    return (
        <Stack.Navigator
            headerMode="screen"
            screenOptions={{
                cardStyle: { flex: 1 },
                header: ({ scene, previous, navigation }) => {
                    // console.log("scene", previous)
                    return (
                        Platform.OS === "web" ?
                            <HeaderWeb />
                            :
                            <Header
                                logo={previous ? false : true}
                                backCallback={() => navigation.goBack()}
                                backButton={previous ? true : false}
                                logout={false}
                                editProfile={false}
                                notificationButton={false}
                                searchButton={false}
                            />
                    )
                }
            }}
        >
            <Stack.Screen name="TrainingHome"
                // options={{
                //     header: () => null
                // }}
                component={TrainingHome}
            />
            <Stack.Screen name="Itinerary" component={Itinerary}
                options={{
                    header: ({ scene, previous, navigation }) => {
                        // console.log("scene", previous)
                        return (
                            <Header
                                backCallback={() => navigation.goBack()}
                                backButton={true}
                            />
                        )
                    }
                }}
            />
            <Stack.Screen name="Mission" component={MissionPage}
                options={{
                    headerTransparent: true,
                    header: ({ scene, previous, navigation }) => {
                        // console.log("scene", previous)
                        return (
                            <Header
                                backCallback={() => navigation.goBack()}
                                backButton={true}
                                minimal
                            />
                        )
                    }
                }}
            />
        </Stack.Navigator>
    );
}

export default StackContent;


// const config = Platform.select({
//     web: {
//         headerMode: 'none',
//     },
//     default: {
//         headerMode: 'float',
//         headerTransitionPreset: 'fade-in-place',
//         // headerLayoutPreset: "left",
//         // mode: 'card',
//         // cardOverlayEnabled:true,
//         // cardShadowEnabled: true,
//         // cardStyle:{width:'95%', borderRadius:15, height:'95%', marginLeft:'2.5%'},
//         // transparentCard: true,
//         defaultNavigationOptions: () => ({
//             header: () => <Header logo={true} backButton={false} notificationButton={true} searchButton={true} />
//         }),
//         transitionConfig: () => ({
//             transitionSpec: {
//                 duration: 300,
//                 easing: Easing.out(Easing.poly(4)),
//                 timing: Animated.timing,
//             },
//             screenInterpolator: sceneProps => {
//                 const { layout, position, scene } = sceneProps;
//                 const { index } = scene;

//                 const height = layout.initHeight;
//                 const translateX = position.interpolate({
//                     inputRange: [index - 1, index, index + 1],
//                     outputRange: [height, 0, 0],
//                 });

//                 const opacity = position.interpolate({
//                     inputRange: [index - 1, index - 0, index],
//                     outputRange: [0, 1, 1],
//                 });

//                 return {
//                     opacity
//                     , transform: [{ translateX }]
//                 };
//             },
//         }),
//     },
// });
// const contentStack = createStackNavigator(
//     {
//         Home: {
//             screen: ContentHome,
//             navigationOptions: ({ navigation }) => ({
//                 title: "Home",
//                 header: () => <Header logo={true} backButton={false} notificationButton={false} searchButton={true} />,
//                 navigationState: navigation.state
//             })
//         },
//         Post: {
//             screen: Post,
//             navigationOptions: ({ navigation }) => ({
//                 title: "Content",
//                 header: () => <Header logo={false} backButton={true} notificationButton={false} searchButton={true} />,
//                 navigationState: navigation.state,
//             })
//         },
//         Comments: {
//             screen: Comments,
//             navigationOptions: ({ navigation }) => ({
//                 title: "Comments",
//                 header: () => <Header logo={false} backButton={true} notificationButton={false} searchButton={true} />,
//                 navigationState: navigation.state
//             })
//         },
//         Category: {
//             screen: Category,
//             navigationOptions: ({ navigation }) => ({
//                 title: "Category",
//                 header: () => <Header logo={false} backButton={true} notificationButton={false} searchButton={true} />,
//                 navigationState: navigation.state
//             })
//         },
//         // SubCategory: { screen: SubCategory },
//         Search: {
//             screen: Search,
//             navigationOptions: ({ navigation }) => ({
//                 title: "Search",
//                 header: () => { },
//                 navigationState: navigation.state
//             })
//         },
//         Notifications: {
//             screen: Notifications,
//             navigationOptions: ({ navigation }) => ({
//                 title: "Notifications",
//                 header: () => <Header logo={false} backButton={true} notificationButton={false} searchButton={true} />,
//                 navigationState: navigation.state
//             })
//         }
//     },
//     config
// );

// contentStack.navigationOptions = {
//     // header: (<View style={{height:40, backgroundColor:'red'}}><Header logo={true} backButton={false} notificationButton={false} searchButton={false} /></View>)
//     // tabBarIcon: ({ focused }) => (
//     //   <TabBarIcon
//     //     focused={focused}
//     //     name={
//     //       Platform.OS === 'ios'
//     //         ? `ios-information-circle${focused ? '' : '-outline'}`
//     //         : 'md-information-circle'
//     //     }
//     //   />
//     // ),
// };

// contentStack.path = 'Content';

// export default contentStack


