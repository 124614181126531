import React, { useState, useEffect, useRef, useContext } from "react";
import { Text, View, TouchableOpacity, StyleSheet, ScrollView, Platform } from "react-native";
import { useNavigation } from '@react-navigation/native';
import * as Animatable from "react-native-animatable"
import LottieView from "lottie-react-native";
import HTML from 'react-native-render-html'
import { LinearGradient } from "expo-linear-gradient";
import { useSafeArea } from 'react-native-safe-area-context';
import AuthContext from "../../context/AuthContext";
import { decideColor } from "../../utils/functions";
import Button from "../common/Button";

const TimerMission = ({ advance, responseCallback }) => {

  const { customization } = useContext(AuthContext)
  const [state, setState] = useState("STOP");
  const [timer, setTimer] = useState(advance.content.timer);
  const [int, setInt] = useState(null);
  const refTimer = useRef(advance.content.timer);
  const { navigate } = useNavigation();
  const insets = useSafeArea()

  useEffect(() => {
    timer === 0 && setTimeout(() => {
      responseCallback(true)
    }, 2000)
  }, [timer])

  const start = () => {
    const intervalo = setInterval(() => {
      refTimer.current = refTimer.current - 1;
      setTimer(refTimer.current);
      if (refTimer.current === 0) {
        clearInterval(intervalo);
      }
    }, 1000);
    setInt(intervalo);
    setState("START");
  };

  const stop = () => {
    clearInterval(int);
    setState("STOP");
  };

  const secToFormat = (time) => {
    // Hours, minutes and seconds
    var hrs = ~~(time / 3600);
    var mins = ~~((time % 3600) / 60);
    var secs = ~~time % 60;

    // Output like "1:01" or "4:03:59" or "123:03:59"
    var ret = "";

    if (hrs > 0) {
      ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }

    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
  }

  const zoomOut = {
    0: {
      scale: 1,
      borderRadius: 100
    },
    1: {
      scale: 0,
      borderRadius: 100
    },
  };

  const morph = {
    0: {
      borderTopRightRadius: 100,
      borderTopLeftRadius: 100,
      borderBottomLeftRadius: 100,
      borderBottomRightRadius: 100,
      backgroundColor: "white",
      scale: 1
    },
    0.5: {
      borderTopRightRadius: 120,
      borderTopLeftRadius: 92,
      borderBottomLeftRadius: 120,
      borderBottomRightRadius: 87,
      backgroundColor: "white",
      scale: 1.15
    },
    1: {
      borderTopRightRadius: 100,
      borderTopLeftRadius: 120,
      borderBottomLeftRadius: 100,
      borderBottomRightRadius: 120,
      backgroundColor: "white",
      scale: 1.3
    }
  }

  return (
    <View style={{ flex: 1, backgroundColor: customization.mainColor, }}>
      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ flexGrow: 1 }}
      >

        <View style={{ flex: 1, paddingTop: insets.top + 100, paddingHorizontal: 15 }} >

          <HTML baseFontStyle={{ fontFamily: "DemiBold", fontSize: 16, color: decideColor(customization.mainColor) }} html={advance.description} style={{ textAlign: "center", marginHorizontal: 20 }} />


          <View style={{ flexGrow: 1, paddingBottom: insets.bottom + 80, justifyContent: "center", alignItems: "center" }}>

            <View style={{ width: 200, height: 200 }}>
              <Animatable.View animation={timer === 0 ? zoomOut : morph} direction="alternate" easing={"ease-in-out"} duration={timer === 0 ? 500 : 4000} iterationCount={timer === 0 ? 1 : "infinite"} style={[styles.shadow, { width: 200, height: 200, position: "absolute", top: 0, left: 0, backgroundColor: "white", alignItems: "center", justifyContent: "center" }]}>
                {!advance.userResponse && <Text style={{ fontSize: 40, fontFamily: "DemiBold" }}>{secToFormat(timer)}</Text>}
              </Animatable.View>

              {timer === 0 || advance.userResponse && Platform.OS !== "web" &&
                <LottieView
                  autoPlay
                  loop={false}
                  style={{
                    width: 200,
                    height: 200,
                    position: "absolute",
                    top: 0,
                    left: 0,
                  }}
                  source={require('../../assets/animations/check.json')}
                />
              }
            </View>

          </View>
        </View>
      </ScrollView>

      <Button
        width="95%"
        color={decideColor(customization.mainColor)}
        style={{ position: "absolute", bottom: insets.bottom, alignSelf: "center" }}
        label={advance.userResponse
          ? "Volver"
          : timer === 0
            ? "Completar Misión"
            : state === "STOP"
              ? "Iniciar"
              : "Pausar"}
        onPress={() => {
          if (advance.userResponse) {
            navigate("TrainingHome");
          } else if (timer === 0) {
            responseCallback(true);
          } else if (state === "STOP") {
            start();
          } else {
            stop();
          }
        }}
      />
    </View>
  );
};

export default TimerMission;

const styles = StyleSheet.create({
  input: {
    borderWidth: 1,
    borderColor: "#C6C6C6",
    height: 50,
    backgroundColor: "white",
    borderRadius: 25,
    alignItems: "center",
    paddingHorizontal: 15,
    marginVertical: 5,
    flex: 1
  },
  shadow: {
    shadowColor: "rgba(0, 0, 0, 0.3)",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.4,
    shadowRadius: 10,
    elevation: 10,
    backgroundColor: "white",
    borderRadius: 15
  }
});