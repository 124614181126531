import React from 'react'
import Header from '../components/AppHeader'

import { createStackNavigator } from '@react-navigation/stack';
const Stack = createStackNavigator();

import ChallengesHome from '../screens/stackTraining/ChallengesHome'
import ChallengePage from '../screens/stackTraining/ChallengePage'

const StackChallenges = ({index}) => {
    return (
        <Stack.Navigator
            headerMode="screen"
            screenOptions={{
                cardStyle: { flex: 1 },
                header: ({ scene, previous, navigation }) => {
                    // console.log("scene", previous)
                    return (
                        <Header
                            logo={previous ? false : true}
                            backCallback={() => navigation.goBack()}
                            backButton={previous ? true : false}
                        />
                    )
                }
            }}
        >
            <Stack.Screen name="ChallengesHome" component={ChallengesHome} />
            <Stack.Screen name="ChallengePage" component={ChallengePage} />
        </Stack.Navigator>
    );
}

export default StackChallenges;