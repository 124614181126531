import React, { useState, useEffect, useRef, useContext } from "react";
import {
  ScrollView,
  StyleSheet,
  View,
  ActivityIndicator,
  RefreshControl,
  Text,
  Image,
  StatusBar,
  TouchableOpacity,
  Modal,
  Dimensions,
  FlatList
} from "react-native";
import moment from "moment";
import { useNavigation, useNavigationParam } from "@react-navigation/native";

import * as Animatable from "react-native-animatable";

import ImageRel from "../../components/common/ImageRel";
import { Ionicons } from "@expo/vector-icons";
import { LinearGradient } from "expo-linear-gradient";
import { flattenDeep, get } from 'lodash'

import { firestore, storage } from 'firebase'
import AuthContext from "../../context/AuthContext";
import Button from "../../components/common/Button";
import Collapsible from "react-native-collapsible";
import { missingTranslationPrefix, translate } from "i18n-js";


const ProfileHome = ({ navigation }) => {

  const { userData, customization } = useContext(AuthContext)

  const [form, setForm] = useState({});
  const [coordinates, setCoordinates] = useState([]);
  const [window, setWindow] = useState("Profile")
  const [score, setScore] = useState(0);
  const [level, setLevel] = useState("");
  const [edit, setEdit] = useState(false);
  const [achievementCollapse, setAchievementCollapse] = useState(true);
  const [config, setConfig] = useState({})
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(true)
  const { navigate } = useNavigation();


  useEffect(() => {

    setLoading(true)

    firestore().collection("users").doc(userData.id).get().then(async (doc) => {
      const img = doc.data().image
      const url = img ? await storage().ref(img).getDownloadURL().then(res => res).catch(err => "") : ""
      setData({ id: doc.id, ...doc.data(), image: url })
      setLoading(false)
    }).catch(err =>
      console.log(err)
    )

  }, [])



  if (loading)
    return (
      <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
        <ActivityIndicator size="large" />
      </View>
    );

  return (
    <ScrollView
      contentContainerStyle={{ flexGrow: 1, width: "100%", maxWidth: 900, alignSelf: "center" }}
      showsVerticalScrollIndicator={false}
    >
      <View style={{ flexGrow: 1 }}>
        <View
          style={{
            height: 300,
            borderRadius: 15,
            overflow: "hidden",
            marginTop: 10,
            alignItems: "center",
            backgroundColor: customization.mainColor
          }}
        >
          <Image
            key={data.image}
            style={{
              width: "100%",
              height: 300,
              resizeMode: "cover"
            }}
            source={{ uri: data.image }}
          />

        </View>

        <View style={{ alignItems: "center", width: "100%", zIndex: 10, elevation: 12 }}>
          <View style={[styles.shadow, { borderRadius: 10 }]}>
            <ImageRel
              background={true}
              imageStyle={{ borderRadius: 10 }}
              source={"https://blacknosaur.s3.eu-west-3.amazonaws.com/bglevel.jpg"}
              backgroundColor={"white"}
              style={{
                minWidth: 320,
                borderRadius: 10,
                height: 85,
                paddingVertical: 15,
                marginTop: -45,
                alignItems: "center",
                justifyContent: "center"
              }}

            >
              <Text style={{ fontSize: 22, fontFamily: "DemiBold" }}>Nivel <Animatable.Text animation="fadeInUp" style={{ color: customization.accentColor }}>Avanzado</Animatable.Text></Text>

            </ImageRel>
          </View>
        </View>

        <View style={[styles.shadow, { paddingBottom: 30, borderBottomLeftRadius: 25, borderBottomRightRadius: 25, width: "100%", marginTop: -40, backgroundColor: "white", paddingTop: 60 }]}>
          <View style={{ flexDirection: "row", justifyContent: "space-evenly" }}>

            <View style={{ alignItems: "center" }}>
              <Text style={{ fontFamily: "DemiBold", fontSize: 45, color: customization.accentColor }}>{data.points ? data.points : 0}</Text>
              <Text style={{ fontFamily: "Regular", marginTop: -10, fontSize: 18 }}>Puntos</Text>
            </View>

            {/* <TouchableOpacity style={{ alignItems: "center" }}>
              <Text style={{ fontFamily: "DemiBold", fontSize: 45, color: customization.accentColor }}>1</Text>
              <Text style={{ fontFamily: "Regular", marginTop: -10, fontSize: 18 }}>Ranking</Text>
            </TouchableOpacity> */}
          </View>

        </View>

        <View style={{ flexDirection: "row", justifyContent: "space-around", marginTop: 10 }}>
          <Button color="gray" onPress={() => navigation.navigate("EditProfile")} style={{ margin: 5 }} label="Editar Perfil" />
          {/* <Button onPress={() => navigation.navigate("MyReviews")} style={{ margin: 5 }} label="Valoraciones" /> */}
        </View>

        <Animatable.View animation={"fadeInLeft"} style={{ width: "100%", padding: 10, flexGrow: 1 }}>

          <View style={[styles.shadow, { backgroundColor: "white", paddingHorizontal: 15, paddingVertical: 20, borderRadius: 20 }]}>
            <View style={{ marginTop: 0 }}>
              <Text style={{ fontSize: 16, marginBottom: 10, fontFamily: "DemiBold", color: "darkgray" }}>Sobre Mi</Text>
              <Text style={{ fontSize: 16, fontFamily: "Regular", color: "black" }}>{data.bio === "" || data.bio === null ? "Sin Biografía" : data.bio}</Text>
            </View>

            <View style={{ marginTop: 20 }}>
              <Text style={{ fontSize: 16, marginBottom: 10, fontFamily: "DemiBold", color: "darkgray" }}>Mi Puesto</Text>
              <Text style={{ fontSize: 16, fontFamily: "Regular", color: "black" }}>{data.position === "" || data.position === null ? "Sin Puesto" : data.position}</Text>
            </View>

            <View style={{ marginTop: 20 }}>
              <Text style={{ fontSize: 16, marginBottom: 10, fontFamily: "DemiBold", color: "darkgray" }}>Mi Equipo</Text>
              <Text style={{ fontSize: 16, fontFamily: "Regular", color: "black" }}>{data.team === "" || data.team === null ? "Sin Equipo" : data.team}</Text>
            </View>

            {/* <TouchableOpacity 
            onPress={() => {
              setAchievementCollapse(!achievementCollapse);
              // imageRef.current.bounceInLeft(1500).then(endState => console.log(endState.finished ? "finished bounce" : "cancelled bounce"))
            }} 
            style={{ marginTop: 20 }}>
              <View style={{ marginBottom: achievementCollapse ? 20 : 0}}>
                <Text style={{ fontSize: 16, fontFamily: "DemiBold", color: "darkgray", borderBottomColor:customization.mainColor , borderBottomWidth: achievementCollapse ? 2 : 0, borderRadius: 7, paddingBottom: achievementCollapse ? 10 : 0}}>Logros Conseguidos</Text>
              </View>
              <Collapsible collapsed={!achievementCollapse} >
                <FlatList 
                  data={data?.achievements}
                  renderItem={({item, index}) => (
                    <View style={{
                      flex: 1,
                      flexDirection: "column",
                      margin: 1
                    }}>
                    <Text style={{alignSelf: "center", fontSize: 20, marginBottom: 10, fontFamily: "DemiBold", color: "darkgray" }}>{item.name}</Text>
                    <Animatable.Image animation="bounceInLeft" duration={700} delay={100 * index} easing="ease-in" style={{alignSelf: "center", justifyContent: 'center', alignItems: 'center', height: 50, width: 50, marginBottom: 10}} source={{uri: item.image || 'https://www.flaticon.com/svg/vstatic/svg/1170/1170611.svg?token=exp=1618222951~hmac=13b361bb681557e55c6ad7298d603ebf'}}></Animatable.Image>
                    <Text style={{alignSelf: "center", marginBottom: 5, fontFamily: "DemiBold", fontSize: 20, color: customization.accentColor}}>{item.pointsToAchieve}</Text>
                    <Text style={{alignSelf: "center", fontSize: 16, fontFamily: "Regular", color: "darkgray", marginBottom: 15 }}>{item.description}</Text>
                    </View>
                  )}
                  numColumns={data?.achievements?.length < 5 ? 2 : 3}
                  keyExtractor={(item, index) => item.id}
                  />
              </Collapsible>
            </TouchableOpacity> */}
          </View>

          {/* <View style={{ marginTop: 30 }}>
            <Text style={{ fontSize: 16, marginBottom: 10, fontFamily: "DemiBold", color: "darkgray" }}>Mis Habilidades</Text>
            <View style={{ flex: 1, flexDirection: "row", flexWrap: "wrap" }}>
              {data.skills ? data.skills.split(',').map((el, i) => {
                return (
                  <View key={i} style={{ height: 35, alignItems: "center", margin: 5, borderRadius: 18, justifyContent: "center", backgroundColor: customization.mainColor, paddingHorizontal: 15, }}>
                    <Text style={{ fontSize: 16, fontFamily: "DemiBold", color: customization.contrastText }}>{el}</Text>
                  </View>
                )
              }) : <Text style={{ fontSize: 16, marginBottom: 10, fontFamily: "Regular", color: "black" }}>Sin Aficiones</Text>
              }
            </View>
          </View>

          <View style={{ marginTop: 30 }}>
            <Text style={{ fontSize: 16, marginBottom: 10, fontFamily: "DemiBold", color: "darkgray" }}>Mis Hobbies</Text>
            <View style={{ flex: 1, flexDirection: "row", flexWrap: "wrap" }}>

              {data.hobbies ? data.hobbies === "" ?
                <Text style={{ fontSize: 16, marginBottom: 10, fontFamily: "DemiBold", color: "darkgray" }}>Sin Aficiones</Text>
                :
                data.hobbies.split(',').map((el, i) => {
                  return (
                    <View key={i} style={{ height: 35, alignItems: "center", margin: 5, borderRadius: 18, justifyContent: "center", backgroundColor: customization.mainColor, paddingHorizontal: 15, }}>
                      <Text style={{ fontSize: 16, fontFamily: "DemiBold", color: customization.contrastText }}>{el}</Text>
                    </View>
                  )
                })
                :
                <Text style={{ fontSize: 16, marginBottom: 10, fontFamily: "Regular", color: "black" }}>Sin Habilidades</Text>
              }
            </View>
          </View> */}

        </Animatable.View>

      </View>
      <View style={{ padding: 5, marginHorizontal: 10, marginTop: 30, alignItems: "center", justifyContent: "center", borderRadius: 10, backgroundColor: "white" }}>
        <Text style={{ fontFamily: "Regular", textAlign: "center", fontSize: 16, color: "gray", marginVertical: 10 }}>App Versión: 1.1.8</Text>
      </View>

    </ScrollView>
  );
};

ProfileHome.navigationOptions = {
  header: null
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    marginBottom: 60
  },
  shadow: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 5
    },
    shadowOpacity: 0.2,
    shadowRadius: 10.5,
    elevation: 10
  },
  imageRedond: {
    height: 40,
    width: 40,
    borderRadius: 20,
    resizeMode: "cover",
    borderWidth: 1,
    borderColor: "gainsboro",
    marginRight: 10
  },
  topicBox: {
    width: 300,
    padding: 15,
    marginBottom: 10,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderWidth: 1,
    borderRadius: 4,
    borderColor: "gainsboro"
  },
  slideIn : {
    0: {
      opacity: .3,
      scale: .7,
    },
    .5: {
      opacity: .7,
      scale: .8,
    },
    1: {
      opacity: 1,
      scale: 1
    }
  }
});

export default ProfileHome;
