import React, { useContext, useEffect, useRef, useState } from "react";
import { ActivityIndicator, Alert, ImageBackground, ScrollView, StatusBar, StyleSheet, Text, TextInput, TouchableOpacity, TouchableWithoutFeedback, View } from "react-native";

import Header from '../../components/AppHeader';
import WithLocalStorage from '../../utils/localStore';


import { Ionicons } from '@expo/vector-icons';
import { StackActions } from '@react-navigation/native';
import * as Animatable from 'react-native-animatable';
import CircularIndicator from "../../components/CircularIndicator";
import LinearBarIndicator from '../../components/LinearBarIndicator';

import { useSafeArea } from 'react-native-safe-area-context';

import { firestore } from 'firebase';
import AuthContext from "../../context/AuthContext";

import PointsContext from "../../context/PointsContext";
import { decideColor } from "../../utils/functions";


const Exam = ({ id, route, navigation, exID }) => {


    const { userData, customization } = useContext(AuthContext)
    const { state, dispatch } = useContext(PointsContext);

    // VARIABLES BASICAS 
    const examID = exID || route.params.id;
    const index = route.params.index;

    const [loadIndicator, setLoadIndicator] = useState(false)
    const [results, showResults] = useState(false)
    const [percentageResults, setPercentageResults] = useState({})

    // NUEVOS
    const [courseAdvance, setCourseAdvance] = useState({})
    const [arrayContents, setArrayContents] = useState([])
    const [arrayContentsIndex, setArrayContentsIndex] = useState(null)
    const [sectionIndex, setSectionIndex] = useState(null)
    const [currentIndex, setCurrentIndex] = useState(null)
    const [examAdvance, setExamAdvance] = useState({})
    const [currentQuestion, setCurrentQuestion] = useState(null)
    const [selectedAnswer, setSelectedAnswer] = useState(null)
    const [showFeedback, setShowFeedback] = useState(false)
    const [openAnswerText, setOpenAnswerText] = useState("");
    const [userAdvance, setUserAdvance] = useState({});
    const [examData, setExamData] = useState({});


    const [data, setData] = useState({})

    const questionRef = useRef()
    const insets = useSafeArea()

    // ANIMACIONES
    const zoomIn = { 0: { scale: 1 }, 1: { scale: 1.06 } }
    const superPulse = { 0: { scale: 1 }, 1: { scale: 1.3 } }

    // Devuelve un booleano en función de si existe un elemento en el advance array del usuario con el id del examen 
    const isInitiated = () => {
        return state?.advance?.some(activity => activity.id === exID) ? true : false;
    }
    useEffect(() => {
        setLoadIndicator(true);
        const unsubscribe = state?.courseRef?.onSnapshot((querySnapshot) => {     
            setData(querySnapshot.data());
            setExamData(querySnapshot.data().advance[route.params.index].contents[0].advance);
            setSectionIndex(index);
            if(isInitiated()){
                // Si se encuentra en el estado del usuario una actividad con el id del examen, se continua el examen;
                console.log("Continuando examen");
                const exam = state.advance[state.advance.findIndex(exam => exam.id === exID)];
                setExamAdvance(exam.contents[0]);
                // Check si examen completado;
                // if(state.advance[state?.advance?.findIndex(exam => exam.id === exID)].contents[0].advance.length === querySnapshot.data().advance[route.params.index].contents[0].advance.length) {
                //     showResults(true);
                // }
                setCurrentQuestion(state?.advance[state?.advance?.findIndex(elem => elem.id === exID)]?.contents[0]?.advance?.length);
            } else {
                // Si no se encuentra en el estado del usuario es que es la primera vez que se realiza
                console.log("Inicializando examen");
                setCurrentQuestion(0);
                let exam = querySnapshot.data().advance[route.params.index];
                exam.contents[0].advance = [];
                state?.advanceRef?.update({advance: firestore.FieldValue.arrayUnion(exam)});
            }
        });
        
        return () => {
            unsubscribe();
            setLoadIndicator(false);
        }
    }, [])

    useEffect(() => {
        // ACTUALIZA EL ESTADO CUANDO LOS DATOS CAMBIAN
        data.advance
    }, [data])
 
    const changeHeader = () => {
        // CAMBIA LA CABECERA SI ESTA DURANTE EL EXAMEN O EN LOS RESULTADOS
        navigation.setOptions({
            header: ({ scene, previous, navigation }) => <View />
        });
    }

    const changeHeaderBack = () => {
        // CAMBIA LA CABECERA SI ESTA DURANTE EL EXAMEN O EN LOS RESULTADOS
        navigation.setOptions({
            headerTransparent: true,
            header: ({ scene, previous, navigation }) => <Header backButton={true} backCallback={() => navigation.goBack()} minimal />
        })
    }

    const checkAnswer = () => {
        if (examData[currentQuestion].answers[selectedAnswer - 1].correctAnswer) {
            setShowFeedback(true)
        } else {
            _nextQuestion()
        }
    }

    const setOpenAnswer = (idx, openAnswerText) => {
        setOpenAnswerText(openAnswerText);
        examAdvance.advance[idx].answers[0].text = openAnswerText;
    }
     
    const _nextQuestion = () => {
        if(examAdvance?.advance?.length === examData?.length) {
            showResults(true);
            finishExam(); 
        } else {
            setLoadIndicator(true);
            examData[currentQuestion].response = selectedAnswer;
            setExamAdvance({...examAdvance, advance: [...examAdvance.advance, examData[currentQuestion]]});

            questionRef.current.fadeOutLeft(200).then(() => {
            questionRef.current.fadeInRight(200);
            setCurrentQuestion(currentQuestion + 1);
            // examAdvance[currentQuestion + 1].response === null 
            // ?
            setSelectedAnswer(null)
            // :
            // setSelectedAnswer(examAdvance[currentQuestion + 1]?.response);
            })
            setLoadIndicator(false);
        }      
        // Índice del examen en el avance del usuario  
        let idx = state?.advance?.findIndex(elem => elem.id === exID);
        // Copia de la actividad del examen
        let examAdv = data?.advance[index];
        // La pregunta actual desde los datos del curso
        let q = data.advance[index].contents[0].advance[currentQuestion]
        // Añado la respuesta seleccionada a la pregunta
        // Chequeo si la pregunta esta o no ya contestada;
        if(!q.response) {
            q.response = selectedAnswer;
            // Copia del avance del estado del usuario
            let newAdvance = state.advance[idx].contents[0].advance;
            // si el array no esta vacío chequea si ha sido previamente contestada 
            newAdvance.length ? newAdvance.some(qe => qe.question === q.question) ? console.log("already answered") : newAdvance.push(q) : newAdvance = [q];
            // Seteo del avance actualizado
            examAdv.contents[0].advance = newAdvance;
            let newAdv = state?.advance;
            // Updateo el estado y actualizo firebase;
            newAdv[idx] = examAdv;
            state?.advanceRef.update({advance: newAdv});
        }
    }
 
    // previous question se queda, tal y como funciona
    const previousQuestion = async () => {
        questionRef.current.fadeOutLeft(200).then(() => {
            questionRef.current.fadeInRight(200)
            setCurrentQuestion(currentQuestion - 1)
            setSelectedAnswer(examAdvance[currentQuestion - 1]?.response)
        })
    }

    const _finishExam = () => {
        if(examAdvance.advance.length === examData.length){
            // Si el examen es la ultima actividad de curso 
            // 
        } else {
            // Hay mas contenidos después
        }
    }

    const finishExam = () => {
        console.log("navigating to itinerary");
        if (examAdvance?.advance?.length === examData.length && examAdvance.mandatory && examAdvance.showResults && examAdvance.tries >= examAdvance.maxTries) {
            if (arrayContents.length - 1 === arrayContentsIndex) {
                // SI EL EXAMEN ES EL ULTIMO CONTENIDO DEL CURSO
                navigation.navigate('smartnew/cursos')
            } else {
                // SI HAY MAS CONTENIDOS DESPUES NAVEGA A ESE CONTENIDO
                arrayContents[arrayContentsIndex + 1].contentType === "EXAM" ?
                    navigation.dispatch(
                        StackActions.replace('Exam',
                            {
                                advanceID,
                                id: arrayContents[arrayContentsIndex + 1].id,
                            })
                    )
                    :
                    navigation.navigate('PostSmart',
                        {
                            advanceID,
                            id: arrayContents[arrayContentsIndex + 1].id,
                        })
            }
        } else {
            const n = checkExam()
            const percentage = n / examAdvance.advance.length * 100
            setPercentageResults({ percentage, correct: n, total: examAdvance.advance.length })
            changeHeader()
            // // 1-DUPLICO EL AVANCE DEL EXAMEN
            // const newarrexam = examAdvance
            // // 2-LE ANADO LA RESPUESTA ENVIADA POR EL USUARIO
            // newarrexam.advance[currentQuestion].response = selectedAnswer
            // // SI EL PORCENTAJE CONSEGUIDO ES MENOR AL PORCENTAJE PARA APROBAR
            // newarrexam.status = !examAdvance.mandatory ? "COMPLETED" : percentage < examAdvance.percentageToPass ? "FAILED" : "COMPLETED"
            // if (percentage < examAdvance.percentageToPass) {
            //     newarrexam.tries = examAdvance.tries ? examAdvance.tries + 1 : 1
            // }
            // // 3-DUPLICO EL AVANCE DEL CURSO
            // const newarr = data.advance
            // // 4-CAMBIO EL ADVANCE DEL EXAMEN POR EL NUEVO CON LA RESPUESTA
            // newarr[sectionIndex].contents[currentIndex] = newarrexam
            // // 5-ACTUALIZA EN FIREBASE
            // questionRef.current.fadeOutLeft(200).then(() =>
            //     firestore().collection("users").doc(userData.id).collection("courseavs").doc(advanceID).update({ advance: newarr })
            //         .then(res => {
            //             setLoadIndicator(false)
            //             arrayContents[arrayContentsIndex].showResults ?
            //                 showResults(true)
            //                 :
            //                 completeCourse()
            //         })
            //         .catch(err => err => Alert.alert("Ha ocurrido un error al finalizar el examen, inténtelo más tarde"))
            // )
        }
    }

    const restartExam = () => {
        setLoadIndicator(true)
        const newarrexam = examAdvance;

        newarrexam.advance = examAdvance.advance.map((q, i, arr) => {
            const newq = { ...q }
            delete newq.response
            return newq
        })
        newarrexam.status = "INITIATED"
        const newarr = data.advance
        newarr[sectionIndex].contents[currentIndex] = newarrexam

        firestore().collection("users").doc(userData.id).collection("courseavs").doc(advanceID).update({ advance: newarr })
            .then(res => {
                setLoadIndicator(false)
                showResults(false)
                changeHeaderBack()
                questionRef.current.fadeOutLeft(200).then(() => {
                    setSelectedAnswer(null)
                    setCurrentQuestion(0)
                    questionRef.current.fadeInRight(200)
                })
            }).catch(err => {
                Alert.alert("Ha ocurrido un error al reiniciar tu examen, por favor inténtalo más tarde")
                setLoadIndicator(false)
            })
    }

    useEffect(() => {
        console.log("examAdvance", examAdvance);
        
    }, [examAdvance])

    const checkExam = () => {
        const answeredCorrectly = examAdvance.advance.map(question => {
            return question.answers[question.response - 1].correctAnswer ? 1 : 0;
        }).reduce((acc, curr) => acc + curr);

        // let correctAnswers = parseFloat((answeredCorrectly / examData.length) * 100).toFixed(2);
        
        // const arr = examAdvance.advance.map((q, i, arr) => i === examAdvance.advance.length - 1 ? q.answers[selectedAnswer - 1].correctAnswer : q.answers[q.response - 1].correctAnswer)
        // const correctAnswers = arr.filter(v => v).length
        return answeredCorrectly;
    }

    const prepareNext = () => {
        var newarr = courseAdvance.advance;
        newarr[sectionIndex].status = courseAdvance.advance[sectionIndex].contents.length - 1 === currentIndex ? "COMPLETED" : "INITIATED"
        // newarr[sectionIndex].contents[currentIndex].status = "CO<MPLETED"
        if (newarr[sectionIndex].contents[currentIndex + 1]) {
            newarr[sectionIndex].contents[currentIndex + 1].status = "INITIATED"
        }
        if (newarr[sectionIndex + 1] && courseAdvance.advance[sectionIndex].contents.length - 1 === currentIndex) {
            newarr[sectionIndex].status = "INITIATED"
            if (newarr[sectionIndex + 1].contents[0]) {
                newarr[sectionIndex + 1].contents[0].status = "INITIATED"
            }
        }
        return newarr
    }

    const completeCourse = () => {
        setLoadIndicator(true)
        if (arrayContents.length - 1 === arrayContentsIndex) {
            // SI EL EXAMEN ES EL ULTIMO CONTENIDO DEL CURSO
            navigation.navigate('CompleteCourse', {
                id: advanceID
            })
        } else {
            // SI HAY MAS CONTENIDOS DESPUES NAVEGA A ESE CONTENIDO
            const newarr = prepareNext()
            firestore().collection("users").doc(userData.id).collection("courseavs").doc(advanceID).update({ advance: newarr })
                .then(dat => {
                    arrayContents[arrayContentsIndex + 1].contentType === "EXAM" ?
                        navigation.dispatch(
                            StackActions.replace('Exam',
                                {
                                    advanceID,
                                    id: arrayContents[arrayContentsIndex + 1].id,
                                })
                        )
                        :
                        navigation.navigate('PostSmart',
                            {
                                advanceID,
                                id: arrayContents[arrayContentsIndex + 1].id,
                            })
                })
                .catch(err => {
                    Alert.alert("Ha ocurrido un error al completar el examen, por favor, inténtelo más tarde")
                })
        }
    }

    return ( 
        <View
        // start={[0, 1]}
        // colors={results ? ["white", "white"] : [customization.gradientMainColor, customization.gradientSecondaryColor]}
        style={{ backgroundColor: customization.mainColor, flex: 1, paddingTop: !results ? insets.top + 10 : 10 }}>

        <StatusBar barStyle="light-content" />

        <View style={{ justifyContent: "center", minHeight: 50, alignItems: "center", marginLeft: 70, marginRight: 20 }}>
                        {((currentQuestion + 1) / examAdvance?.advance?.length * 100) - (currentQuestion + 1 === examAdvance?.advance?.length ? 2 : 0) !== Infinity &&
                        <LinearBarIndicator 
                        percentage={((currentQuestion) / examAdvance?.advance?.length * 100) - (currentQuestion === examAdvance?.advance?.length ? 2 : 0)}
                        backgroundColor="white" gradient={['#B4EC51', '#429321']} width="100%" rounded={5} height={10} />}
                        {/* {examData.map((question, i) => {
                            return (
                                <Animatable.View
                                    animation={i === currentQuestion ? superPulse : null}
                                    direction="alternate"
                                    iterationCount="infinite"
                                    key={i}
                                    style={{
                                        width: 20,
                                        height: 20,
                                        borderColor: i === currentQuestion ? "#3A9811" : "red",
                                        borderRadius: 10,
                                        margin: 5,
                                        borderWidth: question.response !== null ? 1 : 1,
                                        backgroundColor: i === currentQuestion ? "#5ACA11" : 'white'
                                    }}
                                />
                            )
                        })} */}
        </View>

        {!results ?
        <View style={{ flex: 1 }}>
                    <ScrollView contentContainerStyle={{ flexGrow: 1, width: "100%", maxWidth: 800, alignSelf: "center" }}>
                        <View style={{ width: "100%", paddingTop: 20, paddingHorizontal: 10, justifyContent: 'center', marginBottom: 10 }}>
                            <Text style={{ fontSize: 22, color: decideColor(customization.mainColor), fontFamily: 'DemiBold' }}>{currentQuestion !== null ? examData[currentQuestion]?.question : " "}</Text>
                        </View>

                        <Animatable.View ref={questionRef} duration={250} animation={"fadeIn"} style={{ flex: 1, paddingHorizontal: 10, justifyContent: 'center' }}>
                            {currentQuestion !== null && examData[currentQuestion]?.answers.map((answer, i) => {
                                if(examData[currentQuestion]?.openAnswer){
                                    return (
                                        <View key={i}>
                                            <TouchableWithoutFeedback
                                            disabled={(examData[currentQuestion].response && examData[currentQuestion].withFeedback)}
                                            onPress={() => { setSelectedAnswer(i + 1)}} >
                                                <Animatable.View duration={200} style={[styles.answer, styles.shadow]} >
                                                    <TextInput
                                                        placeholder="Escribe aquí tu respuesta..." 
                                                        disabled={examAdvance?.advance?.length === examData.length ? true : false}
                                                        autofocus 
                                                        multiline
                                                        numberOfLines={4}
                                                        value={examAdvance[currentQuestion].answers[0].text || openAnswerText} 
                                                        onChange={(e) => setOpenAnswer(currentQuestion, e.target.value) }>    
                                                    </TextInput>
                                                </Animatable.View>
                                            </TouchableWithoutFeedback>
                                        </View>
                                    )
                                }
                                return (
                                    <View key={i}>
                                        <TouchableWithoutFeedback
                                            disabled={(examData[currentQuestion].response)}
                                            onPress={() => { setSelectedAnswer(i + 1)}} >
                                            <Animatable.View duration={200} animation={i === selectedAnswer - 1 ? zoomIn : null} style={[styles.answer, styles.shadow, selectedAnswer === i + 1 ? styles.active : null]} >
                                                <Text style={{ color: i + 1 === selectedAnswer ? "white" : "black", fontFamily: i + 1 === selectedAnswer ? 'Bold' : 'DemiBold' }}>{answer.text}</Text>
                                            </Animatable.View>
                                        </TouchableWithoutFeedback>
                                        {examAdvance?.advance?.length === examData.length && answer.correctAnswer &&
                                            <View style={[styles.shadow, { backgroundColor: "white", borderRadius: 20, borderWidth: 2, alignItems: "center", justifyContent: "center", position: "absolute", width: 40, height: 40, zIndex: 20, elevation: 20, top: -10, right: -5 }]}>
                                                <Ionicons size={37} name="ios-checkmark-circle-outline" color="orange" />
                                            </View>
                                        }
                                    </View>
                                )
                            })}
                        </Animatable.View>
                    </ScrollView>

                    <View style={{ height: 70, alignItems: 'center', flexDirection: 'row' }}>

                    {currentQuestion > 0 &&
                        <TouchableOpacity onPress={() => { previousQuestion() }} style={{ paddingHorizontal: 25, opacity: .8, flex: 1, position: 'absolute', left: 10, height: 50, bottom: (insets.bottom / 2) + 10, backgroundColor: 'gainsboro', borderRadius: 7, alignItems: 'center', justifyContent: 'center' }}>
                            <Text style={{ color: 'black', fontFamily: 'DemiBold' }}>Anterior</Text>
                        </TouchableOpacity>
                    }
                    {/* currentQuestion === examAdvance.advance.length - 1 ? finishExam() :  */}
                    {selectedAnswer &&
                        <TouchableOpacity
                            disabled={!loadIndicator}
                            onPress={() => (examAdvance[currentQuestion]?.response && currentQuestion - 1 !== examAdvance.advance.length) ? checkAnswer() : _nextQuestion()}
                            style={{ paddingHorizontal: 25, flex: 1, position: 'absolute', right: 10, height: 50, backgroundColor: 'white', borderColor: "gainsboro", borderWidth: 4, bottom: (insets.bottom / 2) + 10, borderRadius: 7, alignItems: 'center', justifyContent: 'center' }}
                        >
                            {
                            // loadIndicator ?
                            //     <ActivityIndicator color="black" style={{ height: 20, width: 20 }} />
                            //   :
                                <Text style={{ color: 'black', fontFamily: 'DemiBold' }}>
                                    {(examAdvance[currentQuestion] && !examAdvance[currentQuestion].response) ? "Comprobar" : (currentQuestion === examAdvance.length - 1) ? "Finalizar" : "Siguiente"}
                                </Text>
                            }
                        </TouchableOpacity>
                    }

                    {currentQuestion === examAdvance?.advance?.length - 1 &&
                        <TouchableOpacity onPress={() => examAdvance?.advance?.length !== examData?.length ? _nextQuestion() : finishExam()} style={{ paddingHorizontal: 25, flex: 1, position: 'absolute', right: -5, height: 50, backgroundColor: 'white', borderColor: "gainsboro", borderWidth: 4, bottom: (insets.bottom / 2) + 10, borderRadius: 7, alignItems: 'center', justifyContent: 'center' }}>
                            <Text style={{ color: 'black', fontFamily: 'DemiBold' }}>{examAdvance?.advance?.length === examData?.length ? "Salir" : loadIndicator ? <ActivityIndicator color="black" style={{ height: 20, width: 20 }} /> : data.final ? "Terminar" : "Comprobar"}</Text>
                        </TouchableOpacity>
                    }
            </View>


        </View>
        :
                        // RESULTADOS EXAMEN
                        <ImageBackground style={{ flex: 1, width: '100%' }} resizeMethod="scale">
                        <ScrollView contentContainerStyle={{ marginHorizontal: 10, flexGrow: 1 }}>
                            <Animatable.View animation="fadeIn" duration={600} style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
                                <View style={{ maxWidth: 350, alignItems: "center" }}>
                                    <Text style={{ fontSize: 36, fontFamily: "DemiBold", textAlign: "center", color: "white" }}>Tu resultado</Text>
                                    <View style={[styles.shadow, { backgroundColor: "white", padding: 25, paddingVertical: 10, borderRadius: 7, marginTop: 20, marginBottom: 20 }]}>
                                        <Text style={{ fontSize: 42, textAlign: "center", fontFamily: "DemiBold" }}>
                                            {percentageResults.correct} de {percentageResults.total}
                                        </Text>
                                    </View>
                                </View>
                                <View style={{ alignItems: "center", justifyContent:"center", marginVertical: 10, marginBottom: 30, width: 220, height: 220 }}>
                                    <CircularIndicator r={95} strokeWidth={12} percentage={percentageResults.percentage} />
                                    <View style={{ position: "absolute", width: "100%", height: "100%", alignItems: "center", justifyContent: "center" }}>
                                        <Text style={{ fontSize: 40, fontFamily: "Bold", color: "white" }}>{Math.round(percentageResults.percentage)}%</Text>
                                    </View>
                                </View>
                                <View style={{ maxWidth:900, marginVertical: 15, backgroundColor: "#f5f5f5", width: "100%", paddingVertical: 15, paddingHorizontal: 10, borderRadius: 10 }}>
                                    <Text style={{ fontSize: 18, textAlign: "center", fontFamily: "Regular" }}>
                                        {examAdvance.percentageToPass <= percentageResults.percentage ?
                                            "Has superado esta evaluación con éxito!"
                                            :
                                            "No has superado la evaluación. Necesitabas al menos contestar correctamente el " +
                                            Math.round(examAdvance.percentageToPass) +
                                            "% de las preguntas."}
                                    </Text>
    
                                    {examAdvance.mandatory && examAdvance.maxTries && examAdvance.percentageToPass >= percentageResults.percentage &&
                                        <Text style={{ marginTop: 10, fontSize: 18, textAlign: "center", fontFamily: "Regular" }}>
                                            Llevas {examAdvance.tries} intentos de {examAdvance.maxTries}
                                        </Text>
                                    }
                                </View>
                                <View style={{ flexDirection: "row" }}>
                                    {examAdvance.mandatory && examAdvance.percentageToPass >= percentageResults.percentage && examAdvance.tries < examAdvance.maxTries &&
                                        <TouchableOpacity onPress={() => restartExam()} style={{ marginRight: 15, paddingHorizontal: 40, marginTop: 10, alignItems: "center", justifyContent: "center", backgroundColor: "white", height: 50, borderRadius: 10 }}>
                                            {loadIndicator ?
                                                <ActivityIndicator style={{ height: 20, width: 20 }} color={customization.mainColor} />
                                                :
                                                <Text style={{ fontSize: 16, fontFamily: "DemiBold", color: customization.mainColor }}>Volver a Intentarlo</Text>
                                            }
                                        </TouchableOpacity>
                                    }
                                    {(!examAdvance.mandatory || examAdvance.percentageToPass <= percentageResults.percentage || examAdvance.tries >= examAdvance.maxTries) &&
                                        <TouchableOpacity onPress={() => completeCourse()} style={{ paddingHorizontal: 40, marginTop: 10, alignItems: "center", justifyContent: "center", backgroundColor: "white", height: 50, borderRadius: 10 }}>
                                            {loadIndicator ?
                                                <ActivityIndicator style={{ height: 20, width: 20 }} color={customization.mainColor} />
                                                :
                                                <Text style={{ fontSize: 16, fontFamily: "DemiBold", color: customization.mainColor }}>Continuar</Text>
                                            }
                                        </TouchableOpacity>
                                    }
                                </View>
                            </Animatable.View>
                        </ScrollView>
                    </ImageBackground >}
        </View>)
}

export default WithLocalStorage(Exam);

const styles = StyleSheet.create({
    active: {
        backgroundColor: '#41C714',
        borderColor: "#119429"
    },
    answer: {
        minHeight: 70,
        justifyContent: "center",
        marginHorizontal: 20,
        padding: 10,
        borderWidth: 4,
        borderColor: "#f5f5f5",
        backgroundColor: "white",
        borderRadius: 15,
        marginBottom: 10
    },
    shadow: {
        shadowColor: "rgba(0, 0, 0, 0.3)",
        borderColor: "#f5f5f5",
        borderWidth: 6,
        shadowOffset: { width: 0, height: 10 },
        shadowOpacity: 0.5,
        shadowRadius: 10,
        elevation: 10
    }
});