import React, { useState, useEffect, useRef, useContext } from "react";
import { Text, View, TextInput, TouchableOpacity, ScrollView } from "react-native";
import { useNavigation } from '@react-navigation/native';
import { useSafeArea } from 'react-native-safe-area-context';
import Button from '../common/Button'
import AuthContext from "../../context/AuthContext";
import { decideColor } from "../../utils/functions";

const AnswerMission = ({ data, userResponse, responseCallback }) => {

  const [answer, setAnswer] = useState("");
  const [writing, setWriting] = useState(false)
  const { navigate } = useNavigation();

  const { customization } = useContext(AuthContext)

  const insets = useSafeArea();
  const scrollRef = useRef()

  useEffect(() => {
    if (userResponse) {
      setAnswer(userResponse);
    }
  });

  useEffect(() => {
    scrollRef.current.scrollToEnd()
  }, [writing])

  return (
    <View style={{ flex: 1 }}>

      <ScrollView
        ref={scrollRef}
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ flexGrow: 1, alignItems: "center", paddingBottom: !writing ? 0 : 300 }}>

        <View
          style={{ backgroundColor: customization.mainColor, paddingTop: insets.top + 100, paddingHorizontal: 15, width: "100%", paddingBottom: 100 }}
        >

          <Text style={{
            fontFamily: "DemiBold",
            color: decideColor(customization.mainColor),
            fontSize: data.content.question.length > 130 ?
              data.content.question.length > 250 ?
                14
                :
                18
              :
              24,
            marginBottom: 10
          }}>
            {data.content.question}
          </Text>

        </View>
        <View style={{ marginTop: -70, flex: 1, width: "100%", alignItems: "center", paddingHorizontal: 10 }}>
          <TextInput
            onFocus={ev => setWriting(!writing)}
            onBlur={ev => setWriting(!writing)}
            style={{
              shadowColor: '#000000',
              shadowOffset: {
                width: 0,
                height: 3
              },
              shadowRadius: 10,
              elevation: 10,
              shadowOpacity: .3,
              minHeight: 190,
              width: "100%",
              maxHeight: 250,
              paddingTop: 10,
              paddingHorizontal: 15,
              fontSize:16,
              fontFamily:"Regular",
              backgroundColor: "#fcfcfc",
              borderRadius: 10
            }}
            placeholder="Tu respuesta...."
            multiline
            maxLength={data.content.numberOfCharacters ? data.content.numberOfCharacters : null}
            textAlignVertical="center"
            numberOfLines={4}
            onChangeText={answer => setAnswer(answer)}
            value={answer}
            editable={userResponse ? false : true}
          />
          {data.content.characterLimit &&
            <View style={{ width: "100%" }}>
              <Text style={{ fontFamily: "DemiBold", color: "gray", textAlign: "right", marginTop: 15, fontSize: 14 }}>{answer.length} de {data.content.numberOfCharacters} caracteres</Text>
            </View>
          }


        </View>

      </ScrollView>

      <Button
        width="95%"
        color={customization.mainColor}
        label="Comprobar"
        style={{ position: "absolute", bottom: insets.bottom, alignSelf: "center" }}
        onPress={() => {
          if (userResponse) {
            navigate("TrainingHome");
          } else {
            responseCallback(answer);
          }
        }}
        disabled={answer === ""}
      />


      {/* {!userResponse &&
        <View style={{ position: "absolute", top: 0, right: -20, marginBottom: insets.bottom + 10, marginTop: insets.top + 15, opacity: answer === "" ? .5 : 1, }}>
          <TouchableOpacity onPress={() => {
            if (userResponse) {
              navigate("TrainingHome");
            } else {
              responseCallback(answer);
            }
          }}
            disabled={answer === ""}
            style={{ backgroundColor: "white", height: 50, paddingHorizontal: 35, borderRadius: 25, alignItems: "center", justifyContent: "center" }}>
            <Text style={{
              fontFamily: "DemiBold",
              fontSize: 16,
              color: "black"
            }}>{userResponse ? "Volver" : "Guardar Respuesta"}</Text>
          </TouchableOpacity>
        </View>} */}
    </View>
  );
};

export default AnswerMission;
