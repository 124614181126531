import React, { useRef, useEffect, useState, useContext } from 'react';
import {
    Text,
    View,
    TouchableOpacity,
    Image,
    Platform
} from "react-native";
import * as Animatable from 'react-native-animatable';
import { Camera } from 'expo-camera';
import * as ImagePicker from "expo-image-picker";
import VideoPlayer from "../videoPlayer/videoPlayer";

import * as Permissions from "expo-permissions";
import { Ionicons } from "@expo/vector-icons";
import Button from './Button';
import AuthContext from '../../context/AuthContext';

const CamModal = ({ response, closeCallback, maxDuration = null, AV = "All", initialCamera = "front" }) => {
    const [hasPermission, setHasPermission] = useState(null);
    const [recording, setRecording] = useState(false)
    const [mode, setMode] = useState(AV === "All" ? "Video" : AV === "Video" ? "Video" : "Photo")
    const [type, setType] = useState(Camera.Constants.Type[initialCamera])
    const [reviewing, setReviewing] = useState(null)

    const camRef = useRef()
    const camContainer = useRef()

    const { customization } = useContext(AuthContext)

    useEffect(() => {
        permissionsCheckAsync()
    }, []);


    const permissionsCheckAsync = async () => {

        const { status: perCam } = await Permissions.getAsync(Permissions.CAMERA);
        const { status: perCamAudio } = await Permissions.getAsync(Permissions.AUDIO_RECORDING);
        const { status: perGallery } = await Permissions.getAsync(Permissions.CAMERA_ROLL);

        setHasPermission({
            camera: perCam === "granted",
            audio: perCamAudio === "granted",
            gallery: perGallery === "granted"
        })
    };

    const updatePermissions = async (type) => {
        await Permissions.askAsync(type)
        permissionsCheckAsync();
    }

    const pickImage = async (field, type) => {
        let result = {};

        result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: AV === "All" ? ImagePicker.MediaTypeOptions.All : AV === "Video" ? ImagePicker.MediaTypeOptions.Videos : ImagePicker.MediaTypeOptions.Images,
            allowsEditing: true,
            quality: 1,
            allowsMultipleSelection: false
        });

        if (!result.cancelled) {
            // uploadMedia(result);
            camContainer.current.animate(flip)
            setReviewing(result)
        } else {
            // setLoading(false);
        }
    };

    const foto = async () => {
        if (camRef) {
            let photo = await camRef.current.takePictureAsync({
                quality: .1
            });
            camContainer.current.animate(flip)
            setReviewing({ ...photo, type: "image" })
        }
    };

    const video = async () => {
        if (camRef) {
            if (!recording) {
                const values = maxDuration ? { maxDuration: maxDuration } : null
                camRef.current.recordAsync(values)
                    .then(video => {
                        setReviewing({ ...video, type: "video" })
                        camContainer.current.animate(flip)
                        setRecording(false)
                    });
                setRecording(true)
            } else {
                camRef.current.stopRecording()
            }
        }
    }

    const discard = () => {
        setReviewing(null)
        camContainer.current.animate(flip)
    }

    const save = () => {
        response(reviewing)
        closeCallback()
    }

    if (hasPermission === null) {
        return <View />;
    }
    if (hasPermission.camera === false || (hasPermission.audio === false && Platform.OS === "android") || hasPermission.gallery === false && Platform.OS !== "web") {
        return (
            <View style={{ flex: 1, alignItems: "center", justifyContent: "center", backgroundColor: "white" }}>

                <TouchableOpacity style={{ height: 80, width: 80, alignItems: "center", justifyContent: "center", position: "absolute", top: 0, right: 0 }} onPress={() => closeCallback()}>
                    <Ionicons name="md-close" size={40} color="black" />
                </TouchableOpacity>
                <Text style={{ marginHorizontal: 20, marginBottom: 20, fontFamily: "Regular", fontSize: 16 }}>Necesitas garantizar acceso a las siguientes funcionalidades para continuar.</Text>
                <View style={{ marginVertical: 20 }}>
                    <TouchableOpacity onPress={async () => updatePermissions(Permissions.CAMERA)} style={{ flexDirection: "row", alignItems: "center" }}>
                        <Ionicons name={!hasPermission.camera ? "md-radio-button-off" : "md-checkmark-circle"} size={30} />
                        <Text style={{ marginHorizontal: 20, color: "black", fontFamily: "DemiBold", fontSize: 16 }}>Acceso a la cámara</Text>
                    </TouchableOpacity>
                    {Platform.OS == "android" && <TouchableOpacity onPress={async () => updatePermissions(Permissions.AUDIO_RECORDING)} style={{ flexDirection: "row", alignItems: "center" }}>
                        <Ionicons name={!hasPermission.audio ? "md-radio-button-off" : "md-checkmark-circle"} size={30} />
                        <Text style={{ marginHorizontal: 20, color: "black", fontFamily: "DemiBold", fontSize: 16 }}>Acceso a grabar audio</Text>
                    </TouchableOpacity>}
                    <TouchableOpacity onPress={async () => updatePermissions(Permissions.CAMERA_ROLL)} style={{ flexDirection: "row", alignItems: "center" }}>
                        <Ionicons name={!hasPermission.gallery ? "md-radio-button-off" : "md-checkmark-circle"} size={30} />
                        <Text style={{ marginHorizontal: 20, color: "black", fontFamily: "DemiBold", fontSize: 16 }}>Acceso a la galería</Text>
                    </TouchableOpacity>

                </View>
                {/* <TouchableOpacity onPress={() => permissionsCheckAsync()} style={{ height: 50, width: 250, alignItems: "center", justifyContent: "center", marginVertical: 10, borderRadius: 25, backgroundColor: "#017676" }}>
                    <Text style={{ marginHorizontal: 20, color: "white", fontFamily: "DemiBold", fontSize: 16 }}>Pedir Permisos</Text>
                </TouchableOpacity> */}

                {hasPermission.gallery &&
                    <Button onPress={() => pickImage()} color={customization.mainColor} label="Galería de Imágenes" />
                }
            </View>
        )
    }

    const flip = {
        0: {
            // opacity: 0,
            // scale: 1,
            rotateY: '180deg'
        },
        1: {
            // opacity: 1,
            // scale: 0,
            rotateY: '0deg'
        },
    };

    return (
        <Animatable.View ref={camContainer} style={{ flex: 1, borderRadius: 20, backgroundColor: "white" }}>
            {reviewing ?
                <View style={{ flex: 1, borderRadius: 20, backgroundColor: "black" }}>
                    {mode === "Photo" ?
                        <Image source={{ uri: reviewing.uri }} style={{ flex: 1, borderRadius: 20, resizeMode: "cover" }} />
                        :
                        <VideoPlayer style={{ flex: 1 }} loop={true} controls={false} shouldPlay={true} useNativeControls={true} resizeMode="cover" source={{ uri: reviewing.uri }} />
                    }

                    <View style={{ flexDirection: "row", width: "100%", justifyContent: "space-between", height: 50, position: "absolute", bottom: "50%" }}>
                        <TouchableOpacity style={{ backgroundColor: "rgba(0,0,0,.5)", borderTopRightRadius: 40, borderBottomRightRadius: 40, height: 80, width: 80, alignItems: "center", justifyContent: "center" }} onPress={() => discard()}>
                            <Ionicons name="ios-arrow-back" size={40} color="white" />
                        </TouchableOpacity>
                        <TouchableOpacity style={{ backgroundColor: "rgba(0,0,0,.5)", borderTopLeftRadius: 40, borderBottomLeftRadius: 40, height: 80, width: 80, alignItems: "center", justifyContent: "center" }} onPress={() => save()}>
                            <Ionicons name="md-checkmark-circle" size={40} color="white" />
                        </TouchableOpacity>
                    </View>
                </View>
                :
                null
            }
            {!reviewing &&
                <Camera ref={camRef} ratio="16:9" useCamera2Api style={{ flex: 1 }} type={type}>
                    <View
                        style={{
                            flex: 1,
                            backgroundColor: 'transparent',
                            flexDirection: 'row',
                        }}>


                        <TouchableOpacity style={{ height: 80, width: 80, alignItems: "center", justifyContent: "center", position: "absolute", top: 0, right: 0 }} onPress={() => closeCallback()}>
                            <Ionicons name="md-close" size={40} color="white" />
                        </TouchableOpacity>


                        {AV === "All" &&
                            <View style={{ position: "absolute", top: 20, left: 20, flexDirection: "row", height: 40, width: 80, borderRadius: 20, borderWidth: 1, borderColor: "white" }}>
                                <TouchableOpacity disabled={mode === "Photo"} style={{ opacity: mode === "Photo" ? 1 : 0.3, height: 40, width: 40, alignItems: "center", justifyContent: "center" }} onPress={() => setMode("Photo")}>
                                    <Ionicons name="md-camera" size={20} color="white" />
                                </TouchableOpacity>
                                <TouchableOpacity disabled={mode === "Video"} style={{ opacity: mode === "Video" ? 1 : 0.3, height: 40, width: 40, alignItems: "center", justifyContent: "center" }} onPress={() => setMode("Video")}>
                                    <Ionicons name="md-videocam" size={20} color="white" />
                                </TouchableOpacity>
                            </View>
                        }

                        <View style={{ position: "absolute", bottom: 20, width: "100%", alignItems: "center" }}>
                            <TouchableOpacity
                                style={{ height: 70, width: 70, borderRadius: 35, alignItems: "center", justifyContent: "center", borderColor: "white", borderWidth: 3, opacity: .7 }}
                                onPress={() => mode === "Video" ? video() : foto()}
                            >
                                {/* <Ionicons name={"md-play"} size={32} /> */}
                                <Animatable.View animation="pulse" easing="ease-out" iterationCount="infinite" style={{ opacity: !recording ? 0 : 1, backgroundColor: "#017676", width: 50, height: 50, borderRadius: 25 }} />
                            </TouchableOpacity>
                        </View>

                        <TouchableOpacity style={{ height: 70, width: 70, alignItems: "center", justifyContent: "center", position: "absolute", bottom: 20, left: 20 }}
                            onPress={() => {
                                setType(
                                    type === Camera.Constants.Type.back
                                        ? Camera.Constants.Type.front
                                        : Camera.Constants.Type.back
                                );
                            }}>
                            <Ionicons name="md-reverse-camera" size={30} color="white" />
                        </TouchableOpacity>

                        <TouchableOpacity style={{ height: 70, width: 70, alignItems: "center", justifyContent: "center", position: "absolute", bottom: 20, right: 20 }} onPress={() => pickImage()}>
                            <Ionicons name="md-images" size={30} color="white" />
                        </TouchableOpacity>
                    </View>
                </Camera>
            }
        </Animatable.View>
    )
}

export default CamModal