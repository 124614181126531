import React, { useEffect, useState } from 'react';
import { StyleSheet, View, Animated, Easing, Text, Linking } from 'react-native'


const processValue = (value, key) => {

    const bottom = `${key}Bottom`
    const top = `${key}Top`
    const left = `${key}Left`
    const right = `${key}Right`

    const processed = typeof value === 'object' && value !== null ?
        {
            [bottom]: value.bottom || 0,
            [top]: value.top || 0,
            [left]: value.left || 0,
            [right]: value.right || 0
        }
        :
        { [key]: value }

    return processed
}

export const Line = ({ fontSize = 16, margin = 0, borderRadius = 7, padding = 0, opacity = 0, color = "gainsboro", extraStyles }) => {
    const processPadding = processValue(padding, "padding")
    const processMargin = processValue(margin, "margin")

    return <Animated.View style={[styles.base, { height: fontSize, opacity: opacity, borderRadius, ...processMargin, ...processPadding, backgroundColor: color, ...extraStyles }]} />
}

export const Element = ({
    height = "auto",
    width = "auto",
    color = "gainsboro",
    extraStyles,
    margin = 0,
    aspectRatio,
    opacity,
    borderRadius = 7
}) => {
    const processMargin = processValue(margin, "margin")

    return <Animated.View style={{ height: height, ...processMargin, width: width, aspectRatio, backgroundColor: color, opacity: opacity, borderRadius, ...extraStyles }} />
}

export const Paragraph = ({ lines = 1, margin = 0, fontSize = 16, gap = 5, borderRadius, extraStyles = {}, textStyles = {}, color = "gainsboro", opacity = 0 }) => {

    const arr = Array.from(Array(lines).keys())
    const processMargin = processValue(margin, "margin")

    return (
        <View style={{
            flexGrow: 1,
            ...processMargin,
            ...extraStyles
        }}
        >
            {arr.map((el, i) =>
                <Line
                    key={i}
                    color={color}
                    opacity={opacity}
                    fontSize={fontSize}
                    borderRadius={borderRadius}
                    margin={{
                        right: Math.random() * 30,
                        left: Math.random() * 30,
                        top: i > 0 ? gap : 0
                    }}
                    extraStyles={textStyles}
                />
            )}
        </View>
    )
}

export const Block = ({
    children = [],
    direction = "column",
    backgroundColor = "white",
    color = "gainsboro",
    padding = 0,
    margin = 0,
    gap = 0,
    align,
    justify,
    opacity = LoadAnimation(200),
    fontSize = 16,
    borderRadius = 10,
    aspectRatio,
    height = "auto",
    width = "auto",
    card = false,
    flex
}) => {

    const processPadding = processValue(padding, "padding")
    const processMargin = processValue(margin, "margin")
    const cardStyles = card ? { ...styles.card } : {}

    return (
        <View style={{ ...cardStyles, flex, aspectRatio, borderRadius, height, backgroundColor, alignItems: align, justifyContent: justify, ...processMargin, width, ...processPadding, flexDirection: direction }}>

            {Array.isArray(children) ? children.map((el, i) => {

                const childrenMargin = typeof el.props.margin === 'object' && el.props.margin !== null ?
                    { top: el.props.margin.top ? el.props.margin.top : 0, bottom: el.props.margin.bottom ? el.props.margin.bottom : 0, left: el.props.margin.left ? el.props.margin.left : 0, right: el.props.margin.right ? el.props.margin.right : 0 }
                    :
                    { top: el.props.margin, bottom: el.props.margin, left: el.props.margin, right: el.props.margin }


                return {
                    ...el,
                    props: {
                        ...el.props,
                        color: el.props.color ? el.props.color : color,
                        opacity: el.props.opacity ? el.props.opacity : opacity,
                        fontSize: el.props.fontSize ? el.props.fontSize : fontSize,
                        borderRadius: el.props.borderRadius ? el.props.borderRadius : borderRadius,
                        margin: {
                            ...childrenMargin,
                            right: childrenMargin.right ? (direction === "row" && i + 1 < children.length) ? childrenMargin.right + gap : childrenMargin.right : (direction === "row" && i + 1 < children.length) ? gap : 0,
                            bottom: childrenMargin.bottom ? (direction === "column" && i + 1 < children.length) ? childrenMargin.bottom + gap : childrenMargin.bottom : (direction === "column" && i + 1 < children.length) ? gap : 0,
                        }

                    }
                }
            })
                :
                children
            }
        </View>
    )
}

export const LoadAnimation = ({ speed }) => {

    const [opacity] = useState(new Animated.Value(.4))

    const loading = () => {
        Animated.loop(
            Animated.sequence([
                Animated.timing(opacity, {
                    toValue: 1,
                    duration: speed,
                    useNativeDriver: true,
                    easing: Easing.sin
                }),
                Animated.timing(opacity, {
                    toValue: .4,
                    duration: speed,
                    useNativeDriver: true,
                    easing: Easing.sin
                })
            ])
        ).start()
    }


    useEffect(() => {
        loading()
    }, [])

    return opacity
}

export const MagicBox = ({ data = [] }) => {

    const recursiveRender = (childs) => {
        return (
            childs.map((el, i) =>
                el.type === "Element" ?
                    <Element opacity={LoadAnimation} key={i} {...el} />
                    :
                    el.type === "Paragraph" ?
                        <Paragraph opacity={LoadAnimation} key={i} {...el} />
                        :
                        el.type === "Line" ?
                            <Line opacity={LoadAnimation} key={i}{...el} />
                            :
                            <Block opacity={LoadAnimation} key={i} {...el}>{recursiveRender(el.children)}</Block>
            )
        )
    }

    return recursiveRender(data)
}


const styles = StyleSheet.create({
    base: {
        borderRadius: 7,
        backgroundColor: "gainsboro"
    },
    card: {
        shadowColor: "#000",
        borderRadius: 15,
        backgroundColor: "white",
        shadowOffset: {
            width: 0,
            height: 5
        },
        shadowOpacity: 0.15,
        shadowRadius: 7,
        elevation: 12
    },
})