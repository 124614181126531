

import chroma from "chroma-js"

export const decideColor = (color) => {
    if (chroma(color).luminance() > .5) {
        return "#000000"
    } else {
        return "#ffffff"
    }
}

export const getMediaHeight = (element) => {
    if (Platform.OS === "web") {
        setMediaAspectRatio(element.target.videoWidth / element.target.videoHeight)
    } else {
        setMediaAspectRatio(element.naturalSize.width / element.naturalSize.height)
    }
}