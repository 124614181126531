import { Ionicons } from "@expo/vector-icons";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Alert, Dimensions, Platform,
  StyleSheet, Text, TouchableOpacity, View
} from "react-native";


import { firestore } from "firebase";
import LottieView from "lottie-react-native";
import { TextInput } from "react-native-gesture-handler";
import Button from "../../components/common/Button";
import Modal from "../../components/common/Modal";
import AuthContext from '../../context/AuthContext';
import withLocalStorage from "../../utils/localStore";

let SCREEN_WIDTH = Dimensions.get("window").width;
let SCREEN_HEIGHT = Dimensions.get("window").height;

const CompleteCourse = ({ id, route, navigation, company }) => {

  const containerRef = useRef()

  const advanceID = route.params.id


  const { userData, customization } = useContext(AuthContext)

  const [failed, setFailed] = useState(null)
  const [modal, setModal] = useState(false)
  const [starRating, setStarRating] = useState(0)
  const [comment, setComment] = useState('')
  const [courseID, setCourseID] = useState("")
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(false)


  useEffect(() => {
    setLoading(true)

    firestore().collection("users").doc(userData.id).collection("courseavs").doc(advanceID).get().then(async (querySnapshot) => {
      setData(querySnapshot.data())
      setLoading(false)
      finishCourse(querySnapshot.data())
    })
  }, [])


  const finishCourse = (data) => {

    const newarr = data.advance.map((section, i, arr) => {
      if (section.status !== "COMPLETED") {
        return {
          ...section,
          status: "COMPLETED",
          completedAt: new Date(),
          contents: section.contents.map(e => ({ ...e, status: e.status !== "FAILED" ? "COMPLETED" : e.status }))
        }
      } else {
        return { ...section }
      }
    })


    firestore().collection("users").doc(userData.id).collection("courseavs").doc(advanceID).update({
      advance: newarr,
      state: "COMPLETED",
      completedAt: new Date()
    })
      .catch(err => Alert.alert("Ocurrio un problema finalizando el curso, por favor inténtelo mas tarde."))
  }



  const submitReview = async () => {
    const variables = {
      review: comment,
      user: firestore().collection("users").doc(userData.id),
      course: data.courseRef,
      visible: true,
      value: starRating
    }

    data.courseRef.collection("reviews").add(variables)
      .then(res => {
        setModal(false)
        navigation.replace('SmartHome')
      }
      ).catch(
        err =>
          console.log(err)
      );
  }

  return (
    <View ref={containerRef} style={{ resizeMode: "cover", flex: 1, alignItems: 'center', justifyContent: 'center', paddingHorizontal: 10, backgroundColor: "white" }}>
      {modal && <Modal>

        <Text style={{ fontFamily: "DemiBold", marginBottom: 15, fontSize: 24 }}>Valora el curso</Text>

        <Text style={{ fontFamily: "Regular", color: "gray" }}>Valora del 1 al 5 tu satisfacción con el curso</Text>
        <View style={{ justifyContent: 'center', marginVertical: 10, flexDirection: 'row' }}>
          <TouchableOpacity style={{ height: 40, width: 40, alignItems: 'center', justifyContent: 'center' }} onPress={() => setStarRating(1)}>
            <Ionicons color={starRating >= 1 ? "orange" : "gray"} size={34} name={starRating >= 1 ? "md-star" : "md-star-outline"} />
          </TouchableOpacity>
          <TouchableOpacity style={{ height: 40, width: 40, alignItems: 'center', justifyContent: 'center' }} onPress={() => setStarRating(2)}>
            <Ionicons color={starRating >= 2 ? "orange" : "gray"} size={34} name={starRating >= 2 ? "md-star" : "md-star-outline"} />
          </TouchableOpacity>
          <TouchableOpacity style={{ height: 40, width: 40, alignItems: 'center', justifyContent: 'center' }} onPress={() => setStarRating(3)}>
            <Ionicons color={starRating >= 3 ? "orange" : "gray"} size={34} name={starRating >= 3 ? "md-star" : "md-star-outline"} />
          </TouchableOpacity>
          <TouchableOpacity style={{ height: 40, width: 40, alignItems: 'center', justifyContent: 'center' }} onPress={() => setStarRating(4)}>
            <Ionicons color={starRating >= 4 ? "orange" : "gray"} size={34} name={starRating >= 4 ? "md-star" : "md-star-outline"} />
          </TouchableOpacity>
          <TouchableOpacity style={{ height: 40, width: 40, alignItems: 'center', justifyContent: 'center' }} onPress={() => setStarRating(5)}>
            <Ionicons color={starRating >= 5 ? "orange" : "gray"} size={34} name={starRating >= 5 ? "md-star" : "md-star-outline"} />
          </TouchableOpacity>
        </View>

        <Text style={{ fontFamily: "Regular", color: "gray" }}>Escribe una breve valoración</Text>

        <TextInput
          placeholder={"Tu valoración..."}
          multiline
          onChangeText={ev => setComment(ev)}
          value={comment}
          numberOfLines={4}
          blurOnSubmit={true}
          style={{ alignItems: "flex-start", height: 100, marginVertical: 20, borderRadius: 15, borderWidth: 1, backgroundColor: 'white', borderColor: '#C6C6C6', paddingHorizontal: 15, paddingVertical: 20, fontFamily: 'Regular' }}
        />

        <Button color={customization.mainColor} onPress={() => submitReview()} label="Enviar Valoración" />


        <TouchableOpacity onPress={() => setModal(false)} style={{ height: 50, position: "absolute", top: 0, right: 0, width: 50, alignItems: "center", justifyContent: "center" }}>
          <Ionicons name="ios-close" size={32} />
        </TouchableOpacity>


      </Modal>
      }

      <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
        {/* <Image style={{ width: SCREEN_WIDTH - 20, marginLeft: 10, height: 300, resizeMode: 'cover' }} source={{ uri: "https://cdn.dribbble.com/users/2071065/screenshots/6760845/dribble_5-20_4x.png" }} /> */}
        {Platform.OS !== "web" &&
          <LottieView
            autoPlay
            style={{
              width: 260,
              height: 260,
              marginBottom: 30
            }}
            source={require('../../assets/animations/check.json')}
          />
        }
        <Text style={{ fontFamily: 'DemiBold', fontSize: 32, textAlign: "center", marginTop: 20 }}>¡Enhorabuena!</Text>
        <Text style={{ fontFamily: 'DemiBold', fontSize: 28, textAlign: "center", marginBottom: 20 }}>Has finalizado este Módulo</Text>

        <Button color={customization.mainColor} onPress={() => setModal(true)} label="Valorar Módulo" />


        <TouchableOpacity onPress={() => navigation.replace('SmartHome')}>
          <Text style={{ fontFamily: 'DemiBold', marginTop: 20 }}>Volver al inicio</Text>
        </TouchableOpacity>
      </View>

    </View>
  );
}

export default withLocalStorage(CompleteCourse);

const styles = StyleSheet.create({
  shadow: {
    shadowColor: "rgba(0, 0, 0, 0.3)",
    shadowOffset: { width: 0, height: 5 },
    shadowOpacity: 0.4,
    shadowRadius: 10,
    elevation: 10,
  }
});