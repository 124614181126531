import React, { useState, useEffect, useContext } from "react";
import {
    View,
    Text,
    Image,
    TouchableOpacity,
    TextInput,
    ImageBackground,
    StyleSheet,
    ActivityIndicator,
    Alert,
    Platform
} from "react-native";
import * as Permissions from "expo-permissions";
import * as ImagePicker from "expo-image-picker";
import { Ionicons } from "@expo/vector-icons";
import moment from "moment";
import Collapsible from 'react-native-collapsible';
import { Video } from 'expo-av';
import CamModal from '../common/CamModal'
import { storage, firestore } from 'firebase'
import Modal from '../common/Modal'
import PointsContext from '../../context/PointsContext'
import AuthContext from "../../context/AuthContext";

import * as Localization from 'expo-localization';
import i18n from 'i18n-js';
import translations from '../../locale/translations'
import Button from "../common/Button";

i18n.translations = translations;
// Set the locale once at the beginning of your app.
i18n.locale = Localization.locale;

const validateForm = content => {
    let res = true;
    let restxt = "";
    if (content.content === "") {
        res = false;
        restxt = restxt + "- Debe introducir una descripción. \n";
    }
    return { res, restxt };
};



const CommunityEditor = ({ }) => {

    const { userData, customization } = useContext(AuthContext)

    const { givePoints } = useContext(PointsContext)
    const [newContent, setNewContent] = useState(false)
    const [showCamera, setShowCamera] = useState({ status: false, type: "Photo" })
    const [userVideo, setUserVideo] = useState(null)

    const initialState = {
        image: null,
        media: null,
        content: ""
    }
    const [content, setContent] = useState(initialState);

    const [loading, setLoading] = useState(false);


    // const [createContent] = useMutation(mutations.createContent);

    useEffect(() => {
        content.content !== "" && setNewContent(true)
    }, [content])


    const handleUpload = async (file, field) => {

        let result = await fetch(file)
        const blob = await result.blob();
        var storageRef = storage().ref().child(`${userData.tenants[0].id}/community/${field === "media" ? "videos" : "images"}/post-${field}-${userData.id}-${moment().format()}`)
        const data = storageRef.put(blob).then((snapshot) => snapshot.ref.fullPath).catch(err => err);
        return data

    };

    const permissionsCheckAsync = async () => {

        const { status } = await Permissions.getAsync(Permissions.CAMERA_ROLL);
        status !== "granted" && await Permissions.askAsync(Permissions.CAMERA_ROLL)

    };

    const pickImage = async (field, type) => {

        permissionsCheckAsync()

        let result = {};

        result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: type === "All" ? ImagePicker.MediaTypeOptions.All : type === "Video" ? ImagePicker.MediaTypeOptions.Videos : ImagePicker.MediaTypeOptions.Images,
            allowsEditing: true,
            quality: 1,
            allowsMultipleSelection: false
        });

        if (!result.cancelled) {
            if (field === "image") {
                setContent({ ...content, [field]: result.uri, media: null })
            } else {
                setContent({ ...content, [field]: result.uri, image: null })
            }
            setNewContent(true)
        } else {
            // setLoading(false);
        }
    };

    const publishContent = async () => {
        setLoading(true)
        const validate = validateForm(content);

        if (validate.res) {

            const image = content.image ? await handleUpload(content.image, "image") : null
            const media = content.media ? await handleUpload(content.media, "media") : null
            const user = {
                id: firestore().collection("users").doc(userData.id),
                image: userData.image ? userData.image : "",
                name: userData.name,
                surname: userData.surname
            }
            const variables = await {
                content: content.content,
                publishDate: firestore.FieldValue.serverTimestamp(),
                image,
                media,
                user
            }

            await firestore().collection("tenants").doc(userData.tenants[0].id).collection("feed").add(variables).then(res => {

                givePoints({
                    showPoints: true,
                    message: "Conseguiste 100 Puntos al publicar en la Comunidad",
                    points: 100
                })
                setContent(initialState)
                setLoading(false)
            }).catch(err => {
                Alert.alert("Ha ocurrido un error publicando el artículo")
            })

        } else {
            setLoading(false);
            Alert.alert("Error en el formulario.", validate.restxt);
        }
    }



    return (
        <View style={{ width: "100%" }}>

            {showCamera.status &&
                <Modal>
                    <CamModal
                        maxDuration={30}
                        AV={showCamera.type}
                        initialCamera={"back"}
                        closeCallback={() => setShowCamera({ status: false, type: showCamera.type })}
                        response={(ev) => {
                            showCamera.type === "Photo" ?
                                setContent({ ...content, image: ev.uri, media: null })
                                :
                                setContent({ ...content, media: ev.uri, image: null })
                        }}
                    />
                </Modal>
            }


            {/* {newContent && <View style={{ position:"absolute" , top: 0, bottom: 0, left: 0, right: 0, backgroundColor: "rgba(0,0,0,.3)" }} />} */}

            <View
                style={[
                    styles.shadow,
                    {
                        backgroundColor: "white",
                        width: "100%",
                        marginTop: 10,
                        borderRadius: 12
                    }
                ]}
            >
                <View style={{
                    flexDirection: "row",
                    alignItems: "center",
                    borderBottomWidth: 1,
                    paddingLeft: 15,
                    borderBottomColor: "#F5F6F7",
                    backgroundColor: "#F5F6F7",
                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,
                    height: 50,
                    justifyContent: "space-between",
                }}>
                    <Text style={{ fontSize: 16, fontFamily: "DemiBold" }}>{i18n.t('share')}</Text>

                    {newContent &&
                        <TouchableOpacity onPress={() => {
                            setNewContent(false)
                            setContent({
                                image: null,
                                media: null,
                                content: ""
                            })
                        }} style={{ height: 50, width: 50, alignItems: "center", justifyContent: "center" }}>
                            <Ionicons name="ios-close" size={30} />
                        </TouchableOpacity>
                    }
                </View>

                <TextInput
                    value={content.content}
                    multiline
                    scrollEnabled={true}
                    style={{
                        paddingVertical: 10,
                        fontFamily: "Regular",
                        minHeight: 80,
                        maxHeight: 150,
                        marginTop: 10,
                        fontSize: 16,
                        borderBottomWidth: 1,
                        borderBottomColor: "#F5F6F7",
                        paddingHorizontal: 15,
                    }}
                    {...Platform.OS !== "web" && { textAlignVertical: "top" }}
                    onChangeText={value => setContent({ ...content, content: value })}
                    placeholder="Comparte algo nuevo!"
                />

                <Collapsible collapsed={!content.image}>
                    <TouchableOpacity onPress={() => setContent({ ...content, image: null })} style={{ height: 80, width: 80, margin: 10 }}>
                        {content.image && <Image source={{ uri: content.image }} style={{ height: 80, width: 80 }} />}
                        <View style={{ position: "absolute", alignItems: "center", justifyContent: "center", top: -10, right: -10, width: 20, height: 20, borderRadius: 10, borderWidth: 2, borderColor: "white", backgroundColor: "#017676" }}>
                            <Ionicons name="md-close" size={8} color="white" />
                        </View>
                    </TouchableOpacity>
                </Collapsible>

                <Collapsible collapsed={!content.media}>
                    <TouchableOpacity onPress={() => setContent({ ...content, media: null })} style={{ height: 80, width: 80, margin: 10 }}>
                        <Video source={{ uri: content.media }} rate={1.0} isMuted={true} resizeMode="cover" shouldPlay isLooping style={{ height: 80, width: 80 }} />
                        <View style={{ position: "absolute", alignItems: "center", justifyContent: "center", top: -10, right: -10, width: 20, height: 20, borderRadius: 10, borderWidth: 2, borderColor: "white", backgroundColor: "#017676" }}>
                            <Ionicons name="md-close" size={8} color="white" />
                        </View>
                    </TouchableOpacity>
                </Collapsible>

                <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", paddingHorizontal: 10, paddingVertical: 10 }}>
                    <View style={{ flexDirection: "row" }}>

                        {Platform.OS !== "web" &&
                            <TouchableOpacity
                                onPress={() => setShowCamera({ status: true, type: "Photo" })}
                                style={{ backgroundColor: "#F5F6F7", paddingHorizontal: 20, height: 35, flexDirection: "row", alignItems: "center", justifyContent: "center", borderRadius: 18 }}
                            >
                                <Ionicons name="camera" size={20} color="black" />
                                {/* <Text style={{ fontFamily: "Regular", marginLeft: 5 }}>Cámara</Text> */}
                            </TouchableOpacity>
                        }

                        <TouchableOpacity
                            onPress={() => pickImage("image", "image")}
                            style={{ backgroundColor: "#F5F6F7", paddingHorizontal: 20, marginLeft: 5, height: 35, flexDirection: "row", alignItems: "center", justifyContent: "center", borderRadius: 18 }}
                        >
                            <Ionicons name="images" size={20} color="black" />
                            {/* <Text style={{ fontFamily: "Regular", marginLeft: 5 }}>Galería</Text> */}
                        </TouchableOpacity>

                        <TouchableOpacity
                            onPress={() => pickImage("media", "Video")}
                            style={{ backgroundColor: "#F5F6F7", paddingHorizontal: 20, marginLeft: 5, height: 35, flexDirection: "row", alignItems: "center", justifyContent: "center", borderRadius: 18 }}
                        >
                            <Ionicons name="videocam" size={20} color="black" />
                            {/* <Text style={{ fontFamily: "Regular", marginLeft: 5 }}>Cámara</Text> */}
                        </TouchableOpacity>
                    </View>


                    <Button
                        onPress={() => publishContent()}
                        label={i18n.t('publish')}
                        color={customization.mainColor}
                        loading={loading}
                        disabled={content.content === ""}
                        width={100}
                        height={30}
                        fontSize={12}
                        round={15}
                    />
                    {/* <TouchableOpacity
                        style={{ backgroundColor: customization.mainColor, paddingHorizontal: 20, height: 35, flexDirection: "row", alignItems: "center", justifyContent: "center", borderRadius: 18 }}
                    >
                        {loading && <ActivityIndicator color={customization.buttonMainColorText} />}
                        {!loading && <Text style={{ fontFamily: "DemiBold", color: customization.buttonMainColorText, marginLeft: 5 }}>{i18n.t('publish')}</Text>}
                    </TouchableOpacity> */}


                </View>
            </View>
        </View>
    );
}

export default CommunityEditor;


const styles = StyleSheet.create({
    elementTitle: {
        fontSize: 18,
        color: "gray",
        marginBottom: 20
    },
    shadow: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 0
        },
        shadowOpacity: 0.15,
        shadowRadius: 7,

        elevation: 12
    },
    imageCountainer: {
        paddingHorizontal: 10
    },
    featuredImage: {
        borderRadius: 15,
        width: "100%",
        height: 200,
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
        marginBottom: 15
    },
    imageButton: {
        marginHorizontal: 10,
        height: 50,
        width: 50,
        borderRadius: 15,
        backgroundColor: "white",
        alignItems: "center",
        justifyContent: "center"
    },
    overlay: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        borderRadius: 15,
        backgroundColor: "rgba(0,0,0,0.6)"
    },
    contentContainer: {
        paddingHorizontal: 10
    },
    typeSelector: {
        flexDirection: "row",
        justifyContent: "space-around"
    },
    typeButton: {
        paddingHorizontal: 15,
        height: 50,
        borderRadius: 10,
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "center"
    }
});
