import React, { useState, useEffect } from "react";
import {
  View,
  ScrollView,
  Text,
  AsyncStorage,
  Dimensions,
  StatusBar,
  StyleSheet,
  Image,
  SafeAreaView,
  TouchableOpacity,
  Platform,
  TextInput
} from "react-native";

import { Ionicons } from "@expo/vector-icons";
import URL from "../../constants/Servers";
import CardPost from '../../components/CardPost'
import withLocalStorage from "../../utils/localStore";
import HeaderWeb from '../../components/HeaderWeb'
import { useSafeArea } from 'react-native-safe-area-context';
import { firestore } from "firebase";

let SCREEN_WIDTH = Dimensions.get("window").width;
let SCREEN_HEIGHT = Dimensions.get("window").height;


const Search = ({ company, navigation }) => {

  const [search, setSearch] = useState('')
  const [contents, setContents] = useState([])
  const [loading, setLoading] = useState(false)
  const [lastSearch, setLastSearch] = useState()
  const [filtroSearch, setFiltroSearch] = useState()

  
  const getSeach = (text) => {
    setLoading(true)
    firestore().collection("contents").where("title", "array-contains", text).get().then(querySnapshot => {
      setContents(querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })))
      setLoading(false)
    }).catch(err => {
      console.log(err)
      setLoading(false)
    })
  }



  useEffect(() => {
    // recoverLastSearch()
  }, [])

  // const recoverLastSearch = async () => {
  //   await setLastSearch(await AsyncStorage.getItem('lastSearch'))

  //   await setFiltroSearch({ title_contains: await AsyncStorage.getItem('lastSearch'), draft_ne: true, origin: "Noticias" })
  //   await callSearch()
  // }

  // const enterSearch = async (input) => {
  //   setSearch(input)
  //   await AsyncStorage.setItem("lastSearch", input);
  // }

  // const searchQuery = async () => {

  //   if (search !== "") {
  //     await AsyncStorage.setItem("lastSearch", search);
  //     setLastSearch(search)
  //     await setFiltroSearch({ title_contains: search, draft_ne: true, origin: "Noticias" })

  //     await callSearch()
  //   }
  // }

  // const removeLastSearch = async () => {
  //   setLastSearch(null)
  //   await AsyncStorage.removeItem('lastSearch')
  // }

  const insets = useSafeArea()

  return (
    <View style={{ flex: 1, backgroundColor: "white" }}>
      {/* {Platform.OS === "web" && <HeaderWeb />} */}
      <View style={{ paddingTop: insets.top, flexDirection: 'row', paddingBottom: 10, paddingRight: 10, backgroundColor: "#e8e8e8", alignItems: 'center', justifyContent: 'center' }} >
        <TouchableOpacity onPress={() => { navigation.goBack() }} style={{ width: 50, height: 50, alignItems: 'center', justifyContent: 'center' }}>
          <Ionicons size={30} name={"ios-arrow-back"} />
        </TouchableOpacity>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            borderRadius: 10,
            backgroundColor: "white",
            height: 45,
            flex: 1,
            paddingHorizontal: 10
          }}
        >

          <TextInput
            autoCapitalize="none"
            style={{ flex: 1, borderBottomColor: "transparent" }}
            underlineColorAndroid="transparent"
            placeholder="Buscar"
            value={search}
            onChangeText={b => setSearch(b)}
          />
          <TouchableOpacity onPress={() => getSeach(search)} style={{ height: 35, width: 35, alignItems: 'center', justifyContent: 'center' }}>
            <Ionicons
              size={26}
              style={{ paddingHorizontal: 10 }}
              color="grey"
              name="md-search"
            />
          </TouchableOpacity>
        </View>
      </View>

      <ScrollView showsVerticalScrollIndicator={false} contentContainerStyle={{ flexGrow: 1, paddingBottom: 100, paddingHorizontal: 10 }}>
        {lastSearch !== null &&
          <View style={{ marginVertical: 10 }}>
            <Text style={{ fontFamily: 'Regular', fontSize: 20, color: 'gainsboro' }}>Última búsqueda</Text>

            <View style={{ height: 35, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
              <Text style={{ fontFamily: 'Regular' }}>{lastSearch}</Text>

              <TouchableOpacity onPress={() => { removeLastSearch() }} style={{ width: 35, height: 35, alignItems: 'center', justifyContent: 'center' }}>
                <Ionicons size={15} name={"md-close-circle"} />
              </TouchableOpacity>
            </View>
          </View>
        }

        {(loading) ?
          <View style={{ flex: 1, flexGrow: 1, alignItems: "center", justifyContent: "center" }}>
            <Text style={{ fontFamily: "DemiBold", color: "gray", fontSize: 18 }}>Buscando...</Text>
          </View>
          :
          <View>
            {contents.map((post, i) => {
              return (
                <CardPost
                  key={i}
                  position={i}
                  title={post.title}
                  id={post.id}
                  publishDate={post.publishDate}
                  categories={post.categories}
                  clickCallback={() => navigation.navigate("Post", { id: post.id, title: post.title })}
                  image={post.image}
                />
              )
            })
            }
          </View>
        }

      </ScrollView>
    </View>
  );
}


const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center"
  },
  shadow: {
    shadowColor: "rgba(0, 0, 0, 0.3)",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.4,
    shadowRadius: 3
  },
  drawer: {
    shadowColor: "#000000",
    shadowOpacity: 0.8,
    shadowRadius: 3,
    elevation: 10
  },
  main: { paddingLeft: 3 }
});


export default withLocalStorage(Search);

// export default class Search extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       busqueda: "",
//       entradas: [],
//       resultados: []
//     };
//   }

//   async componentDidMount() {
//     fetch(`${URL.URL_API}/contents?_limit=0`, {
//       method: "GET",
//       headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${await AsyncStorage.getItem('jwt')}`
//       }
//     })
//       .then(response => response.json())
//       .then(async entradas => {
//         this.setState({ entradas: entradas.filter(e => !e.borrador) });
//         const default_search = await AsyncStorage.getItem("default_search");
//         default_search && this.handleSearch(default_search);
//       });
//   }

//   handleSearch = async b => {
//     const resultados = this.state.entradas.filter(en => {
//       const arrTitulo = en.title
//         .replace(/\?|\¿|\!|\¡/gm, " ")
//         .split(/[\s,]+/)
//         .filter(e => e !== "");
//       const algunTrue = arrTitulo
//         .map(p => this.urlificacion(p).startsWith(this.urlificacion(b)))
//         .includes(true);
//       if (
//         this.urlificacion(en.title).startsWith(this.urlificacion(b)) ||
//         algunTrue
//       ) {
//         return en;
//       }
//     });
//     this.setState({ resultados: b !== "" ? resultados : [], busqueda: b });
//     await AsyncStorage.setItem("default_search", b);
//   };

//   urlificacion(str) {
//     str = str.replace(/^\s+|\s+$/g, ""); // trim
//     str = str.toLowerCase();

//     // remove accents, swap ñ for n, etc
//     var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
//     var to = "aaaaeeeeiiiioooouuuunc------";
//     for (var i = 0, l = from.length; i < l; i++) {
//       str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
//     }

//     str = str
//       .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
//       .replace(/\s+/g, "") // collapse whitespace and replace by -
//       .replace(/-+/g, ""); // collapse dashes

//     return str;
//   }

//   render() {
//     return (
//       <SafeAreaView style={{ flex: 1, backgroundColor: "white" }}>
//         <View
//           style={{
//             display: "flex",
//             flexDirection: "row",
//             backgroundColor: "gainsboro",
//             alignItems: "center",
//             paddingRight: 10,
//             paddingVertical: 10
//           }}
//         >
//           <View
//             style={{
//               display: "flex",
//               flexDirection: "row",
//               alignItems: "center",
//               flex: 1,
//               borderRadius: 10,
//               backgroundColor: "white",
//               height: 35,
//               padding: 5
//             }}
//           >
//             <Ionicons
//               size={26}
//               style={{ paddingHorizontal: 10 }}
//               color="grey"
//               name="ios-search"
//             />
//             <TextInput
//               style={{ width: "80%", borderBottomColor: "transparent" }}
//               underlineColorAndroid="transparent"
//               placeholder="Buscar"
//               value={this.state.busqueda}
//               onChangeText={b => this.handleSearch(b)}
//             />
//           </View>
//         </View>
//         <ScrollView style={{ flex: 1 }}>
//           <View style={{ justifyContent: "space-between" }}>
//             {this.state.resultados.map((post, i) => {
//               return (
//                 <TouchableHighlight
//                   key={i}
//                   onPress={() =>
//                     this.props.navigation.navigate("Post", { id: post.id })
//                   }
//                   style={[
//                     styles.shadow,
//                     {
//                       height: 85,
//                       marginHorizontal: 10,
//                       marginVertical: 5,
//                       borderRadius: 15,
//                       elevation: 10
//                     }
//                   ]}
//                 >
//                   <View
//                     style={[
//                       {
//                         flex: 1,
//                         flexDirection: "row",
//                         borderRadius: 15,
//                         backgroundColor: "white",
//                         alignContent: "center",
//                         alignItems: "center"
//                       }
//                     ]}
//                   >
//                     <View style={{ flex: 2 }}>
//                       <Image
//                         source={{
//                           uri:
//                             (post.image && post.image.url) ||
//                             "https://training-solvia.s3.eu-west-1.amazonaws.com/c168468a42a64f6caa85440dccbe5c9a.jpg"
//                         }}
//                         style={{
//                           flex: 1,
//                           width: null,
//                           height: null,
//                           borderRadius: 8
//                         }}
//                         resizeMode="cover"
//                       />
//                     </View>
//                     <Text style={{ flex: 5, paddingLeft: 10, fontSize: 14 }}>
//                       {" "}
//                       {post.title}
//                     </Text>
//                   </View>
//                 </TouchableHighlight>
//               );
//             })}
//           </View>
//         </ScrollView>
//       </SafeAreaView>
//     );
//   }
// }


