import React, { useRef, useContext } from "react";
import { TouchableWithoutFeedback, Text, ActivityIndicator } from "react-native";
import * as Animatable from "react-native-animatable";
import chroma from "chroma-js";

import AuthContext from "../../context/AuthContext";

const Button = ({ onPress, round = 25, label = "", disabled = false, reverse = false, primary = false, height = 50, width = "auto", fontSize = 16, color, children, textColor, loading = false, paddingHorizontal = 25, ...rest }) => {
	const { customization } = useContext(AuthContext);
	const buttonRef = useRef();
	const pressIn = { from: { scale: 1 }, to: { scale: 0.95 } };
	const pressOut = { from: { scale: 0.95 }, to: { scale: 1 } };

	const decideColor = () => {
		if (chroma(color || customization.mainColor || "black").luminance() > 0.5) {
			return "#000000";
		} else {
			return "#ffffff";
		}
	};

	return (
		<TouchableWithoutFeedback disabled={disabled} onPressIn={() => buttonRef.current.animate(pressIn)} onPressOut={() => buttonRef.current.animate(pressOut)} onPress={onPress} style={{ cursor: "Pointer" }}>
			<Animatable.View
				{...rest}
				ref={buttonRef}
				style={{
					cursor: "Pointer",
					backgroundColor: color || customization.mainColor || "black",
					opacity: disabled ? 0.3 : 1.0,
					...rest.style,
					height: height,
					width: width,
					paddingHorizontal: paddingHorizontal,
					alignItems: "center",
					justifyContent: "center",
					borderRadius: round,
					flexDirection: reverse ? "row" : "row-reverse",
				}}
				duration={200}>
				{loading ? (
					<ActivityIndicator color={textColor ? textColor : color ? decideColor() : "white"} />
				) : children ? (
					children
				) : (
					<Text
						selectable={false}
						style={{
							fontFamily: "DemiBold",
							fontSize: fontSize,
							textAlign: "center",
							color: textColor ? textColor : color ? decideColor() : "white",
						}}>
						{label}
					</Text>
				)}
			</Animatable.View>
		</TouchableWithoutFeedback>
	);
};

export default Button;
