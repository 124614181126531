// import { Platform } from "react-native";

// let Modal;

// if (Platform.OS !== 'web') {
//     Modal = require('react-native').Modal;
// } else {
//     Modal = require('./WebModal').default;
// }

// export default Modal;


import React from 'react';
import * as Animatable from 'react-native-animatable'
import Portal from '@burstware/react-native-portal'
import { TouchableWithoutFeedback, View } from 'react-native';



const Modal = ({ children, onClickOutside, padding = 15, margin = 10 }) => {
    return (
        <Portal>
            <Animatable.View animation="fadeIn" style={{ zIndex: 200, flex: 1, width: "100%", height: "100%", position: "absolute", top: 0, bottom: 0, right: 0, left: 0, alignItems: "center", justifyContent: "center", backgroundColor: "rgba(0,0,0,.5)" }}>

                <TouchableWithoutFeedback onPress={onClickOutside}>
                    <View style={{ position: "absolute", top: 0, bottom: 0, right: 0, left: 0 }} />
                </TouchableWithoutFeedback>

                <Animatable.View animation="fadeInUp" style={{ padding, margin, borderRadius: 10, backgroundColor: "white" }}>
                    {children}
                </Animatable.View>
            </Animatable.View>
        </Portal >

    );
}

export default Modal;