import React, { useContext } from "react";
import {
  Text,
  View,
  TouchableOpacity,
  StyleSheet,
  Platform
} from "react-native";
import Button from '../../components/common/Button'
import { useNavigation } from '@react-navigation/native';
import { LinearGradient } from "expo-linear-gradient";
import LottieView from "lottie-react-native";
import { Ionicons } from "@expo/vector-icons";
import { ScrollView } from "react-native-gesture-handler";
import AuthContext from "../../context/AuthContext";
import Modal from '../common/Modal'


const EndMission = ({ visible, setVisible, userAnswer, feedback, pointsEarned, missionComplete, advance }) => {


  const { goBack } = useNavigation();
  const { customization } = useContext(AuthContext)

  return (
    visible ?
      <Modal
        onRequestClose={() => setVisible(false)} style={{ flex: 1 }}
      >
            {missionComplete && <Text style={{ fontFamily: "DemiBold", fontSize: 26 }}>!Enhorabuena!</Text>}

            <Text style={{ fontFamily: "DemiBold", fontSize: 18 }}>Has completado esta misión</Text>
            {pointsEarned <= 0 && <Text style={{ textAlign: "center", fontFamily: "Regular", fontSize: 16 }}>Pero no has conseguido puntos, inténta superarte en los próximos desafíos.</Text>}

            {Platform.OS !== "web" &&
              <LottieView
                autoPlay
                loop={true}
                style={{
                  width: 250,
                  height: 250,
                }}
                source={missionComplete ? require('../../assets/animations/gift.json') : require('../../assets/animations/nopoints.json')}
              />
            }
            <View style={{ height: 50, marginBottom: 25, flexDirection: "row", alignItems: "center" }}>
              <Text style={{ fontFamily: "DemiBold", fontSize: 22 }}>{pointsEarned}</Text>
              <LinearGradient
                colors={['#F49D44', '#FED25A']}
                style={{ marginLeft: 10, height: 40, width: 40, borderRadius: 20, backgroundColor: "#FCB84F", alignItems: "center", justifyContent: "center" }}>
                <Ionicons name={"ios-star"} size={30} style={{ color: "white" }} />
              </LinearGradient>
            </View>

            {(feedback !== null && feedback !== undefined) &&
              <ScrollView showsVerticalScrollIndicator={false} style={{ maxHeight: 200, width: "100%", marginBottom: 30 }} contentContainerStyle={{ padding: 10, alignItems: "center", borderRadius: 7, backgroundColor: "#f2f2f2" }}>
                <Text style={{ fontFamily: "DemiBold", fontSize: 12 }}>{feedback}</Text>
              </ScrollView>
            }
            <Button color={customization.mainColor} label="Volver" onPress={() => goBack()} />
            {/* <TouchableOpacity style={{ backgroundColor: customization.buttonMainColor, borderRadius: 25, height: 50, width: 250, alignItems: "center", justifyContent: "center" }} onPress={() => navigate("TrainingHome")}>
            <Text style={{ fontSize: 16, fontFamily: "DemiBold", color: customization.buttonMainColorText }}>{missionComplete ? "Genial" : "Volver"}</Text>
          </TouchableOpacity> */}
      </Modal >
      :
      null
  );
};

export default EndMission;

const styles = StyleSheet.create({
  image: {
    width: 250,
    height: 250,
    marginVertical: 25
  },
  container: {
    alignItems: "center",
    backgroundColor: "white",
    borderRadius: 20,
    justifyContent: "center",
    width: "90%",
    padding: 25,
  }
});
