import React, { useState, useRef, useEffect, useContext } from "react";
import {
    ScrollView,
    StyleSheet,
    View,
    ActivityIndicator,
    RefreshControl,
    Text,
    Modal,
    Platform
} from "react-native";
import ItineraryComponent from "../../components/itineraryComponents/ItineraryComponent";
import WelcomeTraining from "./WelcomeTraining";
import LottieView from "lottie-react-native"

import { firestore, storage } from 'firebase'
import AuthContext from "../../context/AuthContext";

import sizeof from 'firestore-size'

const Itinerary = ({ route, navigation }) => {

    const id = route.params.id

    const { userData, customization } = useContext(AuthContext)
    const [mission, setMission] = useState({});
    const [missionID, setMissionID] = useState(false);
    const [itineraryavID, setitineraryavID] = useState(false);
    const [visible, setVisible] = useState(false);
    const [data, setData] = useState({
        advance:[]
    })
    const [loading, setLoading] = useState(false)

    const scrollRef = useRef()

    // const { loading, error, data, refetch, networkStatus } = useQuery(queries.GetAvitineraries,
    //   {
    //     variables: {
    //       userId: id
    //     }
    //   }
    // );

    useEffect(() => {
        setLoading(true)

        const unsubscribe = firestore().collection("users").doc(userData.id).collection("itineraryavs").doc(id).onSnapshot((doc) => {
            if (doc.exists) {
                Promise.all(doc.data().advance.map(async mission => {
                    const img = mission.image
                    const url = img ? await storage().ref(img).getDownloadURL().then(res => res).catch(err => "") : ""
                    return { ...mission, image: url }
                })).then(res => {
                    setLoading(false)
                    setData({ ...doc.data(), id: doc.id, advance: res, })
                })
            }
        })

        return () => {
            unsubscribe()
        }
    }, [])

    // useEffect(() => {

    //     if (data && data.user) {
    //         const last = data && data.user && data.user.avitineraries.map(av => av.advance.filter(ev => ev.userResponse).length)
    //         const total = Array.isArray(last) ? last.reduce((a, b) => a + b, 0) : 0
    //         scrollRef && scrollRef.current && scrollRef.current.scrollTo({ y: total * 170, animated: true })
    //     }

    // }, [data])

    if (loading)
        return (
            <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
                <ActivityIndicator size="large" />
            </View>
        );

    return (
        <View style={[styles.container]}>
            <WelcomeTraining
                visible={visible}
                setVisible={visible => { setVisible(visible); setMission(null) }}
                mission={mission}
                missionID={missionID}
                itineraryavID={itineraryavID}
            />
            <ScrollView
                contentContainerStyle={[styles.contentContainer, { alignItems: "center" }]}
                ref={scrollRef}
                showsVerticalScrollIndicator={false}
            >

                <ItineraryComponent
                    disabled={false}
                    navigation={navigation}
                    setMission={(mission, index) => {
                        setitineraryavID(data.id)
                        setMission(data.advance[index]);
                        setMissionID(data.advance[index].id);
                        setVisible(true);
                    }}
                    itineraryav={data}
                    i={0}
                />
            </ScrollView>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: "white"
    },
    contentContainer: {
        paddingTop: 0,
        // paddingBottom: 50,
        flexGrow: 1
    }
});

export default Itinerary;
