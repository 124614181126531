import React, { useContext, useRef, useReducer, useEffect, useMemo, useState } from "react";
import * as Notifications from 'expo-notifications'
import { View, AsyncStorage, Alert, Vibration, Dimensions, Platform, Image } from 'react-native'
import Constants from 'expo-constants';
import * as Permissions from 'expo-permissions';


import { NavigationContainer, useLinking, DefaultTheme } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import Login from "../screens/stackLogin/login";
import Lost from "../screens/stackLogin/lost";
import AuthContext from '../context/AuthContext'
import MainTabNavigator from './MainTabNavigator'
import ResetPass from "../screens/stackProfile/ResetPass";
import * as Analytics from 'expo-firebase-analytics';
import moment from 'moment'
import * as Linking from 'expo-linking'


import AuthReducer from '../reducers/AuthReducer'

import firebase from 'firebase/app'
import { useSafeArea } from "react-native-safe-area-context";


const Stack = createStackNavigator();

const prefix = Linking.makeUrl("/");


const config = {
  MainTabNavigator: {
    path: "",
    name: "Home",
    screens: {
      StackContent: {
        path: "",
        screens: {
          ContentHome: {
            path: "",
            screens: {
              NewsFeed: "noticias",
              CommunityFeed: "social"
            }
          },
          Category: {
            path: "categoria/:id"
          },
          Post: {
            path: "post/:id",
          },
          Comments: {
            path: "comentarios/:feed/post/:id"
          }
        }
      },
      StackSmart: {
        path: 'smart',
        screens: {
          SmartHome: "cursos",
          CourseActive: {
            path: "curso/:id/"
          },
          PostSmart: {
            path: "curso/:advanceID/post/:id/"
          },
          CompleteCourse: {
            path: "curso/:id/completado/"
          },
          Exam: {
            path: "curso/:advanceID/exam/:id/"
          }
        }
      },
      StackSmartNew: {
        path: 'smartnew',
        screens: {
          SmartHome: "cursos",
          ActivityGroup: "/:id/actividad/:index/",
          CourseActive: {
            path: "curso/:id/"
          },
          PostSmart: {
            path: "curso/:advanceID/post/:id/"
          },
          CompleteCourse: {
            path: "curso/:id/completado/"
          },
          Exam: {
            path: "curso/:id/exam/:index/"
          }
        }
      },
      StackItinerary: {
        path: 'itinerarios',
        screens: {
          TrainingHome: {
            path: "itinerarios"
          },
          Itinerary: {
            path: "itinerarios/:id"
          }
        }
      },
      StackForum: {
        path: 'foros',
        screens: {
          ForumHome: "foros",
          SingleForum: "foro/:id",
          SingleTopic: "tema/:id",
          CreateTopicPage: "foro/:id/nuevohilo"
        }
      },
      StackProfile: {
        path: 'miperfil',
        screens: {
          ProfileHome: "",
          EditProfile: "/editar"
        }
      },
      // StackRanking:{
      //   path:"",
      //   screens: {
      //     SocialAppraisal:"rankings"
      //   }
      // }
    },
  },
  Login: 'login',
  ResetPass: 'reset',
  Lost: 'lost'
};

const initialWidth = Dimensions.get('window').width
const initialHeight = Dimensions.get('window').height

Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: false,
  }),
});

const AppNavigator = ({ navigation }) => {

  // auth().tenantId = 'Brain-bt699'

  const routeNameRef = useRef();
  const navigationRef = useRef();

  const initialState = {
    isLoading: true,
    authed: false,
    resetPass: false,
  }

  const [initRoute, setInitRoute] = useState()
  const [isReady, setIsReady] = useState(false)
  const [logging, setLogging] = useState(false)
  const [error, setError] = useState(false)
  const [state, dispatch] = useReducer(AuthReducer, initialState);
  const [userData, setUserData] = useState({})

  const [expoPushToken, setExpoPushToken] = useState("")
  const [notification, setNotification] = useState({})
  const [dimensions, setDimensions] = useState({
    width: initialWidth,
    height: initialHeight
  })
  const [customization, setCustomization] = useState({
    logo: "",
    placeholder: "https://avirato.com/wp-content/uploads/2020/02/placeholder.png",
    mainColor: "black",
    secondaryColor: "red",
    headerButtonColor: "white",
    achievements: []
  })

  const { getInitialState } = useLinking(navigationRef, {
    prefixes: [prefix],
    config
  });

  useEffect(() => {
    bootstrapAsync();
    //Esta funcion se lanzará cuando se interactue con la notificación
    Notifications.addNotificationResponseReceivedListener(handleNotificationResponse);
  }, []);

  useEffect(() => {
    if (userData?.id) {
      Platform.OS !== "web" && registerForPushNotificationsAsync()
    }
  }, [userData])

  const handleNotificationResponse = response => {
    if (response?.notification?.request?.content?.data) {
      const { screen, params } = response?.notification?.request?.content?.data;
      navigationRef.current.navigate(screen, {
        ...params
      })
    }
  }

  const registerForPushNotificationsAsync = async () => {
    if (Constants.isDevice) {
      const { status: existingStatus } = await Permissions.getAsync(Permissions.NOTIFICATIONS);
      console.log({ existingStatus })
      let finalStatus = existingStatus;
      if (existingStatus !== 'granted') {
        const { status } = await Permissions.askAsync(Permissions.NOTIFICATIONS);
        finalStatus = status;
      }
      if (finalStatus !== 'granted') {
        alert('Failed to get push token for push notification!');
        return;
      }
      const token = await Notifications.getExpoPushTokenAsync();
      // firestore().collection()
      // console.log({ id: userData?.id, token })
      firebase.firestore().collection('users').doc(userData?.id).update({ token: true, exponentPushToken: token.data })
    } else {
      alert('Must use physical device for Push Notifications');
    }

    if (Platform.OS === 'android') {
      Notifications.createChannelAndroidAsync('default', {
        name: 'default',
        sound: true,
        priority: 'max',
        vibrate: [0, 250, 250, 250],
      });
    }
  };


  const handleNotification123 = notification => {
    Vibration.vibrate();
    console.log(notification.origin);
    setNotification(notification)
    if (notification.origin === "selected") {
      console.log("ASDASDASDASD")
      const { screen, params } = notification.data;
      console.log({ screen, params })
      if (screen) {
        if (screen === "Conversation") {
          navigationRef.current.navigate('Forum', {
            screen: screen,
            params: params
          })
        } else if (screen === "CourseActive") {
          // console.log(screen)
          navigationRef.current.navigate('Learning', {
            screen: screen,
            params: params
          })
        } else {
          navigationRef.current.navigate(screen, {
            ...params
          })
        }
      }
    }
  };

  const changeFavicon = (customization) => {

    document.head || (document.head = document.getElementsByTagName('head')[0]);

    var link = document.createElement('link'),
      oldLink = document.getElementById('dynamic-favicon');

    link.id = 'dynamic-favicon';
    link.rel = 'shortcut icon';
    link.href = customization.logo;

    document.title = "Uno.Work"

    if (oldLink) {
      document.head.removeChild(oldLink);
    }
    document.head.appendChild(link);
  }

  const bootstrapAsync = async () => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        await firebase.firestore().collection("users").doc(user.uid).get().then(async (doc) => {
          if (doc.exists) {
            const tenant = doc.data().tenants[0]
            const data = { usergroups: [""], ...doc.data(), id: doc.id, ref: doc.ref}
            // const customization = doc.data().customization
            tenant.onSnapshot(doc => {
              // const achievements = doc.data().customization.achievements;
              if (doc.data().customization.logo) {
                Image.getSize(doc.data().customization.logo, (width, height) => setCustomization({ ...doc.data().customization, logoRatio: width / height, logoSize: { width, height }, ref: doc.ref, id: doc.id}))
                // Platform.OS === "web" && changeFavicon(doc.data().customization.logo)
              } else {
                setCustomization({ ...doc.data().customization, ref: doc.ref, id: doc.id})
              }
            })
            // Platform.OS !== "web" && registerForPushNotificationsAsync()
            // const notificationSubscription = Notifications.addListener(handleNotification);
            setUserData(data)
          } else {
            Alert.alert("No se ha encontrado ningún usuario con estas credenciales")
          }
        })
        .then(() => dispatch({ type: 'RESTORE_TOKEN', authed: true, resetPass: false }))
        .catch(err => console.log(err))
      } else {
        // No user is signed in.
        dispatch({ type: 'RESTORE_TOKEN', resetPass: false });
      }
    })
  };

  const getMeasurements = () => {
    setDimensions({
      width: Dimensions.get('window').width,
      height: Dimensions.get('window').height,
    })
  }

  const authFunctions = useMemo(() => ({

    signIn: async form => {

      const email = form.identifier.trim().toLocaleLowerCase()
      const password = form.password.trim()
      setLogging(true)
      firebase.auth().signInWithEmailAndPassword(email, password)
        .then(async re => {

          await Analytics.setUserId(re.user.uid);
          setLogging(false)
          firebase.firestore().collection("users").doc(re.user.uid).get().then((doc) => {
            if (doc.exists) {
              const tenant = doc.data().tenants[0]
              const data = { usergroups: [""], ...doc.data(), id: doc.id, ref: doc.ref }
              // const customization = doc.data().customization
              tenant.onSnapshot(doc => {
                // const achievements = doc.data().customization.achievements
                // console.log(achievements);
                if (doc.data().customization.logo) {
                  Image.getSize(doc.data().customization.logo, (width, height) => setCustomization({ ...doc.data().customization, logoRatio: width / height, logoSize: { width, height }, ref: doc.ref, id: doc.id}))
                  Platform.OS === "web" && changeFavicon(doc.data().customization.logo)
                } else {
                  setCustomization({ ...doc.data().customization, ref: doc.ref, id: doc.id});
                }
              })
              // Our Analytics, type: LOGIN
              firebase.firestore().collection('tenants').doc(tenant.id).collection('analytics').add({
                type: 'LOGIN',
                userid: data?.id,
                username: data?.name,
                usersurname: data?.surname,
                userimage: data?.image,
                useremail: data?.email,
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                page: 'LOGIN',
                pageId: null,
                usergroups: data?.usergroups
              })
              // Platform.OS !== "web" && registerForPushNotificationsAsync()
              // const notificationSubscription = Notifications.addListener(handleNotification);
              setUserData(data)           
            } else {
              Alert.alert("No se ha encontrado ningún usuario con estas credenciales")
            }
          })
        })
        .then(() => dispatch({ type: 'RESTORE_TOKEN', authed: true, resetPass: false }))
        .catch((err) => {
          setLogging(false)
          setError(err)
        });
    },

    signOut: async data => {
      firebase.auth().signOut().then(async () => {
        dispatch({ type: 'SIGN_OUT' })
      }).catch((error) => {
        Alert.alert(error.message)
      });

    },

    changeConfirmed: async data => {
      dispatch({ type: 'CHANGE_CONFIRMED', confirmed: data })
    }
    
  }), []);


  useEffect(() => {
    Platform.OS === "web" && getInitialState()
      .catch(() => { })
      .then(state => {
        if (state !== undefined) {
          setInitRoute(state);
        }
        setIsReady(true);
      });
  }, [state])

  // Gets the current screen from navigation state
  const getActiveRouteName = state => {
    const route = state.routes[state.index];
    if (route.state) {
      // Dive into nested navigators
      return getActiveRouteName(route.state);
    }

    return {
      name: route.name,
      id: route.params ? route.params.id : null,
      ...route
    };
  };


  const customAnalytics = async (currentScreen) => {
    const user = await AsyncStorage.getItem('user');
    if (user) {
      const variables = {
        pageName: currentScreen.name,
        pageId: currentScreen.id,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      }
      firebase.firestore().collection("users").doc(JSON.parse(user).id).collection("activity").doc(`${moment().valueOf()}`).set(variables)
        .then(res => { })
        .catch(err => { })
    }
  }

  const theme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      primary: 'white',
      background: "ghostwhite",
      card: 'rgb(255, 255, 255)',
      text: 'rgb(28, 28, 30)',
      border: 'rgb(199, 199, 204)',
    },
  };

  if (state.isLoading) {
    return null
  }
  return (
    <NavigationContainer
      theme={theme}
      ref={navigationRef}
      initialState={initRoute}
      onStateChange={state => {
        const previousRouteName = routeNameRef.current;
        const currentRouteName = getActiveRouteName(state);
        if (previousRouteName !== currentRouteName.name) {
          // customAnalytics(currentRouteName)
          Analytics.setCurrentScreen(currentRouteName.name, currentRouteName.name);
        }
        routeNameRef.current = currentRouteName.name;
      }}
    >
      <View style={{ flex: 1 }} onLayout={getMeasurements}>
        <AuthContext.Provider value={{ customization, authFunctions, userData, logging, error, dimensions}}>
          <Stack.Navigator headerMode="none" mode="modal">
            {!state.authed && <Stack.Screen name="Login" options={{ animationTypeForReplace: state.authed ? 'pop' : 'push' }} component={Login} />}
            {!state.authed && <Stack.Screen name="Lost" component={Lost} />}
            {state.authed && <Stack.Screen  name="MainTabNavigator" component={MainTabNavigator} />}
            {/* <Stack.Screen name="ResetPass" component={ResetPass} /> */}
          </Stack.Navigator>
        </AuthContext.Provider>
      </View>
    </NavigationContainer>
  );
}

export default AppNavigator;

const SplashScreen = () => {
  return (
    <View style={{ flex: 1, backgroundColor: "black" }} />
    // <Image style={{ flex: 1, width: "100%", resizeMode: "cover" }} source={require('../assets/images/splash.png')} />

  )
};
