import React, { useContext } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  ActivityIndicator
} from "react-native";
import { useNavigation } from '@react-navigation/native';
import { useSafeArea } from 'react-native-safe-area-context';
import { LinearGradient } from "expo-linear-gradient";

import CardFeaturedPost from "../CardFeaturedPost";
import AuthContext from "../../context/AuthContext";
import { decideColor } from "../../utils/functions"

const PostMission = ({ data, userResponse, completed, loader, responseCallback }) => {


  const { customization } = useContext(AuthContext)
  const { navigate } = useNavigation();
  const insets = useSafeArea();


  return (
    <View>

      <View style={{ backgroundColor: customization.mainColor, paddingTop: insets.top + 120, paddingHorizontal: 15, paddingBottom: 120 }} >

        <Text style={{ fontFamily: "DemiBold", fontSize: 22, color: decideColor(customization.mainColor), textAlign: "center", marginBottom: 10 }}>
          Visualiza este contenido para completar la misión
          </Text>

        <Text style={{ fontFamily: "Regular", fontSize: 14, color: decideColor(customization.mainColor), textAlign: "center", marginTop: 20 }}>
          Haz click en la caja del contenido para ir al mismo, una vez lo hayas completado haz click en el botón superior para completar la misión.
          </Text>

      </View>


      <View style={{ alignItems: "center", marginTop: -80 }}>
        <CardFeaturedPost
          title={data.content.title}
          idpost={data.content.id}
          publishDate={data.content.publishDate}
          image={data.content.image}
          id={data.content.id}
          comments={[]}
          likes={[]}
          clickCallback={() => {
            navigate("Post", { id: data.content.id, title: data.content.title })
          }}
          cardStyle={{ width: "90%" }}
          navigate={navigate}
        />
      </View>

      {!userResponse &&
        <View style={{ position: "absolute", top: 0, right: -20, marginBottom: insets.bottom + 10, marginTop: insets.top + 15 }}>
          <TouchableOpacity
            onPress={() =>
              userResponse ? navigate("TrainingHome") : responseCallback()
            }
            style={{ backgroundColor: "white", height: 50, paddingHorizontal: 35, borderRadius: 25, alignItems: "center", justifyContent: "center" }}>
            {loader ?
              <ActivityIndicator />
              :
              <Text style={{
                fontFamily: "DemiBold",
                fontSize: 16,
                color: "black"
              }}>Completar Misión</Text>
            }
          </TouchableOpacity>
        </View>}
    </View>
  );
};

export default PostMission;

const styles = StyleSheet.create({
  shadow: {
    shadowColor: "rgba(0, 0, 0, 0.3)",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.4,
    shadowRadius: 3,
    elevation: 10
  }
});