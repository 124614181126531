import React, { useState, useEffect } from 'react';
import { Text, View, Animated } from 'react-native'
import Svg, { Circle, Rect, Defs, Stop, LinearGradient } from 'react-native-svg'

const CircularIndicator = ({ r = 50, strokeWidth = 15, transitionSpeed = 1000, percentage = 0, strokeColor, backgroundColor, insideComponent, style = {} }) => {

    const [value] = useState(new Animated.Value(0))
    let length = 2 * Math.PI * r;

    useEffect(() => {
        Animated.timing(value, {
            toValue: length * (-percentage / 100),
            duration: transitionSpeed,
        }).start()
    }, [percentage])

    const animatedpercentage = value.interpolate({
        inputRange: [0, 100],
        outputRange: [0, 100],
    })

    let AnimatedCircle = Animated.createAnimatedComponent(Circle);


    return (
        <Svg style={{ ...style }} height={(r * 2) + (strokeWidth)} width={(r * 2) + (strokeWidth)}>
            <Defs>
                <LinearGradient id="red" x1="0%" y1="0%" x2="0%" y2="100%">
                    <Stop offset="0%" stopColor="#F5515F" />
                    <Stop offset="100%" stopColor="#9F041B" />
                </LinearGradient>
                <LinearGradient id="orange" x1="0%" y1="0%" x2="0%" y2="100%">
                    <Stop offset="0%" stopColor="#FAD961" />
                    <Stop offset="100%" stopColor="#F76B1C" />
                </LinearGradient>
                <LinearGradient id="green" x1="0%" y1="0%" x2="0%" y2="100%">
                    <Stop offset="0%" stopColor="#B4EC51" />
                    <Stop offset="100%" stopColor="#429321" />
                </LinearGradient>
            </Defs>
            <AnimatedCircle
                strokeLinecap="round"
                className="circle"
                opacity={1}
                cx={(r) + (strokeWidth / 2)}
                cy={(r) + (strokeWidth / 2)}
                r={r}
                stroke={strokeColor ? strokeColor : `url(#${percentage <= 33 ? 'red' : percentage <= 66 ? 'orange' : 'green'})`}
                strokeWidth={strokeWidth}
                fillOpacity="0"
            />
            <AnimatedCircle
                strokeDashoffset={animatedpercentage}
                strokeDasharray={`${length} ${length}`}
                strokeLinecap="round"
                className="circle"
                cx={(r) + (strokeWidth / 2)}
                cy={(r) + (strokeWidth / 2)}
                r={r}
                stroke={backgroundColor ? backgroundColor : `url(#${percentage <= 33 ? 'red' : percentage <= 66 ? 'orange' : 'green'})`}
                strokeWidth={strokeWidth}
                fillOpacity="0"
            />

            {/* <foreignObject width="100%" height={(r * 2) + (strokeWidth)}>

                <View style={{ display: 'flex', height: "100%", alignItems: 'center', justifyContent: 'center' }}>
                    {percentage === undefined ? <Text style={{ fontSize: 20, fontWeight: 600, color: 'gainsboro' }}>Sin Datos</Text> : <Text style={{ fontSize: 50, fontWeight: 800 }}>{percentage}%</Text>}
                </View>
            </foreignObject> */}

        </Svg>
    );
}

export default CircularIndicator;