import { Platform } from 'react-native'
import React from 'react'
import Header from '../components/AppHeader'
import HeaderWeb from '../components/HeaderWeb'

import SmartHome from '../screens/stackSmart/SmartHome'
import Course from '../screens/stackSmart/Course'
import CourseActive from '../screens/stackSmart/CourseActive'
import CompleteCourse from '../screens/stackSmart/CompleteCourse'
import MyCourses from '../screens/stackSmart/MyCourses'
import PostSmart from "../screens/stackSmart/PostSmart";
import Exam from "../screens/stackSmart/Exam";


import { createStackNavigator } from '@react-navigation/stack';
const Stack = createStackNavigator();

const StackSmart = ({ index }) => {
    return (
        <Stack.Navigator
            headerMode="screen"
            screenOptions={{
                title: "Cursos",
                cardStyle: { flex: 1 },
                header: ({ scene, previous, navigation }) => {
                    // console.log("scene", previous)
                    return (
                        Platform.OS === "web" ?
                            <HeaderWeb index={index} />
                            :
                            <Header
                                logo={previous ? false : true}
                                backCallback={() => navigation.goBack()}
                                backButton={previous ? true : false}
                            />
                    )
                }
            }}
        >
            <Stack.Screen name="SmartHome" component={SmartHome} />
            {/* <Stack.Screen name="Course" component={Course}/> */}
            <Stack.Screen name="CourseActive" component={CourseActive}
                options={{
                    headerTransparent: true,
                    header: ({ scene, previous, navigation }) => {
                        // console.log("scene", previous)
                        return (
                            Platform.OS === "web" ?
                                <HeaderWeb navigation={navigation} scene={scene} />
                                :
                                <Header
                                    backCallback={() => navigation.navigate("SmartHome")}
                                    backButton={true}
                                    minimal
                                />
                        )
                    }
                }}
            />
            <Stack.Screen name="CompleteCourse" component={CompleteCourse} options={{ header: () => null }} />
            {/* <Stack.Screen name="MyCourses" component={MyCourses} /> */}
            <Stack.Screen name="PostSmart" component={PostSmart} initialParams={{ courseID: "", activecontent: "", arrayContents: [] }} />
            <Stack.Screen name="Exam" component={Exam}
                options={{
                    headerTransparent: true,
                    header: ({ scene, previous, navigation }) =>
                            <Header backButton={true} backCallback={() => navigation.goBack()} minimal />
                }}
            />
        </Stack.Navigator>
    );
}

export default StackSmart;