import React, { useState, useEffect, useContext } from "react";
import { View, Text, Platform, Image, StyleSheet, Dimensions, TouchableOpacity, TouchableNativeFeedbackComponent } from "react-native";

// import FeaturedCourseCard from '../../components/FeaturedCourseCard'
// import CardCourseStandard from '../../components/CardCourseStandard'
// import MyActiveCourses from '../../components/MyActiveCourses'

import LottieView from "lottie-react-native";

import { ScrollView } from "react-native-gesture-handler";

import firebase from "firebase/app";
import AuthContext from "../../context/AuthContext";

import * as Localization from "expo-localization";
import i18n from "i18n-js";
import translations from "../../locale/translations";
import { Block, Element, Paragraph, Line } from "../../components/placeholders/MagicBox";
import { Ionicons } from "@expo/vector-icons";

import PathContainer from "../../components/itineraryComponentsNew/PathContainer";
import HTML from "react-native-render-html";
import PointsContext from "../../context/PointsContext";
import moment from "moment";

i18n.translations = translations;
i18n.locale = Localization.locale;
i18n.fallbacks = true;

const screenWidth = Dimensions.get("window").width;

const SmartHome = ({ id, company, navigation }) => {
	const { userData } = useContext(AuthContext);
	const { dispatch } = useContext(PointsContext);

	const [loading, setLoading] = useState(true);
	const [data, setData] = useState([]);
	const [ranking, setRanking] = useState([]);
	const [number, setNumber] = useState(1);
	const [error, setError] = useState(false);

	useEffect(() => {
		setLoading(true);
		const unsubscribe = firebase
			.firestore()
			.collection("users")
			.doc(userData.id)
			.collection("courseavs")
			.where("visible", "==", true)
			.orderBy("initDate", "asc")
			.limit(1)
			.onSnapshot((querySnapshot) => {
				if (querySnapshot.docs.length > 0) {
					const updated = querySnapshot.docs[0]?.data()?.advance;
					const advRef = querySnapshot.docs[0]?.ref;
					const courseRef = querySnapshot.docs[0]?.data()?.courseRef;

					dispatch({ type: "ADVANCE", advance: updated, advanceRef: advRef, courseRef });

					setNumber(querySnapshot.docs.length);

					if (courseRef) {
						getRanking(courseRef);
						getCourseData(courseRef, advRef, updated);
					} else {
						setError(true);
					}
				} else {
					setError(true);
					setLoading(false);
				}
			});

		return () => {
			unsubscribe();
		};
	}, []);

	const getRanking = async (courseRef) => {
		courseRef
			.collection("courseavs")
			.orderBy("points", "desc")
			.limit(5)
			.get()
			.then((querySnapshot) => {
				// console.log(querySnapshot.docs.map(el => el.data()))
				setRanking(querySnapshot.docs.map((el) => el.data()));
			});
	};

	const getCourseData = async (courseRef, advRef, updated) => {
		courseRef.get().then(async (querySnapshot) => {
			console.log(querySnapshot.data());
			const d = await Promise.all(
				querySnapshot.data().contentDetails.map(async (section) => {
					const img = section.image;
					const url = img
						? await firebase
								.storage()
								.ref(img)
								.getDownloadURL()
								.then((res) => res)
								.catch((err) => "")
						: null;
					return { ...section, image: url };
				})
			).then((res) => res);

			let forum, relatedContent;

			if (querySnapshot.data().forumRef) {
				forum = await getForum(querySnapshot.data().forumRef);
			}

			if (querySnapshot.data().aditionalMaterials && querySnapshot.data().aditionalMaterials[0]) {
				relatedContent = await getRelatedContent(querySnapshot.data().aditionalMaterials[0]);
			}

			const variables = await { ...querySnapshot.data(), id: querySnapshot.id, ref: querySnapshot.ref, forum, relatedContent, contentDetails: d, advanceID: advRef, userAdvance: updated };
			console.log("ALGOOOOOO", variables);
			setData(variables);
			setLoading(false);
		});
	};

	const getForum = async (forumRef) => {
		const forum = await forumRef
			.collection("topics")
			.limit(1)
			.get()
			.then(async (querySnapshot) => {
				if (querySnapshot.docs[0]) {
					const img = querySnapshot.docs[0].data().image;
					const url = img
						? await firebase
								.storage()
								.ref(img)
								.getDownloadURL()
								.then((res) => res)
								.catch((err) => "")
						: null;
					const userimg = querySnapshot.docs[0].data().user?.image;
					const userurl = userimg
						? await firebase
								.storage()
								.ref(userimg)
								.getDownloadURL()
								.then((res) => res)
								.catch((err) => "")
						: null;
					return { ...querySnapshot.docs[0].data(), id: querySnapshot.docs[0].id, image: url, user: { ...querySnapshot.docs[0].data().user, image: userurl } };
				}
			})
			.catch((err) => console.error(err, "No forum found"));
		return forum;
	};

	const getRelatedContent = async (contentRef) => {
		const content = await contentRef
			.get()
			.then(async (doc) => {
				console.log(doc.data());
				if (doc.exists) {
					const img = doc.data().image;
					const url = img
						? await firebase
								.storage()
								.ref(img)
								.getDownloadURL()
								.then((res) => res)
								.catch((err) => "")
						: null;
					return { ...doc.data(), id: doc.id, image: url };
				}
			})
			.catch((err) => console.log(err));
		return content;
	};

	if (loading) {
		return (
			<View style={{ flexGrow: 1, paddingHorizontal: 10, paddingBottom: 20, width: "100%", maxWidth: 800, alignSelf: "center" }}>
				<Text style={{ textAlign: Platform.OS === "web" ? "left" : null, marginHorizontal: 10, marginTop: 20, marginBottom: 10, fontFamily: "Bold", fontSize: 34 }}>{i18n.t("mycourses")}</Text>
				{Array.from(Array(number).keys()).map((el, i) => (
					<Block key={i} card width="100%" margin={10}>
						<Line margin={10} />
						<Block width="100%" direction="row">
							<Element height={120} width={120} />
							<Block margin={10} justify="space-between" flex={1}>
								<Line extraStyles={{ width: 150, alignSelf: "flex-end" }} />
								<Line fontSize={22} extraStyles={{ width: 150 }} />
							</Block>
						</Block>
					</Block>
				))}
			</View>
		);
	}
	return data.length === 0 ? (
		<View style={{ flexGrow: 1, alignItems: "center", justifyContent: "center" }}>
			{Platform.OS !== "web" && (
				<LottieView
					autoPlay
					loop={false}
					style={{
						width: 200,
						height: 350,
						marginBottom: 0,
					}}
					source={require("../../assets/animations/courses.json")}
				/>
			)}
			<Text style={{ color: "darkgrey", fontSize: 20, textAlign: "center", marginHorizontal: 20, fontFamily: "DemiBold" }}>{i18n.t("nocourses")}</Text>
		</View>
	) : (
		<ScrollView style={{ flex: 1 }} contentContainerStyle={{ maxWidth: 1200, width: "100%", alignSelf: "center" }}>
			<View style={{ flexDirection: "row" }}>
				<View style={{ width: "60%", paddingTop: 50 }}>
					{/* <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between", paddingHorizontal: 15 }}>
						<Text style={{ marginTop: 20, marginBottom: 10, fontFamily: "Bold", fontSize: 24 }}>My Learning Path</Text>
						<TouchableOpacity style={{ alignItems: "center", flexDirection: "row" }}>
							<Text style={{ fontFamily: "Bold", marginRight: 5 }}>Activos</Text>
							<Ionicons style={{ marginTop: 5 }} name="caret-down" size={15} />
						</TouchableOpacity>
					</View>
					{data.map((item, index) => (
						<CardActiveCourseNew key={index} name={item.name} description={item.description} image={item.image} data={item.advance} initDate={item.initDate ? item.initDate.toDate() : false} clickCallback={() => navigation.replace("CourseActive", { id: item.id })} />
					))} */}
					<PathContainer data={data} />
				</View>

				<View style={{ width: "40%", borderLeftColor: "gainsboro", paddingVertical: 10, borderLeftWidth: 1, paddingHorizontal: 20 }}>
					<View style={{ flexDirection: "row", alignItems: "center" }}>
						{/* <Image source={{ uri: "https://runrunes.org/wp-content/uploads/2014/06/venezuela.jpg" }} style={{ resizeMode: "cover", width: 50, height: 50, borderRadius: 25 }} /> */}
						<View style={{ flex: 1 }}>
							<Text style={{ marginTop: 20, marginBottom: 10, fontFamily: "Bold", fontSize: 24 }}>👋 Hola de nuevo {userData.name}!</Text>
						</View>
					</View>

					<View style={[styles.shadow, { flexDirection: "row", padding: 15, paddingHorizontal: 20, backgroundColor: "white", borderRadius: 15, alignItems: "center" }]}>
						<View style={{ flex: 1 }}>
							<View style={{ flexDirection: "row" }}>
								<Text style={{ fontSize: 44, fontFamily: "Bold" }}>{data.coursePoints}</Text>
								<View style={{ marginTop: 30, position: "relative" }}>
									<Text style={{ fontSize: 16, fontFamily: "DemiBold" }}> puntos</Text>
								</View>
							</View>
							<Text style={{ fontSize: 18, fontFamily: "Bold", color: "gray" }}>Nivel Avanzado</Text>
						</View>
					</View>

					<View style={{ flexDirection: "row", alignItems: "center" }}>
						{/* <Image source={{ uri: "https://runrunes.org/wp-content/uploads/2014/06/venezuela.jpg" }} style={{ resizeMode: "cover", width: 50, height: 50, borderRadius: 25 }} /> */}
						<View style={{ flex: 1 }}>
							<Text style={{ marginTop: 20, marginBottom: 10, fontFamily: "Bold", fontSize: 24 }}>Ranking de {data.name}</Text>
						</View>
					</View>

					<View style={[styles.shadow, { padding: 15, paddingHorizontal: 20, backgroundColor: "white", borderRadius: 15 }]}>
						{ranking.map((el, i) => {
							return (
								<View key={i} style={{ flexDirection: "row", alignItems: "center" }}>
									<View style={{ width: 40 }}>
										<Text style={{ fontSize: 34, fontFamily: "Bold" }}>{i + 1}</Text>
									</View>
									<View style={{ position: "relative", flex: 1 }}>
										<Text style={{ fontSize: 16, fontFamily: "DemiBold" }}>
											{el.userName.trim()} {el.userSurname}
										</Text>
									</View>
									<View style={{ backgroundColor: "#f2f2f2", alignItems: "flex-end", paddingHorizontal: 10, justifyContent: "center", borderRadius: 7, height: 30 }}>
										<Text style={{ fontSize: 14, fontFamily: "DemiBold" }}>{el.points}</Text>
									</View>
								</View>
							);
						})}
					</View>

					{/* <View style={{ flexDirection: "row", justifyContent: "center", marginTop: 20, backgroundColor: "#F9F9F9", borderRadius: 15, paddingVertical: 15, paddingHorizontal: 10 }}>
						<View style={[{ alignItems: "center", backgroundColor: "white", padding: 15, width: 100, borderRadius: 15, marginHorizontal: 5 }, styles.shadow]}>
							<Text style={{ position: "absolute", top: -10, left: -10, fontSize: 34 }}>🔥</Text>
							<View style={{ height: 60 }}>
								<Text style={{ textAlign: "center", fontFamily: "Bold", fontSize: 50 }}>13</Text>
							</View>
							<Text style={{ textAlign: "center", fontFamily: "Regular", fontSize: 14 }}>Días Activo</Text>
						</View>
						<View style={[{ alignItems: "center", backgroundColor: "white", padding: 15, width: 100, borderRadius: 15, marginHorizontal: 5 }, styles.shadow]}>
							<View style={{ height: 60 }}>
								<Image source={{ uri: "https://cdn.trophystore.co.uk/Img/Dynamic/Product/43514-635772277575268750.jpg?w=1600&h=1200&m=crop" }} style={{ width: 50, height: 50, resizeMode: "contain" }} />
							</View>
							<Text style={{ textAlign: "center", fontFamily: "Regular", fontSize: 14 }}>Última Medalla</Text>
						</View>
					</View> */}

					<View style={{ marginTop: 30 }}>
						<View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
							<Text style={{ marginRight: 10, fontFamily: "Bold", fontSize: 20 }}>Destacado para ti</Text>
							{/* <TouchableOpacity style={{ borderWidth: 3, borderRadius: 6, alignItems: "center", justifyContent: "center", width: 100, height: 35 }}>
								<Text style={{ fontFamily: "Bold" }}>Ver Más</Text>
							</TouchableOpacity> */}
						</View>
						<TouchableOpacity onPress={() => navigation.navigate("StackContent", { screen: "Post", params: { id: data.relatedContent?.id } })}>
							<Image
								style={{ marginTop: 10, height: 150, width: "100%", borderRadius: 7 }}
								source={{ uri: data.relatedContent ? (data.relatedContent?.image ? data.relatedContent.image : "https://images.pexels.com/photos/4069292/pexels-photo-4069292.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260") : "" }}
							/>
							<Text style={{ marginRight: 10, fontFamily: "Bold", fontSize: 18, marginTop: 10 }}>{data.relatedContent?.title}</Text>
							<HTML
								baseFontStyle={{ fontFamily: "Regular", fontSize: 14, color: "#303030" }}
								html={data.relatedContent?.content ? data.relatedContent?.content.slice(0, 150) : "<p></p>"}
								imagesMaxWidth={800}
								staticContentMaxWidth={800}
								onLinkPress={async (evt, href) => await WebBrowser.openBrowserAsync(href.toLowerCase().includes("://") ? href : `https://${href}`)}
							/>
							<View style={{ marginTop: 10, flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
								<View style={{ flexDirection: "row" }}>
									<View style={{ flexDirection: "row", alignItems: "center", marginRight: 15 }}>
										<Ionicons color="red" name="heart" size={25} />
										<Text style={{ marginLeft: 5, fontFamily: "Bold", color: "grey", fontSize: 14 }}>{data.relatedContent?.likesCount}</Text>
									</View>
									<View style={{ flexDirection: "row", alignItems: "center" }}>
										<Ionicons name="chatbubble-ellipses-outline" size={25} />
										<Text style={{ marginLeft: 5, fontFamily: "Bold", color: "grey", fontSize: 14 }}>{data.relatedContent?.commentsCount}</Text>
									</View>
								</View>
								<Text style={{ fontSize: 14, fontFamily: "Bold" }}>{data.relatedContent.contentLength} min</Text>
							</View>
						</TouchableOpacity>
					</View>

					<View style={{ marginTop: 30 }}>
						<View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
							<Text style={{ marginRight: 10, fontFamily: "Bold", fontSize: 20 }}>Foros del Itinerario</Text>
							<TouchableOpacity onPress={() => navigation.navigate("StackForum", { screen: "SingleForum", params: { id: data.forumRef.id } })} style={{ borderWidth: 3, borderRadius: 6, alignItems: "center", justifyContent: "center", width: 100, height: 35 }}>
								<Text style={{ fontFamily: "Bold" }}>Ver Todos</Text>
							</TouchableOpacity>
						</View>

						<TouchableOpacity
							onPress={() => navigation.navigate("StackForum", { screen: "SingleTopic", params: { id: data.forum.id, forumID: data.forumRef.id } })}
							style={[{ paddingVertical: 10, paddingHorizontal: 15, borderRadius: 10, width: "100%", backgroundColor: "white", marginTop: 20 }, styles.shadow]}>
							<Text style={{ marginRight: 10, fontFamily: "Bold", fontSize: 18, marginTop: 10 }}>{data.forum?.title}</Text>
							<View style={{ marginTop: 15, flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
								<View style={{ flexDirection: "row", alignItems: "center", marginRight: 15 }}>
									<Image source={{ uri: data.forum?.user?.image ? data.forum?.user?.image : "https://runrunes.org/wp-content/uploads/2014/06/venezuela.jpg" }} style={{ resizeMode: "cover", width: 40, height: 40, borderRadius: 20, backgroundColor: "gainsboro" }} />
									<Text style={{ marginLeft: 5, fontFamily: "Bold", color: "grey", fontSize: 14 }}>{data.forum?.user?.name}</Text>
								</View>
								<View style={{ flexDirection: "row", alignItems: "center" }}>
									<View style={{ marginRight: 10, flexDirection: "row", alignItems: "center", marginRight: 15 }}>
										<Ionicons color="grey" name="chatbox-outline" size={25} />
										<Text style={{ marginLeft: 5, fontFamily: "Bold", color: "grey", fontSize: 14 }}>{data.forum?.responses}</Text>
									</View>

									<Text style={{ fontSize: 14, fontFamily: "Bold" }}>Hace {moment(data.forum.lastActive ? data.forum.lastActive.toDate() : "").fromNow("mm:mm")}</Text>
								</View>
							</View>
						</TouchableOpacity>
					</View>
				</View>
			</View>
		</ScrollView>
	);
};

export default SmartHome;

const styles = StyleSheet.create({
	shadow: {
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 5,
		},
		shadowOpacity: 0.1,
		shadowRadius: 6.68,

		elevation: 11,
	},
});
