import React, { useState, useEffect, useContext } from "react";
import {
    View,
    Dimensions,
    ActivityIndicator,
    ScrollView,
    KeyboardAvoidingView
} from "react-native";
import AuthContext from '../../context/AuthContext'


import EditPersonalProfile from "../../components/profileComponents/EditPersonalProfile";
import moment from "moment";
import { useSafeArea } from 'react-native-safe-area-context';
import _ from "lodash"
import Header from "../../components/AppHeader";

import { firestore, storage } from 'firebase'

const EditProfile = ({ navigation }) => {


    const { userData, customization } = useContext(AuthContext)
    const [pass, setPass] = useState({});
    const [loadingMutation, setLoadingMutation] = useState(false);
    const [form, setForm] = useState({});
    const [data, setData] = useState({});
    const [formPass, setFormPass] = useState({})
    const [loading, setLoading] = useState({})
    const [saving, setSaving] = useState(false)
    const [tab, setTab] = useState("Profile")
    const inset = useSafeArea()

    useEffect(() => {
        setLoading(true)
        firestore().collection("users").doc(userData.id).get().then(async (doc) => {
            const img = doc.data().image
            const url = img ? await storage().ref(img).getDownloadURL().then(res => res).catch(err => "") : ""

            setData({ id: doc.id, ...doc.data(), image: url })
            setForm({ id: doc.id, ...doc.data(), image: url })
            setLoading(false)
        })
    }, [])


    useEffect(() => {
        // if (!resetPass) {
        navigation.setOptions({
            header: ({ scene, previous, navigation }) => {
                return (
                    <Header
                        backButton={true}
                        backCallback={() => navigation.goBack()}
                        saveChanges={true}
                        saveDeactivated={!_.isEqual(form, data)}
                        saving={saving}
                        saveChangesCallback={() => editPersonalProfile(form, data)}
                    />
                )
            }

        })
        // }
    }, [form, saving])




    const editPersonalProfile = () => {
        setSaving(true)
        const variables = {
            ...(form.team !== data.team && { team: form.team }),
            ...(form.position !== data.position && { position: form.position }),
            ...(form.bio !== data.bio && { bio: form.bio }),
            ...(form.skills !== data.skills && { skills: form.skills }),
            ...(form.hobbies !== data.hobbies && { hobbies: form.hobbies })
        }
        form.image !== data.image ?
            uploadImage(form.image).then(url => {
                firestore().collection("users").doc(userData.id).update({ ...variables, image: url }).then(res => {
                    setSaving(false)
                    navigation.goBack()
                })
            })
            :
            firestore().collection("users").doc(userData.id).update(variables).then(res => {
                setSaving(false)
                navigation.goBack()
            })


    }

    const uploadImage = async (uri) => {
        let result = await fetch(uri)
        const blob = await result.blob();
        var storageRef = storage().ref().child(`${userData.tenants[0].id}/users/${userData.id}/profilePicture`)
        const data = storageRef.put(blob).then((snapshot) => snapshot.ref.fullPath).catch(err => err);
        return data
    }


    if (loading) {
        return (
            <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
                <ActivityIndicator size="large" />
            </View>
        );
    }
    return (
        <View style={{
            flex: 1,
            backgroundColor: "white"
        }}>
            <KeyboardAvoidingView behavior="position" style={{ flex: 1 }}>
                <ScrollView showsVerticalScrollIndicator={false} contentContainerStyle={{ flexGrow: 1, width:"100%", maxWidth: 600, alignSelf:"center", paddingBottom: inset.bottom }}>
                    <EditPersonalProfile
                        form={form}
                        setForm={ev => setForm(ev)}
                    />
                </ScrollView>
            </KeyboardAvoidingView>
        </View>

    );
};

export default EditProfile;
