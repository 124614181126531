import React, { useState, useRef, useEffect, useContext } from "react";
import { StyleSheet, View, ActivityIndicator, Text, Platform, ImageBackground } from "react-native";

import { firestore, storage } from "firebase";
import AuthContext from "../../context/AuthContext";
import Carousel, { Pagination } from "react-native-snap-carousel";
import { useSafeArea } from "react-native-safe-area-context";
import Button from "../../components/common/Button";
import { ScrollView } from "react-native-gesture-handler";

const entrenamiento = ({ id, navigation }) => {
	const carouselRef = useRef();
	const insets = useSafeArea();
	const { userData, customization, dimensions } = useContext(AuthContext);
	const [index, setIndex] = useState(0);
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);

	const scrollRef = useRef();

	useEffect(() => {
		setLoading(true);

		const unsubscribe = firestore()
			.collection("users")
			.doc(userData.id)
			.collection("itineraryavs")
			.onSnapshot((querySnapshot) => {
				Promise.all(
					querySnapshot.docs.map(async (doc) => {
						return Promise.all(
							doc.data().advance.map(async (mission) => {
								const img = mission.image;
								const url = img
									? await storage()
											.ref(img)
											.getDownloadURL()
											.then((res) => res)
											.catch((err) => "")
									: "";
								return { ...mission, image: url };
							})
						).then((res) => {
							return { ...doc.data(), id: doc.id, advance: res };
						});
					})
				).then((res) => {
					setLoading(false);
					setData(res);
				});
			});

		return () => {
			unsubscribe();
		};
	}, []);

	useEffect(() => {
		if (data && data.user) {
			const last = data && data.user && data.user.avitineraries.map((av) => av.advance.filter((ev) => ev.userResponse).length);
			const total = Array.isArray(last) ? last.reduce((a, b) => a + b, 0) : 0;
			scrollRef && scrollRef.current && scrollRef.current.scrollTo({ y: total * 170, animated: true });
		}
	}, [data]);

	const _renderItem = ({ item, index }) => {
		const completed = item.advance.reduce((acc, it) => acc + (it.userResponse ? 1 : 0), 0);

		return (
			<ImageBackground source={{ uri: item.image }} imageStyle={{ borderRadius: 15 }} style={[styles.shadow, { height: "100%", minWidth: 500, maxWidth: 500, flex: 1, backgroundColor: "white", borderRadius: 15, marginRight: 10 }]}>
				<View style={{ paddingHorizontal: 15, borderTopLeftRadius: 15, borderTopRightRadius: 15, paddingVertical: 10, backgroundColor: item.color || customization.mainColor }}>
					<Text style={{ fontFamily: "DemiBold", textAlign: "center", fontSize: 20, color: "white" }}>{item.name}</Text>
				</View>
				<View style={{ flex: 1, justifyContent: "space-between", alignItems: "center" }}>
					<View style={{ padding: 15, paddingTop: 50, maxWidth: 400 }}>
						<Text style={{ fontFamily: "Regular", textAlign: "center", color: "white", fontSize: 16 }}>{item.description}</Text>
					</View>

					<View style={{ width: "100%", paddingBottom: 10, alignItems: "center" }}>
						<Text style={{ fontSize: 26, color: "white", marginBottom: 20, fontFamily: "DemiBold" }}>
							{completed} de {item.advance.length}
						</Text>
						<Button label="Continuar Desafíos" width={200} color={item.color} onPress={() => navigation.navigate("Itinerary", { id: item.id })} />
					</View>
				</View>
			</ImageBackground>
		);
	};

	if (loading)
		return (
			<View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
				<ActivityIndicator size="large" />
			</View>
		);

	return (
		<View style={{ paddingBottom: 50 + insets.bottom, flex: 1 }}>
			{data.length > 0 ? (
				// <Carousel
				//   ref={carouselRef}
				//   data={data}
				//   renderItem={_renderItem}
				//   onSnapToItem={(index) => setIndex(index)}
				//   containerCustomStyle={{ paddingVertical: 10 }}
				//   sliderWidth={dimensions.width}
				//   itemWidth={dimensions.width * (Platform.OS === "web" ? (dimensions.width < 1000 ? 0.85 : 0.5) : 0.85)}
				//   layoutCardOffset={5}
				//   layout={'default'}
				// />
				<ScrollView contentContainerStyle={{ padding: 20, flexGrow: 1, alignItems: "center", justifyContent:'center' }} horizontal>
					{data.map((el, i) => (
						<_renderItem item={el} key={i} index={i} />
					))}
				</ScrollView>
			) : (
				<View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
					<Text style={{ color: "darkgrey", fontSize: 20, textAlign: "center", marginHorizontal: 20, fontFamily: "DemiBold" }}>No tienes Itinerarios Asignados</Text>
				</View>
			)}
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
	},
	contentContainer: {
		paddingTop: 0,
		flexGrow: 1,
	},
	shadow: {
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 5,
		},
		shadowOpacity: 0.34,
		shadowRadius: 6.27,

		elevation: 10,
	},
});

export default entrenamiento;
