import React, { useState, useEffect, useRef, useContext } from "react";
import { View, Text, StyleSheet, TouchableOpacity, Image, FlatList, Platform } from "react-native";
import * as Animatable from 'react-native-animatable'
import { Ionicons } from '@expo/vector-icons'
import AuthContext from "../../context/AuthContext";
import { decideColor } from "../../utils/functions"


const CourseCollapsableItems = ({ items, toogled, itemCallback, lastCompleted, activecontent, navigation, arrayContents }) => {


    const { customization } = useContext(AuthContext)

    let _scrollView = useRef()
    const itemsContainer = useRef()

    const open = {
        0: { height: 0 },
        1: { height: 220 }
    }
    const close = {
        0: { height: 220 },
        1: { height: 0 }
    }

    const zoomIn = {
        0: { scale: 1, elevation: 0 },
        1: { scale: 1.15, elevation: 20 }
    };

    const scrollToItem = (sectionIndex, contentID) => {
        _scrollView.current && toogled === true && _scrollView.current
            .scrollToOffset({
                offset: (200 * arrayContents.findIndex(el => el.id === contentID)) + (sectionIndex === 0 ? -40 : sectionIndex * 40),
                animated: true
            })
    }
    useEffect(() => {
        itemsContainer.current && toogled === true && itemsContainer.current.animate(open)
        itemsContainer.current && toogled === false && itemsContainer.current.animate(close)
        _scrollView.current && toogled === true && _scrollView.current.scrollToOffset({ offset: 200 * lastCompleted, animated: true })
    }, [toogled])

    useEffect(() => {
        // _scrollView.current && toogled === true && _scrollView.current
        //     // arrayContents.findIndex(el => el.id === activecontent))
        //     .scrollToOffset({
        //         offset: (200 * arrayContents.findIndex(el => el.id === activecontent)),
        //         animated: true
        //     })
    }, [activecontent])


    return (

        <Animatable.View duration={300} easing={"ease-in-out"} ref={itemsContainer} style={{ zIndex: 2, height: 0, backgroundColor: customization.mainColor }}>
            <FlatList
                data={items}
                horizontal
                ref={_scrollView}
                removeClippedSubviews={false}
                getItemLayout={(data, index) => ({ length: items.length, offset: 20 * index, index })}
                keyExtractor={(item, index) => index.toString()}
                ItemSeparatorComponent={() => <View style={{ width: 20 }} />}
                ListFooterComponent={() => <View style={{ width: 20 }} />}
                ListHeaderComponent={() => <View style={{ width: 20 }} />}
                showsHorizontalScrollIndicator={false}
                contentContainerStyle={{ paddingVertical: 15 }}
                renderItem={({ item, index }) => {

                    const sectionIndex = index

                    return (
                        <View style={{ padding: 10, paddingBottom: 0, paddingHorizontal: 20, backgroundColor: 'rgba(255,255,255,.1)', borderRadius: 10 }}>

                            <Text style={{ fontFamily: 'DemiBold', fontSize: 18, color: decideColor(customization.mainColor), marginBottom: 10 }}>{item.name}</Text>

                            <View style={{ flexDirection: 'row', marginTop: 20 }}>
                                {item.contents.map((content, i) => {
                                    return (
                                        <Animatable.View
                                            key={i}
                                            duration={300}
                                            animation={content.id === activecontent ? zoomIn : null}
                                            style={[styles.shadow, { elevation: 2, zIndex: activecontent === content.id ? 10 : 1, opacity: content.status === "NOT INITIATED" ? .5 : 1, width: 200, borderRadius: 7, marginRight: i === item.contents.length - 1 ? 0 : 10, backgroundColor: 'white', height: 100 }]}
                                        >
                                            <TouchableOpacity
                                                disabled={content.status === "NOT INITIATED" && true}
                                                onPress={() => {
                                                    scrollToItem(sectionIndex, content.id)
                                                    itemCallback(content.id)
                                                }}
                                                style={{ flex: 1 }}
                                            >
                                                {/* <Image source={{uri:content.image.url}} style={{width:80, height:80}}/> */}

                                                <View style={{
                                                    backgroundColor: content.status === "COMPLETED" ? '#3CAF29' : "#f2f2f2",
                                                    width: 40, height: 40,
                                                    borderTopLeftRadius: 7,
                                                    borderBottomRightRadius: 7,
                                                    position: 'absolute', right: 0, bottom: 0, zIndex: 2,
                                                    alignItems: 'center', justifyContent: 'center',
                                                }}>
                                                    <Ionicons name={content.status === "COMPLETED" ? "ios-checkmark" : content.contentType === "VIDEO" ? "ios-play" : content.contentType === "AUDIO" ? "ios-volume-high" : content.contentType === "DOCUMENT" ? "ios-document" : content.contentType === "EXAM" ? "ios-checkmark-circle-outline" : "md-list"}
                                                        size={content.status === "COMPLETED" ? 40 : 30} color={content.status === "COMPLETED" ? "white" : "black"}
                                                    />
                                                </View>

                                                <View style={{ width: "100%", justifyContent: "flex-start", paddingHorizontal: 10, paddingTop: 10 }}>
                                                    <Text style={{ fontFamily: 'DemiBold', fontSize: 14 }}>{content.name}</Text>
                                                </View>

                                            </TouchableOpacity>
                                        </Animatable.View>
                                    )
                                })}
                            </View>


                        </View>)
                }}

            />
        </Animatable.View>

    );
}

export default CourseCollapsableItems;

const styles = StyleSheet.create({
    shadow: {
        shadowColor: "rgba(0, 0, 0, 0.3)",
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: .4,
        shadowRadius: 10,
    }
});
