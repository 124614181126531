import React, { useContext, useState } from "react";
import { Image, Text, View, StyleSheet, Dimensions } from "react-native";
import MissionComponent from "./MissionComponent";
import ImageRel from "../common/ImageRel";
import Svg, { Path, Line as LineSvg, Circle } from "react-native-svg";
import { get } from "lodash";
import { LinearGradient } from "expo-linear-gradient";
import { Ionicons } from "@expo/vector-icons";
import AuthContext from "../../context/AuthContext";

const SCREEN_WIDTH = Math.round(Dimensions.get("window").width);

const ItineraryComponent = ({ itineraryav, setMission, i, disabled, navigation }) => {
	const [pathWidth, setPathWidth] = useState();
	const { customization } = useContext(AuthContext);
	return (
		<View
			style={[styles.shadow, { elevation: 20 + i, maxWidth: 800, width: "100%" }]}
			onLayout={({
				nativeEvent: {
					layout: { width },
				},
			}) => setPathWidth(width)}>
			{/* TITULO */}
			{/* <LinearGradient
        start={[0, 1]}
        colors={itineraryav.customHeader || [customization.mainColor, customization.mainColor]}
        style={{ width: "100%", height: 10, marginBottom: 50, height: 50, alignItems: 'center', justifyContent: "center" }}
      >
        <Text style={{ fontSize: 16, color: customization.buttonMainColorText, fontFamily: "Bold" }}>
          {itineraryav.name}
        </Text>

      </LinearGradient> */}
			{/* FIN TITULO ITINERARIO */}

			<View style={{ position: "relative", opacity: disabled ? 0.3 : 1, marginTop: 50 }}>
				<Ionicons name="ios-send" size={60} style={{ position: "absolute", right: 10, top: -40, transform: [{rotate:"187deg"}] }} />

				{itineraryav.advance.map((mission, i) => {
					const adv = get(itineraryav, "advance", []);
					const finished = adv.length === adv.map((e) => e.userResponse).filter((f) => f).length && adv.length > 0;

					return (
						<View key={i} style={{ paddingHorizontal: 12 }}>
							<MissionComponent setMission={(mission) => setMission(mission, i)} mission={mission} pathWidth={pathWidth} itineraryav={itineraryav} index={i} navigation={navigation} disabled={disabled} />

							{/* ULTIMO TROZO DE LINEA DE ITINERARIO */}
							{itineraryav.advance.length - 1 === i && (
								<View style={{ height: 85, marginTop: -10, overflow: "hidden", transform: [{ rotateY: i % 2 === 1 ? "0deg" : "180deg" }] }}>
									<Svg width="100%" height="170px" style={{ position: "absolute" }}>
										<LineSvg x1="85" y1="5" x2="90%" y2="5" stroke={finished ? customization.mainColor : "gainsboro"} strokeDasharray={"1 17"} strokeLinecap="round" strokeWidth="10" fill="none" />
										<Svg width="85" height="100%">
											<Circle cx="85" cy="85" r="80" stroke={finished ? customization.mainColor : "gainsboro"} strokeDasharray={"1 17"} strokeLinecap="round" strokeWidth="10" fill="none" />
										</Svg>
									</Svg>

									{/* <Svg width="100%" height="170px">
										<Path d={`M 85 5 L ${SCREEN_WIDTH - 100} 5`} stroke={finished ? customization.mainColor : "gainsboro"} strokeDasharray={"1 17"} strokeLinecap="round" strokeWidth="10" fill="none" />
										<Path d="M 5 85  A 80 80 0 0 1 85 5 " stroke={finished ? customization.mainColor : "gainsboro"} strokeDasharray={"1 17"} strokeLinecap="round" strokeWidth="10" fill="none" />
									</Svg> */}
								</View>
							)}
						</View>
					);
				})}
			</View>
		</View>
	);
};

export default ItineraryComponent;

const styles = StyleSheet.create({
	shadow: {
		backgroundColor: "white",
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: -10,
		},
		shadowOpacity: 0.1,
		shadowRadius: 6.5,
		elevation: 10,
	},
});
