import React, { useState, useContext, useEffect } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
  Dimensions,
  Image
} from "react-native";
import { Ionicons } from "@expo/vector-icons";
import * as Linking from 'expo-linking'

import moment from "moment";
import withLocalStorage from "../../utils/localStore";

import ImageRel from "../common/ImageRel";
import * as Animatable from 'react-native-animatable'
import LikesButton from "./LikesButton";
import VideoPlayer from '../videoPlayer/videoPlayer'
import * as WebBrowser from 'expo-web-browser';
import * as Analytics from 'expo-firebase-analytics';
import PointsContext from '../../context/PointsContext'
import { firestore } from 'firebase'
import AuthContext from "../../context/AuthContext";
import MediaViewerManager from "../common/MediaViewerManager";

if (Platform.OS !== 'web') {
  ParsedText = require('react-native-parsed-text').default
}



const CardPost = ({
  commentCallback,
  position,
  deleting,
  deleted,
  postID,
  title,
  media,
  image,
  publishDate,
  user,
  style,
  likes = [],
  likeCallback,
  content,
  comments = [],
  deleteCallback,

}) => {

  const { userData, customization } = useContext(AuthContext)
  const [liked, setLiked] = useState(false)
  const { givePoints } = useContext(PointsContext)
  const [size, setSize] = useState({
    width: 0,
    height: 0
  })

  const date = moment(publishDate).format("DD [de] MMMM YYYY");
  const del = {
    0: {
      opacity: 0.2,
    },
    1: {
      opacity: 1.0,
    }
  }

  const giveLike = () => {
    setLiked(!liked)
    if (likes && likes.includes(userData.id)) {
      firestore().collection("tenants").doc(userData.tenants[0].id).collection("feed").doc(postID).update({
        likes: firestore.FieldValue.arrayRemove(userData.id)
      })
      firestore().collection("users").doc(userData.id).update({
        feedLikes: firestore.FieldValue.arrayRemove(postID)
      })
      Analytics.logEvent('Dislike', {
        contentType: 'post',
        itemId: postID,
        method: 'app'
      });
    } else {
      firestore().collection("tenants").doc(userData.tenants[0].id).collection("feed").doc(postID).update({
        likes: firestore.FieldValue.arrayUnion(userData.id)
      })
      firestore().collection("users").doc(userData.id).update({
        feedLikes: firestore.FieldValue.arrayUnion(postID)
      })
      Analytics.logEvent('Like', {
        contentType: 'post',
        itemId: postID,
        method: 'app'
      });
      givePoints({
        showPoints: true,
        message: "Conseguiste 10 Puntos al dar like a un comentario",
        points: 10
      })
    }
  }


  useEffect(() => {
    likes.includes(userData.id) && setLiked(true)
  }, [likes])


  return (
    <Animatable.View
      animation={deleted === position ? "zoomOut" : deleting === position ? del : "fadeIn"}
      iterationCount={deleting === position ? "infinite" : 1}
      direction="alternate"
      pointerEvents={deleting === position ? "none" : "auto"}
      style={[styles.shadow, {
        marginTop: 20, width: "100%",
        borderColor: deleting === position ? "#017676" : "transparent",
        borderWidth: deleting === position ? 3 : 0
      }]}
    >
      <View style={styles.card}>
        {/* Info Usuario */}
        <View style={styles.userInfoContainer}>
          <View style={{ flexDirection: "row", alignItems: "center", flex: 1 }}>
            <Image style={styles.userImage} source={{ uri: user && user.image || "https://sigmaestimates.com/wp-content/uploads/sites/2/2018/10/person-placeholder-1.png" }} />
            <View style={{ marginLeft: 10, justifyContent: "center" }}>
              <Text style={styles.username}>
                {user && user.name}
              </Text>
              <Text style={styles.date}>{date}</Text>
            </View>
          </View>
          {user && user.id.id.includes(userData.id) &&
            <TouchableOpacity onPress={() => deleteCallback()} style={{ width: 45, height: 45, alignItems: "center", justifyContent: "center" }}>
              <Ionicons name="md-trash" color="gray" size={25} />
            </TouchableOpacity>
          }
        </View>

        <MediaViewerManager useNativeControls={true} url={media} cover={image} type={media ? "VIDEO" : "TEXT"} />

        {/* Imagen del Post
        {image &&
          <ImageRel
            style={{ width: "100%", height: 400, borderRadius: 8 }}
            source={image}
          />
        }

        {media &&
          <View style={{ height: 300 }} >
            <VideoPlayer
              shouldPlay={false}
              style={{ height: 300, width: '100%' }}
              useNativeControls={true}
              resizeMode="contain"
              source={{ uri: media }}   // Can be a URL or a local file.
            />
          </View>
        } */}


        {/* Contenido del Post */}
        <View style={{ paddingHorizontal: 10, paddingVertical: 20 }}>
          {Platform.OS !== "web" ?
            <ParsedText
              style={styles.content}
              parse={
                [
                  { type: 'url', style: styles.url, onPress: async (ev) => await WebBrowser.openBrowserAsync(ev.toLowerCase().includes("://") ? ev : `https://${ev}`) },
                  { type: 'phone', style: styles.phone, onPress: (ev) => Linking.openURL(`tel:${ev}`) },
                  { type: 'email', style: styles.email, onPress: (ev) => Linking.openURL(`mailto:${ev}`) },
                ]
              }
              childrenProps={{ allowFontScaling: false }}
            >
              {content}
            </ParsedText>
            :
            <Text style={{ fontFamily: "Regular", fontSize: 16 }}>{content}</Text>
          }
        </View>



        {/* Botones Inferiores Like y Comentar */}
        <View style={{ flexDirection: "row", borderTopColor: "gainsboro", borderTopWidth: 1 }}>
          {/* <LikesButton postID={postID} /> */}

          <TouchableOpacity onPress={() => giveLike()}
            style={{ flexDirection: "row", flex: 1, height: 45, alignItems: "center", justifyContent: "center" }}
          >
            <Ionicons name={liked ? "ios-heart" : "heart-outline"} size={20} style={{ marginRight: 10, color: "#ef0000" }} />
            <Text style={{ fontFamily: "DemiBold", fontSize: 14, color: "gray" }}>{likes ? likes.length : 0} Likes</Text>
          </TouchableOpacity>

          <TouchableOpacity style={styles.likeContainer} onPress={() => commentCallback()} >
            <Ionicons name={"md-chatbubbles"} size={25} style={{ marginRight: 10, color: "grey" }} />
            <Text style={{ fontFamily: "DemiBold", fontSize: 14, color: "grey" }}>
              {comments.length} Comentarios
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </Animatable.View>
  );
};

export default CardPost;

const styles = StyleSheet.create({
  userInfoContainer: {
    padding: 10,
    flexDirection: "row",
    height: 60,
    borderBottomColor: "gainsboro",
    justifyContent: "space-between",
    borderBottomWidth: 1,
  },
  url: {
    color: "#017676"
  },
  email: {
    color: "#017676"
  },
  phone: {
    color: "#017676"
  },
  shadow: {
    elevation: 10,
    borderRadius: 15,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 3
    },
    borderWidth: 0,
    shadowOpacity: 0.15,
    shadowRadius: 7,
  },
  username: {
    fontSize: 16,
    lineHeight: 20,
    fontFamily: "DemiBold"
  },
  date: {
    fontSize: 12,
    lineHeight: 16,
    color: "gray",
    fontFamily: "Regular"
  },
  likeContainer: {
    height: 40,
    paddingHorizontal: 10,
    flex: 1,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center"
  },
  userImage: {
    overflow: "hidden",
    borderRadius: 20,
    height: 40,
    width: 40
  },
  card: {
    // minHeight: 250,
    width: "100%",
    backgroundColor: "white",
    borderRadius: 12
  },
  categoryContainer: {
    marginBottom: 5,
    marginRight: 5,
    backgroundColor: "black",
    alignSelf: "flex-start",
    borderRadius: 15,
    paddingVertical: 5,
    paddingHorizontal: 10
  },
  categoryText: {
    color: "white",
    fontSize: 10,
    fontFamily: "DemiBold"
  },
  content: {
    fontFamily: "Regular",
    fontSize: 16
  }
});
