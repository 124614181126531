import React, { useState, useEffect } from "react";
import { ImageBackground, View, ActivityIndicator, Image } from "react-native";
// import * as Crypto from 'expo-crypto';
import * as FileSystem from 'expo-file-system';

const ImageRel = ({ source, background = false, imageStyle = {}, backgroundColor = "gainsboro", style, ratio, children, resizeMode = "cover", onLoad, onLoadEnd }) => {

  // const [uri, setUri] = useState(null)

  // useEffect(() => {
  //   setUri(source)
  //   pathExist()
  //   return () => {
  //     setUri(null)
  //   }
  // }, [source])


  // const pathExist = async () => {

  //   try {
  //     const name = await Crypto.digestStringAsync(
  //       Crypto.CryptoDigestAlgorithm.SHA256,
  //       uri.split("?")[0]
  //     );

  //     const path = `${FileSystem.cacheDirectory}${name}`
  //     const image = await FileSystem.getInfoAsync(path)

  //     if (image.exists) {
  //       setUri(image.uri)
  //     } else {
  //       const newImage = await FileSystem.downloadAsync(uri, path)
  //       setUri(newImage.uri)
  //     }
  //   }
  //   catch{
  //     err =>
  //   }
  // }

  if (background) {
    return (
      <ImageBackground
        imageStyle={{ ...imageStyle }}
        style={{ ...style, backgroundColor: backgroundColor }}
        source={{ uri: source ? source : "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=" }}
      >
        {children}
      </ImageBackground>
    );
  } else {
    return (
      <View style={{ ...style, aspectRatio: ratio, backgroundColor: backgroundColor }}>
        <Image
          // {...props}
          style={{ ...style, flex: 1, resizeMode: resizeMode }}
          key={source}
          onLoadEnd={onLoadEnd}
          onLoad={onLoad}
          source={{ uri: source ? source : "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=" }}
        />
      </View>
    )
  }
};

export default ImageRel;
