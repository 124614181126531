import React, { useState, useEffect, useRef, useContext } from "react";
import {
  ScrollView,
  StyleSheet,
  View,
  ActivityIndicator,
  Text,
  Image,
  Platform,
} from "react-native";

import { firestore, storage } from 'firebase'
import AuthContext from "../../context/AuthContext";
import ValorationForm from '../../components/rankingComponents/ValorationForm'
import moment from 'moment'
import { useSafeArea } from 'react-native-safe-area-context'

const ProfileHome = ({ navigation }) => {

  const { userData, customization } = useContext(AuthContext)
  const inset = useSafeArea()

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)

  const [open, setOpen] = useState(null)


  useEffect(() => {

    setLoading(true)

    firestore().collection("users").doc(userData.id).collection("evaluations").orderBy("createdAt", "desc").get().then((snapshot) => {

      Promise.all(snapshot.docs.map(async d => {
        const id = d.data().user.id
        const url = id ? await storage().ref(`users/${id}/profilePicture`).getDownloadURL().then(res => res).catch(err => "https://hmri.org/dev/wp-content/uploads/2019/06/placeholder-person.png") : "https://hmri.org/dev/wp-content/uploads/2019/06/placeholder-person.png"
        return ({ ...d.data(), id: d.id, user: { ...d.data().user, image: url }, ref: d.ref })
      })).then(res => {
        setData(res)
        setLoading(false)
      })
    }).catch(err =>
      console.log(err)
    )

  }, [])


  if (loading)
    return (
      <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
        <ActivityIndicator size="large" />
      </View>
    );

  return (
    <ScrollView
      contentContainerStyle={{ flexGrow: 1, padding: 15, paddingBottom: 50 + inset.bottom }}
      showsVerticalScrollIndicator={false}
    >
      <Text style={{ textAlign: Platform.OS === "web" ? "center" : null, marginHorizontal: 10, marginTop: 20, marginBottom: 10, fontFamily: "Bold", fontSize: 34 }}>Mis Valoraciones</Text>
      {data.map((review, i) => {
        return (
          <View key={i} style={{ marginBottom: 20 }}>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <Image source={{ uri: review.user.image }} style={{ width: 40, height: 40, borderRadius: 20 }} />
              <View>
                <Text style={{ marginLeft: 10, fontSize: 18, fontFamily: "DemiBold" }}>{review.user.name} {review.user.surname}</Text>
                <Text style={{ marginLeft: 10, color: "gray", fontFamily: "DemiBold" }}>{moment(review.createdAt.toDate()).format("DD MMMM YYYY")}</Text>
              </View>
            </View>
            <ValorationForm disabled={true} key={i} open={open === i} toogleCallback={() => setOpen(open === null ? i : open === i ? null : i)} form={review.evaluation} template={review.form} />
          </View>
        )
      })}


    </ScrollView>
  );
};

ProfileHome.navigationOptions = {
  header: null
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    marginBottom: 60
  },
  shadow: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 5
    },
    shadowOpacity: 0.2,
    shadowRadius: 10.5,
    elevation: 10
  },
  imageRedond: {
    height: 40,
    width: 40,
    borderRadius: 20,
    resizeMode: "cover",
    borderWidth: 1,
    borderColor: "gainsboro",
    marginRight: 10
  },
  topicBox: {
    width: 300,
    padding: 15,
    marginBottom: 10,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderWidth: 1,
    borderRadius: 4,
    borderColor: "gainsboro"
  }
});

export default ProfileHome;
