import React, { Component } from "react";
import {
  ScrollView,
  Text,
  Dimensions,
  AsyncStorage,
  TouchableOpacity,
  View,
  RefreshControl,
  ActivityIndicator
} from "react-native";
// import constantes from "../../utils/constantes";
import URL from "../../constants/Servers"
// import auth from "../../utils/userData";
import CardNoti from "../../components/CardNotification";
import { Ionicons } from "@expo/vector-icons";

let SCREEN_WIDTH = Dimensions.get("window").width;
let SCREEN_HEIGHT = Dimensions.get("window").height;

export class MyNotificacion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notifications: [],
      loading: false,
      refreshing: false
    };
  }

  componentDidMount = async () => {
    this.setState({ loading: true });
    const user = JSON.parse(await AsyncStorage.getItem("user"));
    const token = await AsyncStorage.getItem('jwt')
    const headers = {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
    const query =
      "/graphql?query=%7B%0A%20%20%0A%20%20mynotifications(sort%3A%20%22date%3Adesc%22,%20where%3A%20%7Busers%3A%20%22" +
      user.id +
      "%22%7D,%20limit%3A10)%7B%0A%20%20%20%20id%0A%20%20%20%20title%0A%20%20%20%20body%0A%20%20%20%20data%0A%20%20%20%20date%0A%20%20%7D%0A%7D";

    await fetch(URL.URL_API + query, {
      method: "GET",
      headers
    })
      .then(data => data.json())
      .then(response =>
        this.setState({
          notifications: response.data.mynotifications,
          loading: false
        })
      );
  };

  render() {
    return this.state.notifications.length === 0 ? (
      <View>
        <ScrollView
          refreshControl={
            <RefreshControl
              refreshing={this.state.refreshing}
              onRefresh={this.componentDidMount}
            />
          }
        >
          {this.state.loading === true ? (
            <ActivityIndicator />
          ) : (
              <View
                style={{
                  height: 250,
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <Text
                  style={{ fontSize: 24, textAlign: "center", color: "gray" }}
                >
                  No tienes notificaciones
              </Text>
              </View>
            )}
        </ScrollView>
      </View>
    ) : (
        <View>
          <View
            style={{
              height: 45,
              backgroundColor: "white",
              alignContent: "center",
              justifyContent: "center",
              elevation: 5,
              borderBottomColor: "gainsboro",
              borderBottomWidth: 1
            }}
          >
            <Text style={{ fontSize: 20, textAlign: "center" }}>
              Últimas notificaciones
          </Text>
            <TouchableOpacity
              style={{ position: "absolute", top: 0, left: 20 }}
              onPress={() => this.props.navigation.goBack()}
            >
              <View
                style={{
                  height: 45,
                  width: 60,
                  flex: 1,
                  flexDirection: "row"
                }}
              >
                <Ionicons name="ios-arrow-back" size={35} color="gray" />
              </View>
            </TouchableOpacity>
          </View>
          <ScrollView
            refreshControl={
              <RefreshControl
                refreshing={this.state.refreshing}
                onRefresh={this.componentDidMount}
              />
            }
          >
            {this.state.loading === true ? (
              <ActivityIndicator />
            ) : (
                this.state.notifications.map((noti, i) => {
                  return (
                    <CardNoti
                      key={i}
                      posicion={i}
                      title={noti.title}
                      body={noti.body}
                      id={noti.id}
                      navigation={this.props.navigation}
                      date={noti.date}
                      data={noti.data}
                    />
                  );
                })
              )}
          </ScrollView>
        </View>
      );
  }
}

export default MyNotificacion;
