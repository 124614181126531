import React, { useState, useEffect, useContext } from "react";
import {
    View,
    Text,
    ScrollView,
    StyleSheet,
    StatusBar,
    ImageBackground,
    Platform,
} from "react-native";

import LinearBarIndicator from "../../components/LinearBarIndicator";
import CourseSection from '../../components/CourseSection'
import TutorChat from '../../components/TutorChat'
import moment from 'moment'

import { firestore, storage } from 'firebase'


import { useSafeArea } from 'react-native-safe-area-context';
import AuthContext from "../../context/AuthContext";

import * as Localization from 'expo-localization';
import i18n from 'i18n-js';
import translations from '../../locale/translations'
import Button from "../../components/common/Button";

import HTML from "react-native-render-html";

i18n.translations = translations;
i18n.locale = Localization.locale;
i18n.fallbacks = true;



const Course = ({ id, navigation, route }) => {

    const { userData, customization, dimensions } = useContext(AuthContext)
    const [tab, changeTab] = useState(1)
    const [data, setData] = useState({})
    const [originalData, setOriginalData] = useState({})
    const [loading, setLoading] = useState(true)

    const insets = useSafeArea()


    // VARIABLES BASICAS DE QUE CONTENIDO ESTAMOS USANDO
    const advanceID = route.params.id


    // FUNCION DE CARGA INICIAL

    useEffect(() => {
        setLoading(true)
        const unsubscribe = firestore().collection("users").doc(userData.id).collection("courseavs").doc(advanceID).onSnapshot(async (querySnapshot) => {
            const img = querySnapshot.data().image
            const url = img ? await storage().ref(img).getDownloadURL().then(res => res).catch(err => "") : null
            Promise.all(querySnapshot.data().advance.map(async section => {
                const img = section.image
                const url = img ? await storage().ref(img).getDownloadURL().then(res => res).catch(err => "") : null
                return { ...section, image: url }
            })).then(res => {
                setOriginalData(querySnapshot.data().advance)
                setData({ ...querySnapshot.data(), initDate: querySnapshot.data().initDate ? querySnapshot.data().initDate.toDate() : false, image: url, id: querySnapshot.id, advance: res })
                navigation.setOptions({ title: 'Curso - ' + querySnapshot.data().name })
                setLoading(false)
            })
        })

        return () => { unsubscribe() }
    }, [])


    const [totalContents, setTotalContents] = useState(0)
    const [completed, setCompleted] = useState(0)
    const [percentage, setPercentage] = useState(0)


    useEffect(() => {
        const total = data.advance ? data.advance.reduce((totalContents, section) => (totalContents === undefined ? 0 : totalContents) + section.contents.length, 0) : 0
        const comp = data.advance ? data.advance.reduce((total, section) => (total === undefined ? 0 : total) + section.contents.filter((content) => content.status === "COMPLETED").length, 0) : 0
        setTotalContents(total)
        setCompleted(comp)
        setPercentage(total !== 0 ? comp / total * 100 : 0)
    }, [data])


    const _initiateCourse = async () => {

        let arrayContents = data.advance.reduce((newarr, section) => [...newarr, ...section.contents], [])
        const advanceID = data.id
        const activecontent = arrayContents[completed].id
        const activeContentType = arrayContents[completed].contentType

        const newarr = await originalData.map((section, i) => {
            if (i === 0) {
                return {
                    ...section,
                    status: "INITIATED",
                    contents: section.contents.map((content, i) => {
                        if (i === 0) {
                            return {
                                ...content,
                                status: "INITIATED"
                            }
                        }
                        else {
                            return content
                        }
                    })
                }
            } else {
                return {
                    ...section
                }
            }
        })

        firestore().collection("users").doc(userData.id).collection("courseavs").doc(advanceID).update({
            state: "ACTIVE",
            advance: newarr
        }).then(res => {
            navigation.navigate(activeContentType === "EXAM" ? "Exam" : 'PostSmart', { advanceID: advanceID, id: activecontent })
        })
        // await changeContentStatus({ variables: { advanceID, advance: newarr, state: "ACTIVE" } })

    }

    const _goToLast = async () => {
        
        let arrayContents = data.advance?.reduce((newarr, section) => [...newarr, ...section.contents], [])
        const advanceID = data?.id
        const activecontent = arrayContents[completed]?.id
        const activeContentType = arrayContents[completed]?.contentType

        await navigation.navigate(activeContentType === "EXAM" ? "Exam" : 'PostSmart',
            {
                advanceID: advanceID,
                id: activecontent
            })

    }

    if (loading) return null
    return (
        <View style={{ flex: 1, backgroundColor: "white" }}>

            <ScrollView showsVerticalScrollIndicator={false} contentContainerStyle={{ alignItems: Platform.OS === "web" ? 'center' : null, paddingBottom: 50 }}>
                <ImageBackground source={{ uri: data.image }} style={{ backgroundColor: 'gray', justifyContent: 'center', position: 'relative', width: "100%", alignItems: "center", paddingTop: insets.top + 50, minHeight: 300 }}>
                    <View style={{ position: 'absolute', backgroundColor: 'rgba(0,0,0,0.7)', top: 0, bottom: 0, right: 0, left: 0, zIndex: 2 }} />

                    <View style={{ position: "relative", flex: 1, paddingHorizontal: 10, zIndex: 3, maxWidth: 900, width:"100%", justifyContent: 'center' }}>
                        <Text style={{ marginBottom: 10, fontSize: 28, fontFamily: 'DemiBold', color: 'white' }} >{data.name}</Text>
                        {/* <Text style={{ marginBottom: 10, fontSize: 16, fontFamily: 'Regular', color: 'white' }} >{data.description}</Text> */}
                        <HTML
                        baseFontStyle={{ fontFamily: 'Regular', fontSize: 18, color: '#303030' }}
                        html={data.description ? data.description : "<p></p>"}
                        imagesMaxWidth={900}
                        staticContentMaxWidth={900}
                        onLinkPress={async (evt, href) =>
                            await WebBrowser.openBrowserAsync(href.toLowerCase().includes("://") ? href : `https://${href}`)
                        }
                    />
                        <View style={{ position: "absolute", bottom: 15 }}>
                            <Text style={{ color: 'white', fontFamily: "DemiBold", fontSize: 20 }}>{completed} de {totalContents} {i18n.t('completed')}</Text>
                        </View>
                    </View>


                    {percentage !== Infinity && <LinearBarIndicator percentage={percentage} width={'100%'} height={10} rounded={0} gradient={['#B4EC51', '#429321']} styles={{ position: 'absolute', bottom: 0, zIndex: 3 }} />}
                </ImageBackground>
                {/* </ImageRel> */}

                {/* <View style={{ height: 70, flexDirection: 'row', width: screenWidth, maxWidth: 900 }}>

                        <TouchableOpacity onPress={() => changeTab(1)} style={{ height: 70, justifyContent: 'center', alignItems: 'center', width: '33%' }}>
                            <Text style={{ fontFamily: "DemiBold", color: tab === 1 ? "black" : "gray", fontSize: tab === 1 ? 20 : 16 }}>Contenidos</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => changeTab(2)} style={{ height: 70, justifyContent: 'center', alignItems: 'center', width: '34%' }}>
                            <Text style={{ fontFamily: "DemiBold", color: tab === 2 ? "black" : "gray", fontSize: tab === 2 ? 20 : 16 }}>Foro</Text>
                        </TouchableOpacity>
                        <TouchableOpacity onPress={() => changeTab(3)} style={{ height: 70, justifyContent: 'center', alignItems: 'center', width: '33%' }}>
                            <Text style={{ fontFamily: "DemiBold", color: tab === 3 ? "black" : "gray", fontSize: tab === 3 ? 20 : 16 }}>Mi Tutor</Text>
                        </TouchableOpacity>

                    </View> */}

                {tab === 1 &&

                    <View style={{ flex: 1, paddingHorizontal: 10, marginTop:10, width: dimensions.width, maxWidth: 900 }}>
                        
                        { data.initDate ? moment(moment()).isAfter(data.initDate ? data.initDate : moment()) ?
                            data.state !== "COMPLETED" &&
                            <Button
                                onPress={() => (data.advance.length === data.advance.filter((section) => section.status === "NOT INITIATED").length ? _initiateCourse() : _goToLast())}
                                label={data.advance.length === data.advance.filter((section) => section.status === "NOT INITIATED").length ? "Comenzar Módulo" : "Continuar Módulo"}
                            />
                            :
                            <Button
                                disabled={true}
                                onPress={() => (data.advance.length === data.advance.filter((section) => section.status === "NOT INITIATED").length ? _initiateCourse() : _goToLast())}
                                label={`El curso estará disponible el ${moment(data.courseav ? data.courseav.initDate : moment()).format("DD [de] MMMM [a las] mm:hh")}`}
                            />
                            :
                            <Button
                                onPress={() => (data.advance.length === data.advance.filter((section) => section.status === "NOT INITIATED").length ? _initiateCourse() : _goToLast())}
                                label={data.advance.length === data.advance.filter((section) => section.status === "NOT INITIATED").length ? "Comenzar Módulo" : "Continuar Módulo"}
                            />
                        }

                        {data.advance && data.advance.map((section, i) => {
                            return (
                                <CourseSection
                                    key={i}
                                    navigation={navigation}
                                    id={data?.id}
                                    content={section}
                                    advance={data}
                                    arrayContents={data.advance.reduce((newarr, section) => [...newarr, ...section.contents], [])}
                                />
                            )
                        })}


                    </View>

                }

                {tab === 2 && <View style={{ marginTop: 15, flex: 1 }}>

                    {data.courseav && data.courseav.course && data.courseav.course.forum !== undefined ?
                        // <TutorChat tutor={data.courseav.tutor} courseID={data.courseav.id} />
                        <View><Text>Hilo del Foro</Text></View>
                        :
                        <View style={{ flex: 1, paddingVertical: 100, backgroundColor: 'gainsboro', marginHorizontal: 10, borderRadius: 15, alignItems: 'center', justifyContent: 'center' }}>
                            <Text style={{ fontFamily: 'DemiBold', fontSize: 20 }}>
                                Este módulo no tiene hilos abiertos
                                </Text>
                        </View>
                    }

                </View>}



                {tab === 3 &&

                    <View style={{ marginTop: 15, flex: 1, maxWidth: 900 }}>
                        {data.courseav.tutor !== null ?
                            <TutorChat tutor={data.courseav.tutor} courseID={data.courseav.id} />
                            :
                            <View style={{ flex: 1, paddingVertical: 100, backgroundColor: 'gainsboro', marginHorizontal: 10, borderRadius: 15, alignItems: 'center', justifyContent: 'center' }}>
                                <Text style={{ fontFamily: 'DemiBold', fontSize: 20 }}>
                                    No tienes un tutor Asignado
                                </Text>
                            </View>
                        }
                    </View>

                }
            </ScrollView>
        </View>
    )
}

export default Course;

const styles = StyleSheet.create({
    shadow: {
        shadowColor: "rgba(0, 0, 0, 0.3)",
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.8,
        shadowRadius: 10,
        elevation: 10
    }
});