import { Platform } from 'react-native'
import React from 'react'
import Header from '../components/AppHeader'

import SocialAppraisal from "../screens/stackRanking/SocialAppraisal";
import ValorationUser from "../screens/stackRanking/ValorationUser";


import { createStackNavigator } from '@react-navigation/stack';
import HeaderWeb from '../components/HeaderWeb';


const Stack = createStackNavigator();

const StackRanking = () => {
    return (
        <Stack.Navigator
            headerMode="screen"
            screenOptions={{
                cardStyle: { flex: 1 },
                header: ({ scene, previous, navigation }) => {
                    // console.log("scene", previous)
                    return (
                        Platform.OS === "web" ?
                            <HeaderWeb />
                            :
                            <Header
                                logo={previous ? false : true}
                                backCallback={() => navigation.goBack()}
                                backButton={previous ? true : false}
                            />
                    )
                }
            }}
        >
            <Stack.Screen name="SocialAppraisal" component={SocialAppraisal} />
            <Stack.Screen name="ValorationUser" component={ValorationUser} />
        </Stack.Navigator>
    );
}

export default StackRanking;