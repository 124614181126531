import React, { useState, useEffect, useContext } from 'react';
import { Image, View, Text, StyleSheet, ScrollView, TouchableOpacity, TextInput, Platform } from 'react-native';
import { firestore, storage } from 'firebase';
import AuthContext from '../../context/AuthContext';
import { useSafeArea } from 'react-native-safe-area-context';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'

import ValorationForm from '../../components/rankingComponents/ValorationForm'

import { useNavigation } from '@react-navigation/native';


const ValorationUser = ({ route }) => {

    const { customization, userData } = useContext(AuthContext)
    const userID = route.params.id
    const { goBack } = useNavigation()
    const insets = useSafeArea()
    const [data, setData] = useState({})
    const [forms, setForms] = useState([])
    const [form, setForm] = useState({})
    const [open, setOpen] = useState(null)


    useEffect(() => {
        var listener = firestore().collection("users").doc(userID).onSnapshot(async doc => {
            if (doc.exists) {
                const img = doc.data().image
                const url = img ? await storage().ref(img).getDownloadURL().then(res => res).catch(err => "https://cdn5.vectorstock.com/i/thumb-large/85/94/person-gray-photo-placeholder-man-silhouette-sign-vector-23838594.jpg") : "https://cdn5.vectorstock.com/i/thumb-large/85/94/person-gray-photo-placeholder-man-silhouette-sign-vector-23838594.jpg"
                setData({ ...doc.data(), id: doc.id, image: url, ref: doc.ref })
            } else {

            }
        })

        firestore().collection("evaluations").where("active", "==", true).get().then(res => {
            const data = res.docs.map(d => ({ ...d.data(), id: d.id, ref: d.ref }))
            setForms(data)
        })

        return () => listener()

    }, [])


    const sendValoration = (template, results) => {
        const variables = {
            user: {
                id: userData.id,
                ref: userData.ref,
                name: userData.name,
                surname: userData.surname
            },
            form: template,
            evaluation: results,
            createdAt: firestore.FieldValue.serverTimestamp()
        }
        firestore().collection("users").doc(userID).collection("evaluations").add(variables).then(res => {
            setForm({})
            goBack()
        }).catch(err =>
            console.log(err)
        )
    }

    return (
        <KeyboardAwareScrollView showsVerticalScrollIndicator={false} contentContainerStyle={{ flexGrow: 1, maxWidth: 800, width: "100%", alignSelf: "center", padding: 15, paddingBottom: 70 + insets.bottom }}>
            <View style={{ flex: 1 }}>
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <Image source={{ uri: data.image }} style={{ zIndex: 2, width: 80, height: 80, borderRadius: 80 }} />
                    <View style={[styles.shadow, { padding: 15, backgroundColor: "white", borderRadius: 10, marginLeft: -20, paddingLeft: 30, flex: 1 }]}>
                        <Text style={{ fontFamily: "DemiBold", fontSize: 20 }}>{data.name} {data.surname}</Text>
                        {data.position && <Text style={{ fontFamily: "DemiBold", fontSize: 16, color: "gray", marginTop: 5, }}>{data.position} | {data.team}</Text>}
                        {data.bio && <Text numberOfLines={3} style={{ fontFamily: "Regular", fontSize: 14, marginTop: 5 }}>{data.bio}</Text>}
                    </View>
                </View>
                <View style={{ marginTop: 30 }}>
                    <Text style={{ textAlign: Platform.OS === "web" ? "center" : null, marginHorizontal: 10, marginTop: 0, marginBottom: 10, fontFamily: "Bold", fontSize: 34 }}>Valoraciones</Text>
                    <Text numberOfLines={3} style={{ fontFamily: "Regular", fontSize: 16, marginTop: 5 }}>Puedes valorar a {data.name} {data.surname} en las siguientes competencias</Text>
                </View>


                {forms.length > 0 ?
                    forms.map((f, i) => {
                        return (

                            <ValorationForm key={i}
                                // completed={myEvaluations.includes(f.id)} 
                                open={open === i} toogleCallback={() => setOpen(open === null ? i : open === i ? null : i)} submitCallback={() => sendValoration(f, form)} setForm={setForm} form={form} template={f} />
                        )

                    })
                    :
                    <View style={{ flex: 1, paddingVertical: 50, alignItems: "center", justifyContent: "center" }}>
                        <Text style={{ fontFamily: "DemiBold", fontSize: 18, color: "gainsboro" }}>No hay Valoraciones Disponibles</Text>
                    </View>
                }
            </View>
        </KeyboardAwareScrollView>
    );
}

export default ValorationUser;

const styles = StyleSheet.create({
    shadow: {
        shadowColor: "rgba(0, 0, 0, 0.3)",
        shadowOffset: { width: 0, height: 5 },
        shadowOpacity: 0.4,
        shadowRadius: 10,
        elevation: 10,
    }
});

