import React, { useState, useEffect, useRef, useContext } from "react";
import { View, ActivityIndicator, Alert, StatusBar } from "react-native";

import PostMission from "../../components/itineraryComponents/PostMission";
import EventMission from "../../components/itineraryComponents/EventMission";
import TestMission from "../../components/itineraryComponents/TestMission";
import AnswerMission from "../../components/itineraryComponents/AnswerMission";
import UploadMission from "../../components/itineraryComponents/UploadMission";
import VideoMission from "../../components/itineraryComponents/VideoMission";
import withLocalStorage from "../../utils/localStore";
import moment from "moment";
import EndMission from "../../components/itineraryComponents/EndMission";

import BioMission from "../../components/itineraryComponents/BioMission";
import ForumMission from "../../components/itineraryComponents/ForumMission";
import TimerMission from "../../components/itineraryComponents/TimerMission";
import { Audio } from 'expo-av';

import { firestore, storage } from 'firebase'
import AuthContext from "../../context/AuthContext";

const MissionPage = ({ navigation, id, route }) => {

  const { userData } = useContext(AuthContext)

  // ROUTE PARAMS
  const missionID = route.params.id
  const itineraryavID = route.params.itineraryavID


  // INICIALIZE STATES
  const [itineraryAV, setItineraryAV] = useState(null);
  const [userResponse, setUserResponse] = useState(null)
  const [visible, setVisible] = useState(false);
  const [feedbackText, setFeedbackText] = useState(null)
  const [missionComplete, setMissionComplete] = useState(null)
  const [pointsEarned, setPointsEarned] = useState(null)
  const [loader, setLoader] = useState(false);
  const [originalData, setOriginalData] = useState({})
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(true)



  useEffect(() => {

    const unsubscribe = firestore().collection("users").doc(userData.id).collection("itineraryavs").doc(itineraryavID).onSnapshot(async (querySnapshot) => {
      Promise.all(querySnapshot.data().advance.map(async mission => {
        const img = mission.image
        const url = img ? await storage().ref(img).getDownloadURL().then(res => res).catch(err => "") : null
        return ({
          ...mission,
          image: url
        })
      })).then(res => {
        console.log("ASD")
        setOriginalData(querySnapshot.data().advance)
        setData(res.find(x => x.id === missionID))
        setItineraryAV({ ...querySnapshot.data(), id: querySnapshot.id, advance: res })
        setUserResponse(res.find(x => x.id === missionID).userResponse)
        setLoading(false)
      })
    })

    return () => { unsubscribe() }
  }, [])

  // const { } = useQuery(queries.GetItineraryAV, {
  //   variables: { itineraryavID },
  //   onCompleted: (data => {
  //     setItineraryAV(data.avitinerary)
  //     setUserResponse(data.avitinerary.advance.find(x => x.id === missionID).userResponse)
  //   }),
  // })

  // const { loading, error, data, refetch, networkStatus } = useQuery(queries.GetSingleMission, {
  //   variables: {
  //     missionID
  //   },
  // });



  // MUTATIONS
  // const [updatePoints] = useMutation(mutations.updatePoints);

  // const [updateAvItinerary] = useMutation(mutations.updateAvItinerary, {
  //   refetchQueries: [
  //     {
  //       query: queries.GetAvitineraries,
  //       variables: {
  //         userId: id
  //       }
  //     }
  //   ],
  // });



  // FUNCTIONS

  const updateMission = (ev) => {
    setLoader(true)
    const currentIndex = itineraryAV.advance.findIndex(mission => mission.id === missionID)
    const isTest = itineraryAV.advance[currentIndex].type === "TEST"
    if (isTest) {
      const answers = itineraryAV.advance[currentIndex].content.answers
      const withFeedback = itineraryAV.advance[currentIndex].content.withFeedback
      const indexResponse = answers.findIndex(aw => aw.text === ev)
      const { correctAnswer, feedback, points } = answers[indexResponse]
      withFeedback && setFeedbackText(feedback)
      setPointsEarned(points)
      setMissionComplete(correctAnswer)

      const newarr = originalData
      newarr[currentIndex].userResponse = ev
      newarr[currentIndex].userCompleted = moment().format()

      const variables = {
        advance: newarr,
        state: newarr.length === newarr.findIndex(x => x.id === missionID) + 1 ? "COMPLETE" : itineraryAV.state
      }

      firestore().collection("users").doc(userData.id).collection("itineraryavs").doc(itineraryavID).update(variables)
        .then(res => {
          firestore().collection("users").doc(userData.id).update({
            points: firestore.FieldValue.increment(points)
          }).then(res => {
            pointsSound()
            setLoader(false)
            setVisible(true)
          }).catch(err => {
            console.log(err)
            setLoader(false)
            Alert.alert("Ha ocurrido un error al intentar completar el desafío. Por favor, inténtelo de nuevo")
            navigation.navigate("TrainingHome")
          })
        })
        .catch(err => {
          console.log(err)
          setLoader(false)
          Alert.alert("Ha ocurrido un error al intentar completar el desafío. Por favor, inténtelo de nuevo")
          navigation.navigate("TrainingHome")
        })

    } else {
      setPointsEarned(itineraryAV.advance[currentIndex].score)
      setMissionComplete(true)

      const newarr = originalData
    newarr[currentIndex].userResponse = ev
    newarr[currentIndex].userCompleted = moment().format()

    const variables = {
      advance: newarr,
      state: newarr.length === newarr.findIndex(x => x.id === missionID) + 1 ? "COMPLETE" : itineraryAV.state
    }

    firestore().collection("users").doc(userData.id).collection("itineraryavs").doc(itineraryavID).update(variables)
      .then(res => {
        firestore().collection("users").doc(userData.id).update({
          points: firestore.FieldValue.increment(isTest ? points : itineraryAV.advance[currentIndex].score)
        }).then(res => {
          pointsSound()
          setLoader(false)
          setVisible(true)
        }).catch(err => {
          console.log(err)
          setLoader(false)
          Alert.alert("Ha ocurrido un error al intentar completar el desafío. Por favor, inténtelo de nuevo")
          navigation.navigate("TrainingHome")
        })
      })
      .catch(err => {
        console.log(err)
        setLoader(false)
        Alert.alert("Ha ocurrido un error al intentar completar el desafío. Por favor, inténtelo de nuevo")
        navigation.navigate("TrainingHome")
      })
    }
  }

  const pointsSound = async () => {
    const soundObject = new Audio.Sound();
    try {
      await soundObject.loadAsync(require('../../assets/sounds/Achievement.mp3'));
      await soundObject.playAsync();
      // Your sound is playing!
    } catch (error) {
      // An error occurred!
    }
  }



  if (loading) {
    return (
      <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
        <ActivityIndicator size="large" />
      </View>
    )
  }
  return (
    <View style={{ flexGrow: 1, backgroundColor: "white" }}>
      <StatusBar barStyle="light-content" />

      <EndMission
        pointsEarned={pointsEarned}
        missionComplete={missionComplete}
        feedback={feedbackText}
        visible={visible}
        setVisible={visible => setVisible(visible)}
        advance={data}
      />


      <View style={{ flex: 1 }}>

        {/* CONTENIDO TIPO POST */}
        {data.type === "POST" && (
          <PostMission
            data={data}
            userResponse={userResponse}
            loader={loader}
            responseCallback={() => {
              // setVisible(true)
              updateMission(true)
            }}
          />
        )} 

        {/* CONTENIDO TIPO TEST */}
        {data.type === "TEST" && (
          <TestMission
            data={data}
            loader={loader}
            userResponse={userResponse}
            responseCallback={response => {
              updateMission(response)
              // setVisible(true)
            }}
          />
        )}

        {data.type === "VIDEO" && (
          <VideoMission
            data={data}
            loader={loader}
            userResponse={userResponse}
            responseCallback={response => {
              updateMission(true)
              // setVisible(true)
            }}
          />
        )}

        {/* CONTENIDO TIPO ANSWER */}
        {data.type === "ANSWER" && (
          <AnswerMission
            data={data}
            userResponse={userResponse}
            loader={loader}
            responseCallback={response => {
              updateMission(response)
              // setVisible(true)
            }}
          />
        )}

        {/* CONTENIDO TIPO EVENTO */}
        {data.type === "EVENT" && (
          <EventMission
            data={data}
            loader={loader}
            userResponse={userResponse}
            responseCallback={response => {
              updateMission(response)
              // setVisible(true)
            }}
          />
        )}

        {/* CONTENIDO TIPO UPLOAD */}
        {data.type === "UPLOAD" && (
          <UploadMission
            data={data}
            userResponse={userResponse}
            responseCallback={response => {
              updateMission(response)
              // setVisible(true)
            }}
          />
        )}

        {/* CONTENIDO TIPO BIO */}
        {data.type === "BIO" && (
          <BioMission 
            data={data}
            loader={loader}
            userResponse={userResponse}
            responseCallback={response => {
              updateMission(response)
              // setVisible(true)
            }}
          />
        )}

        {/* CONTENIDO TIPO FORUM */}
        {/* {data.mission.type === "FORUM" && (
          <ForumMission
            advance={data.mission}
            dataForum={dataForum}
            responseCallback={response => updateMission(response)}
          />
        )} */}

        {/* CONTENIDO TIPO TIMER */}
        {data.type === "TIMER" && (
          <TimerMission
            advance={data}
            userResponse={userResponse}
            responseCallback={response => {
              updateMission(response)
              // setVisible(true)
            }}
          />
        )}
      </View>
    </View>
  )
}



export default withLocalStorage(MissionPage);
