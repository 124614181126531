import React, { useState, useRef, useEffect } from "react";
import { Keyboard, TextInput, View, TouchableOpacity, Platform, ActivityIndicator } from 'react-native'
import { KeyboardAccessoryView } from 'react-native-keyboard-accessory'
import { Ionicons } from "@expo/vector-icons";
import { useSafeArea } from "react-native-safe-area-context";


const SafeBottomTextInput = ({ web,  writeCallback, sendCallback, loading = false, input }) => {

  const insets = useSafeArea()
  const [marginBottom, setMarginBottom] = useState(5 + insets.bottom);

  const onKeyboardShow = event => {
    setMarginBottom(5);
  };
  const onKeyboardHide = () => {
    setMarginBottom(insets.bottom);
  };
  const keyboardDidShowListener = useRef();
  const keyboardDidHideListener = useRef();

  useEffect(() => {
    // keyboardDidShowListener.current = Keyboard.addListener(
    //   "keyboardWillShow",
    //   onKeyboardShow
    // );
    // keyboardDidHideListener.current = Keyboard.addListener(
    //   "keyboardWillHide",
    //   onKeyboardHide
    // );
    // return () => {
    //   keyboardDidShowListener.current.remove();
    //   keyboardDidHideListener.current.remove();
    // };
  }, []);

  return (

    <KeyboardAccessoryView
      hideBorder={true} 
      androidAdjustResize={true}
      avoidKeyboard={true}
      alwaysVisible={true}
      bumperHeight={web ? 0 : 20}
    >
      <View style={{ minHeight:40, flex:1, flexDirection: "row", alignItems:"center", marginTop: 5, marginBottom: marginBottom, maxWidth:900, width:"100%", alignSelf:"center", backgroundColor: "white", borderRadius: 7,  marginHorizontal: 10 }}>
        <TextInput
          value={input}
          onChangeText={e => writeCallback(e)}
          multiline
          placeholder="Escribe Aquí..."
          {...Platform.OS !== "web" && { textAlignVertical: "top" }}
          style={{ fontFamily: "Regular", minHeight:40, alignItems:"center", fontSize:16, paddingBottom:10, paddingTop:10, flexGrow:1, paddingLeft: 10 }}
        />
        {loading ?
          <View style={{ width: 50, height: 50, alignContent: "center", justifyContent: "center" }}>
            <ActivityIndicator />
          </View>
          :
          <TouchableOpacity style={{ width: 45, alignItems: "center", justifyContent: "center" }} disabled={input === ""} onPress={() => sendCallback()}>
            <Ionicons
              name="md-send"
              size={27}
              color={input === "" ? "gainsboro" : "gray"}
            />
          </TouchableOpacity>
        }
      </View>
    </KeyboardAccessoryView>
  );
}

export default SafeBottomTextInput;