import React from 'react'


import Header from '../components/AppHeader'

import { createStackNavigator, useHeaderHeight } from '@react-navigation/stack';
import Conversation from '../screens/stackForum/Conversation';
import CreateTopicPage from '../screens/stackForum/CreateTopicPage';
import ForumHome from '../screens/stackForum/ForumHome';
import SingleForum from '../screens/stackForum/SingleForum';
import SingleTopic from '../screens/stackForum/SingleTopic';
import UserList from '../screens/stackForum/UserList';
import { Platform } from 'react-native';
import HeaderWeb from '../components/HeaderWeb';

const Stack = createStackNavigator();


const StackForum = () => {

    return (
        <Stack.Navigator
            headerMode="screen"
            screenOptions={{
                cardStyle: { flex: 1 },
                header: ({ scene, previous, navigation }) => {
                    // console.log("scene", previous)
                    return (
                        Platform.OS === "web" ?
                            <HeaderWeb />
                            :
                            <Header
                                logo={previous ? false : true}
                                backCallback={() => navigation.goBack()}
                                backButton={previous ? true : false}
                                logout={false}
                                editProfile={false}
                                notificationButton={false}
                                searchButton={false}
                            />
                    )
                }
            }}
        >
            <Stack.Screen name="ForumHome" component={ForumHome}/>
            <Stack.Screen name="SingleForum" component={SingleForum}
                // options={{
                //     header: ({ scene, previous, navigation }) => {
                //         // console.log("scene", previous)
                //         return (
                //             <Header
                //                 backWhite
                //                 backCallback={() => navigation.goBack()}
                //                 backButton={true}
                //                 newThread={true}
                //                 style={{ backgroundColor: "#017676" }}
                //             />
                //         )
                //     }
                // }}
            />
            <Stack.Screen name="SingleTopic" component={SingleTopic} />
            {/* <Stack.Screen name="Conversation" component={Conversation}
                options={{
                    header: ({ scene, previous, navigation }) => {
                        let userStr = scene.route && scene.route.params.user.name + " " + scene.route.params.user.surname
                        let userAvatar = scene.route ? scene.route.params.user.image : null
                        
                        return (
                            <Header
                                logo={false}
                                backButton={true}
                                notificationButton={false}
                                searchButton={false}
                                userAvatar={userAvatar}
                                chatTitle={userStr}
                                backCallback={()=> 
                                    // navigation.goBack()
                                    navigation.navigate('ForumHome', {
                                        onGoBack: true,
                                      })
                                    // scene.route && scene.route.params.back
                                }
                                // title={"Conversación con " + usersStr}
                                addUsers={false}
                            />
                        )
                    }
                }}
            /> */}
            {/* <Stack.Screen name="UserList" component={UserList} options={{ header: ({ scene, previous, navigation }) => null }} /> */}
            <Stack.Screen name="CreateTopicPage" component={CreateTopicPage} />
        </Stack.Navigator>
    );
}

export default StackForum;