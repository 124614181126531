import React, { useState, useEffect, useRef, useContext } from "react";
import { View, TouchableHighlight, StyleSheet, Alert, TouchableOpacity, Text, TextInput, ScrollView, Modal, ActivityIndicator } from "react-native";
import { useNavigation } from '@react-navigation/native';
import withLocalStorage from "../../utils/localStore";

import { useSafeArea } from 'react-native-safe-area-context';
import { LinearGradient } from "expo-linear-gradient";
import EditProfileTraining from "./EditProfileTraining";
import CamModal from "../common/CamModal";
import { firestore } from "firebase";
import AuthContext from "../../context/AuthContext";

import { decideColor } from '../../utils/functions'

const BioMission = ({ id, data, responseCallback, userResponse }) => {


  const { userData, customization } = useContext(AuthContext)
  const { navigate } = useNavigation();
  const insets = useSafeArea()

  const scrollRef = useRef()

  const [writing, setWriting] = useState(false)
  const [form, setForm] = useState({});
  const [loading, setLoading] = useState(false)
  const [showCamera, setShowCamera] = useState(false);
  const [image, setImage] = useState(null)


  const editForm = (field, value) => {
    setForm({
      ...form,
      [field]: value
    })
  }


  useEffect(() => {
    userResponse && setForm(userResponse)
  }, [userResponse])


  useEffect(() => {
    writing && scrollRef.current.scrollToEnd()
  }, [writing])



  return (
    <View style={{ flex: 1 }}>
      <ScrollView ref={scrollRef} showsVerticalScrollIndicator={false} contentContainerStyle={{ flexGrow: 1, paddingBottom: !writing ? 40 : 320 }}>

        <View style={{ backgroundColor: customization.mainColor, paddingTop: insets.top + 80, paddingHorizontal: 15, paddingBottom: 160 }} >
          <Text style={{ fontFamily: "Bold", fontSize: 24, color: decideColor(customization.mainColor), textAlign: "center", marginBottom: 10 }}>
            Completa estos datos de tu perfil para conseguir puntos!
          </Text>
        </View>

        <View style={[styles.shadow, { borderRadius: 20, marginHorizontal: 10, backgroundColor: "white", marginTop: -120 }]}>

          <EditProfileTraining
            form={form}
            setForm={setForm}
            disabled={userResponse ? false : true}
            team={data.content.team}
            position={data.content.position}
            bio={data.content.bio}
            hobbies={data.content.hobbies}
            skills={data.content.skills}
            imageProfile={false}
            userImage={image}
            imageCallback={(ev) => setShowCamera(true)}
            writingCallback={(ev) => setWriting(ev)}
          />
        </View>


      </ScrollView>
      {!userResponse &&
        <View style={[, styles.shadow, {
          position: "absolute", top: 0, right: -20, marginBottom: insets.bottom + 10, marginTop: insets.top + 15,
          opacity: form === null ? .5 : 1,
        }]}>
          <TouchableOpacity
            onPress={() => {
              if (
                data.content.team ||
                data.content.position ||
                data.content.bio ||
                data.content.hobbies ||
                data.content.skills) {
                if (
                  data.content.team !== "" ||
                  data.content.position !== "" ||
                  data.content.bio !== "" ||
                  data.content.hobbies !== "" ||
                  data.content.skills !== "") {
                  setLoading(true),
                    firestore().collection("users").doc(userData.id).update({ ...form })
                      .then(res => { responseCallback(form); setLoading(false) })
                      .catch(err => Alert.alert("Lo sentimos mucho, ha ocurrido un error, inténtelo más tarde"))
                } else {
                  Alert.alert("Tienes que completar todos los campos del formulario para continuar la misión")
                }
              }
            }
            }
            disabled={form === null}
            style={{ backgroundColor: "white", height: 50, paddingHorizontal: 35, borderRadius: 25, alignItems: "center", justifyContent: "center" }}>

            {!loading ?
              <Text style={{ fontFamily: "DemiBold", fontSize: 16, color: "black" }}>Guardar Datos</Text>
              :
              <ActivityIndicator styles={{ height: 20, width: 20 }} />
            }
          </TouchableOpacity>
        </View>}


      {/* <Modal transparent={true} visible={showCamera}>
        <View style={{ flex: 1, backgroundColor: "rgba(0,0,0,.7)" }}>
          <View style={{ marginVertical: 50, marginHorizontal: 20, flex: 1, borderRadius: 20, overflow: "hidden" }}>
          </View>
        </View>
      </Modal> */}
    </View>
  );
};

export default BioMission;


const styles = StyleSheet.create({
  input: {
    borderWidth: 1,
    borderColor: "#C6C6C6",
    height: 50,
    backgroundColor: "white",
    borderRadius: 25,
    alignItems: "center",
    paddingHorizontal: 15,
    marginVertical: 5,
    flex: 1
  },
  shadow: {
    shadowColor: "rgba(0, 0, 0, 0.3)",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.4,
    shadowRadius: 3,
    elevation: 10,
    backgroundColor: "white",
    borderRadius: 15
  }
});