import React, { useState, useContext } from "react";
import { View, Text, TouchableOpacity, StyleSheet, ScrollView, ActivityIndicator, Platform } from "react-native";

import moment from "moment";
import HTML from "react-native-render-html";
import ImageRel from "../../components/common/ImageRel";
import * as Animatable from "react-native-animatable";

import { useSafeArea } from "react-native-safe-area-context";
import { LinearGradient } from "expo-linear-gradient";
import { Ionicons } from "@expo/vector-icons";
import * as WebBrowser from "expo-web-browser";
import AuthContext from "../../context/AuthContext";

if (Platform.OS !== "web") {
	MapView = require("react-native-maps").default;
	Marker = require("react-native-maps").Marker;
}

import { decideColor } from "../../utils/functions";

const options = [
	{
		text: "Si",
	},
	{
		text: "Quizás",
	},
	{
		text: "No",
	},
];

const Option = ({ text, callback, userResponse, loader, selected, i }) => {
	const { customization } = useContext(AuthContext);
	const zoomIn = {
		0: {
			scale: 1,
			shadowOpacity: 0,
		},
		1: {
			scale: 1.07,
			shadowOpacity: 0.2,
		},
	};

	const backgroundColor = !userResponse ? (selected === i ? "#f5f5f5" : "transparent") : userResponse === text ? "#f5f5f5" : "#f5f5f5";

	let color = "white";

	if (selected === null || selected !== i) {
		color = decideColor(customization.mainColor);
	} else {
		color = "black";
	}
	if (userResponse) {
		color = "black";
	}

	const borderColor = !userResponse
		? selected === null
			? decideColor(customization.mainColor)
			: decideColor(customization.mainColor)
		: userResponse === text
		? decideColor(customization.mainColor)
		: decideColor(customization.mainColor);

	return (
		<Animatable.View
			duration={100}
			opacity={!userResponse ? (selected === null ? 1 : selected === i ? 1 : 0.4) : userResponse === text ? 1 : 0.6}
			animation={!userResponse ? (selected === null ? null : selected === i ? zoomIn : null) : userResponse === text ? zoomIn : null}
			style={{
				flex: 1,
				shadowColor: "rgba(0, 0, 0, 1)",
				shadowOffset: { width: 0, height: 0 },
				shadowRadius: 5,
				elevation: 10,
			}}
			key={i}>
			<TouchableOpacity
				onPress={() => callback(i)}
				disabled={userResponse ? true : false}
				style={{
					minHeight: 70,
					justifyContent: "center",
					flex: 1,
					alignItems: "center",
					marginHorizontal: 5,
					padding: 10,
					borderWidth: 4,
					borderColor: borderColor,
					backgroundColor: backgroundColor,
					borderRadius: 15,
					// opacity: selected === null ? 1 : selected === i ? 1 : .6
				}}>
				{loader ? (
					<ActivityIndicator size='small' color='white' />
				) : (
					<Text
						style={{
							fontFamily: selected === null ? "Regular" : selected === i ? "DemiBold" : "Regular",
							fontSize: 20,
							color: color,
						}}>
						{text}
					</Text>
				)}
			</TouchableOpacity>
		</Animatable.View>
	);
};

const EventMission = ({ data, responseCallback, userResponse }) => {
	const [selected, setSelected] = useState(null);
	const insets = useSafeArea();
	const { customization } = useContext(AuthContext);
  console.log(data.image)
	return (
		<View style={{ flex: 1 }}>
			<ScrollView contentContainerStyle={{ flexGrow: 1, paddingBottom: 40 }}>
				<View style={{ paddingTop: insets.top + 80, paddingHorizontal: 15, backgroundColor: customization.mainColor, paddingBottom: 160 }}>
					<View style={{ maxWidth: 900, alignSelf: "center", width: "100%" }}>
						<Text style={{ fontFamily: "Bold", fontSize: 24, color: decideColor(customization.mainColor), textAlign: "center", marginBottom: 10 }}>Asistiras a este evento?</Text>
						{data.content.eventType === "Online" && (
							<View style={{ width: "100%", borderRadius: 10, alignItems: "center", justifyContent: "center", height: 220, marginBottom: 10, backgroundColor: "rgba(256,256,256,.3)" }}>
								<ImageRel style={{ width: "97%", height: 210, borderRadius: 10 }} source={data.image} />
							</View>
						)}
						{/* CONFIRMACION DE ASISTENCIA */}
						<View style={{ marginVertical: 10 }}>
							<View style={{ flexDirection: "row" }}>
								{options.map((option, i) => (
									<Option key={i} text={option.text} i={i} userResponse={userResponse} selected={selected} callback={() => setSelected(i)} />
								))}
							</View>
						</View>
					</View>
				</View>

				<View style={{ marginTop: -150 , maxWidth: 900, alignSelf:"center", width: "100%" }}>
					{/* MAPA CON LOCALIZACION */}
					{data.content.eventType === "Offline" && (
						<View style={{ marginHorizontal: 10, marginTop: 0, marginBottom: 20, height: 250, borderRadius: 10, overflow: "hidden" }}>
							{!Platform.OS === "web" && (
								<MapView
									style={{ flex: 1 }}
									region={{
										latitude: data.content.lat,
										longitude: data.content.lng,
										latitudeDelta: 0.0022,
										longitudeDelta: 0.0021,
									}}
									pitchEnabled={false}
									rotateEnabled={false}>
									<Marker
										coordinate={{
											latitude: data.content.lat,
											longitude: data.content.lng,
										}}
									/>
								</MapView>
							)}
						</View>
					)}
					{/* FIN MAPA CON LOCALIZACION */}

					{/* FECHA Y DESCRIPCION */}
					<View style={[styles.shadow, { paddingHorizontal: 20, paddingBottom: 15, paddingTop: 25, backgroundColor: "white", borderRadius: 10, marginHorizontal: 10, marginBottom: 5 }]}>
						<Text style={{ fontFamily: "Regular", color: "gray", marginBottom: 10 }}>Fecha del evento</Text>
						<View
							style={{
								alignItems: "center",
								marginBottom: 20,
								justifyContent: "space-between",
								backgroundColor: "#f5f5f5",
								flexDirection: "row",
								borderRadius: 10,
								paddingVertical: 10,
								paddingHorizontal: 15,
							}}>
							<View style={{ flexDirection: "row", alignItems: "center" }}>
								<Text style={{ fontFamily: "DemiBold", fontSize: 42, textAlign: "center" }}>{moment(data.content.date).format("DD")}</Text>

								<View style={{ marginLeft: 10, borderLeftColor: "gainsboro", borderLeftWidth: 1, paddingLeft: 10 }}>
									<Text style={{ fontSize: 18, fontFamily: "DemiBold" }}>{moment(data.content.date).format("MMMM YYYY")}</Text>
									<Text style={{ fontSize: 18, fontFamily: "DemiBold" }}>{moment(data.content.date).format("HH:MM A")}</Text>
								</View>
							</View>
							<Ionicons name='ios-calendar' size={40} />
						</View>

						{data.content.eventType === "Offline" && (
							<View>
								<Text style={{ fontFamily: "Regular", color: "gray", marginBottom: 10 }}>Lugar del evento</Text>
								<View style={{ alignItems: "center", marginBottom: 20, backgroundColor: "#f5f5f5", flexDirection: "row", borderRadius: 10, paddingVertical: 10, paddingHorizontal: 15 }}>
									<Text style={{ fontFamily: "DemiBold", fontSize: 18 }}>{data.content.place}</Text>
								</View>
							</View>
						)}

						<Text style={{ fontFamily: "Regular", color: "gray", marginBottom: 10 }}>Descripción del evento</Text>

						<View style={{ alignItems: "center", marginBottom: 20, backgroundColor: "#f5f5f5", flexDirection: "row", borderRadius: 10, paddingVertical: 10, paddingHorizontal: 15 }}>
							{Platform.OS === "web"? 
                <div dangerouslySetInnerHTML={{__html: data.content.description}}/>
              :<HTML
								html={data.description}
								baseFontStyle={{ fontFamily: "Regular", fontSize: 16 }}
								onLinkPress={async (evt, href) => await WebBrowser.openBrowserAsync(href.toLowerCase().includes("://") ? href : `https://${href}`)}
							/>}
						</View>
					</View>
					{/* FIN FECHA Y DESCRIPCION */}
				</View>
			</ScrollView>
			{!userResponse && (
				<View style={{ position: "absolute", top: 0, right: -20, marginBottom: insets.bottom + 10, marginTop: insets.top + 15, opacity: selected === null ? 0.5 : 1 }}>
					<TouchableOpacity
						onPress={() => responseCallback(selected === 0 ? "Si" : selected === 1 ? "Quizás" : "No")}
						disabled={selected === null}
						style={{ backgroundColor: "white", height: 50, paddingHorizontal: 35, borderRadius: 25, alignItems: "center", justifyContent: "center" }}>
						<Text
							style={{
								fontFamily: "DemiBold",
								fontSize: 16,
								color: "black",
							}}>
							Envíar
						</Text>
					</TouchableOpacity>
				</View>
			)}
		</View>
	);
};

export default EventMission;

const styles = StyleSheet.create({
	shadow: {
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 0,
		},
		shadowOpacity: 0.15,
		shadowRadius: 7,

		elevation: 12,
	},
});

{
	/* <TouchableOpacity
              onPress={() => setSelected(1)}
              disabled={data.userResponse ? true : false}
              style={[styles.shadow, {
                marginHorizontal: 5,
                height: 65,
                flex: 1,
                borderRadius: 10,
                justifyContent: "center",
                alignItems: "center",
                paddingHorizontal: 10,
                marginVertical: 5,
                borderWidth: 5,
                borderColor: !data.userResponse
                  ? "white"
                  : data.userResponse === 1
                    ? "#26A313"
                    : "white",
                backgroundColor: "white"
              }]}
            >
              <Text style={{ fontFamily: "DemiBold", fontSize: 24 }}>Si</Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => setSelected(2)}
              disabled={data.userResponse ? true : false}
              style={[styles.shadow, {
                marginHorizontal: 5,
                height: 65,
                flex: 1,
                borderRadius: 10,
                justifyContent: "center",
                alignItems: "center",
                paddingHorizontal: 10,
                marginVertical: 5,
                borderColor: !data.userResponse
                  ? "white"
                  : data.userResponse === 1
                    ? "#26A313"
                    : "white",
                backgroundColor: "white"
              }]}
            >
              <Text style={{ fontFamily: "DemiBold", fontSize: 24 }}>Quizas</Text>
            </TouchableOpacity>
            <TouchableOpacity
              onPress={() => setSelected(3)}
              disabled={data.userResponse ? true : false}
              style={[styles.shadow, {
                marginHorizontal: 5,
                height: 65,
                flex: 1,
                borderRadius: 10,
                justifyContent: "center",
                alignItems: "center",
                paddingHorizontal: 10,
                marginVertical: 5,
                borderColor: !data.userResponse
                  ? "white"
                  : data.userResponse === 1
                    ? "#26A313"
                    : "white",
                backgroundColor: "white"
              }]}
            >
              <Text style={{ fontFamily: "DemiBold", fontSize: 24 }}>No</Text>
            </TouchableOpacity> */
}
