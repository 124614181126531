import React, { useState } from "react";
import {
  View,
  Text,
  ImageBackground,
  StyleSheet,
  SafeAreaView,
  TouchableHighlight,
  TouchableOpacity,
  ScrollView,
  ActivityIndicator,
  RefreshControl,
  AsyncStorage
} from "react-native";
import * as Notifications from 'expo-notifications'
import Collapsible from "react-native-collapsible";

import Header from '../../components/AppHeader'
import { Ionicons } from "@expo/vector-icons";

import URL from "../../constants/Servers";
import Element from "../../constants/Elements";

import { useSafeArea } from "react-native-safe-area-context"

const goReto = async (reto, avance, navigate) => {
  if (avance) {
    navigate("Reto", {
      challengeavid: avance
    });
  } else {
    navigate("ChallengePage", { challengeid: reto.id });
  }
};

const completadoBTN = (challenge, navigate) => (

  <TouchableHighlight
    onPress={() => navigate("ChallengePage", { challengeid: challenge.id })}
    style={{
      width: 90,
      height: 35,
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#005500",
      borderRadius: 25
    }}
  >
    <Text
      style={{
        color: "white",
        fontFamily: "Regular",
        fontSize: 12
      }}
    >
      Completado
        </Text>
  </TouchableHighlight>
);

const activoBTN = (challenge, navigate) => (
  <TouchableHighlight
    style={{
      width: 90,
      height: 35,
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#64C500",
      borderRadius: 25
    }}
    onPress={() => navigate("ChallengePage", { challengeid: challenge.id })}
  >
    <Text
      style={{
        color: "white",
        fontFamily: "Regular",
        fontSize: 12
      }}
    >
      Activo
      </Text>
  </TouchableHighlight>
);

const verDetallesBTN = (challenge, navigate) => (
  <View style={{ flexDirection: "row" }}>
    <TouchableHighlight
      style={{
        width: 90,
        height: 35,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#153388",
        borderRadius: 25,
      }}
      onPress={() => navigate("ChallengePage", { challengeid: challenge.id })}>

      <Text
        style={{
          color: "white",
          fontFamily: "Regular",
          fontSize: 12
        }}
      >
        Ver Detalles
        </Text>

    </TouchableHighlight>
  </View>
);

const esperandoBTN = (challenge, navigate) => (
  <TouchableHighlight
    style={{
      height: 35,
      paddingHorizontal: 15,
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "gray",
      borderRadius: 25
    }}
    onPress={() => navigate("ChallengePage", { challengeid: challenge.id })}>
    <Text
      style={{
        color: "white",
        fontFamily: "Regular",
        fontSize: 12
      }}
    >
      Esperando Inicio
        </Text>
  </TouchableHighlight>
);

const fallidoBTN = (challenge, navigate) => (
  <TouchableHighlight onPress={() => navigate("ChallengePage", { challengeid: challenge.id })}>
    <View
      style={{
        width: 90,
        height: 35,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "red",
        borderRadius: 25
      }}
    >
      <Text
        style={{
          color: "white",
          fontFamily: "Regular",
          fontSize: 12
        }}
      >
        Fallido{" "}
      </Text>
    </View>
  </TouchableHighlight>
);

const onButtonPress = (i, retosToggle, setRetosToggle) => {
  setRetosToggle(retosToggle !== i ? i : -1);
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center"
  },
  shadow: {
    shadowColor: "black",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.3,
    shadowRadius: 10,
    elevation: 5,
    backgroundColor: "transparent"
  }
});

const ChallengesHome = ({ id, navigation }) => {

  const [retosToggle, setRetosToggle] = useState(-1);
  const [refreshing, refetching] = useState(true);
  const data = []
  const loading = false
  const refetch = null
  const networkStatus = null

  // const { loading, error, data, refetch, networkStatus } = useQuery(
  //   queries.GetEntrenamiento,
  //   {
  //     variables: { id },
  //     notifyOnNetworkStatusChange: false,
  //     onCompleted: () => refetching(false),
  //   }
  // );

  // Notifications.addListener(
  //   (notification) => {
  //     if (notification.data.avid) {
  //       refetch()
  //     }
  //   }
  // );

  const insets = useSafeArea()


  if (loading) return <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}><ActivityIndicator size="large" /></View>;
  if (error) return <Text>Error :(</Text>;

  return (
    <ScrollView
      alwaysBounceVertical={true}
      bouces={true}
      contentContainerStyle={{ maxWidth: 900, flexGrow: 1 }}
      refreshControl={
        <RefreshControl refreshing={refreshing} onRefresh={() => refetch()} />
      }
    >

      <ImageBackground style={{ flexGrow: 1, paddingHorizontal: 15, paddingTop: 20, paddingBottom: insets.bottom + 40 }} imageStyle={{ resizeMode: "cover" }}>
        {/* <NavigationEvents onWillFocus={payload => refetch()} /> */}

        {/* SECCION DE TEXTO */}

        <View style={{ padding: 15, width: "100%" }}>
          <Text style={{ fontSize: 24, textAlign: "left", fontFamily: "DemiBold" }}>
            Comienza tu entrenamiento{" "}
          </Text>
          <Text style={{ fontSize: 16, textAlign: "left", fontFamily: "DemiBold" }}>
            ¿En qué quieres superarte hoy? Comienza nuevos retos y planes de
            entrenamiento
          </Text>
        </View>

        {/* <View style={{ flex: 1 }}> */}
        {/* SECCION DE RETOS */}
        {data.challengeGroups &&
          data.challengeGroups.map((itinerary, i) => {
            return (
              <View
                key={i}
                style={{
                  marginTop: 10,
                  marginBottom: 25,
                }}
              >
                <TouchableOpacity
                  onPress={() =>
                    onButtonPress(i, retosToggle, setRetosToggle)
                  }
                  style={[
                    styles.shadow,
                    {
                      elevation: 15,
                      height: 90,
                      zIndex: 6,
                      borderRadius: 15,
                      backgroundColor: "transparent"
                    }
                  ]}
                >
                  <ImageBackground
                    imageStyle={{ borderRadius: 15, backgroundColor: "white" }}
                    source={{
                      uri:
                        itinerary.image !== null &&
                          itinerary.image.url !== null
                          ? itinerary.image.url
                          : Element.placeholder
                    }}
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      height: 90,
                    }}>
                    <View style={{ backgroundColor: "black", opacity: .6, borderRadius: 15, top: 0, bottom: 0, left: 0, right: 0, position: "absolute" }} />
                    <Ionicons
                      name={`ios-${itinerary.icon}`}
                      size={60}
                      color="white"
                      style={{ marginLeft: 15 }}
                    />
                    <Text
                      style={{
                        color: "white",
                        fontFamily: "DemiBold",
                        fontSize: 20,
                        paddingLeft: 15,
                        flex: 1,
                        paddingRight: 15
                      }}
                    >
                      {itinerary.name}
                    </Text>
                  </ImageBackground>
                </TouchableOpacity>
                <View style={[
                  styles.shadow,
                  { backgroundColor: "white", zIndex: 5, elevation: 6, marginTop: -20, borderBottomLeftRadius: 15, borderBottomRightRadius: 15, }
                ]}>
                  <Collapsible
                    style={{ flex: 1, padding: 15, paddingTop: 40 }}
                    collapsed={retosToggle === i ? false : true}
                  >
                    <View>
                      <Text style={{ fontSize: 16, fontFamily: 'Regular', color: 'black', paddingBottom: itinerary.description ? 15 : 0, zIndex: 2 }}>
                        {itinerary.description && itinerary.description}
                      </Text>
                    </View>
                    {itinerary.challenges &&
                      itinerary.challenges.map((challenge, j) => {
                        return (
                          <View
                            key={j}
                            style={{
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                              paddingBottom: 10,
                              paddingTop: 10,
                              zIndex: 2,
                              borderBottomColor: "gainsboro",
                              borderBottomWidth: 0
                            }}
                          >
                            <TouchableHighlight>
                              <View
                                style={{
                                  flexDirection: "row",
                                  alignItems: "center"
                                }}
                              >
                                <Text
                                  style={{
                                    marginRight: 10,
                                    fontSize: 16,
                                    alignItems: "center",
                                    justifyContent: "center",
                                    fontFamily: "DemiBold"
                                  }}
                                >
                                  {challenge.name}
                                </Text>
                                {/* <Text>
                                  {challenge.amount * challenge.often <= 1
                                    ? challenge.amount * challenge.often +
                                    " día"
                                    : challenge.amount * challenge.often +
                                    " días"}
                                </Text> */}
                              </View>
                            </TouchableHighlight>
                            {challenge.challengeavs[0]
                              ? challenge.challengeavs[0].state === "CANCELED"
                                ? verDetallesBTN(challenge, navigation.navigate)
                                : challenge.challengeavs[0].state === "FAILED"
                                  ? fallidoBTN(challenge, navigation.navigate)
                                  : challenge.challengeavs[0].state ===
                                    "COMPLETED"
                                    ? completadoBTN(challenge, navigation.navigate)
                                    : challenge.challengeavs[0].state ===
                                      "INITIATED"
                                      ? activoBTN(challenge, navigation.navigate)
                                      : challenge.challengeavs[0].state ===
                                      "ACTIVE" &&
                                      esperandoBTN(challenge, navigation.navigate)
                              : verDetallesBTN(challenge, navigation.navigate)}
                          </View>
                        );
                      })}
                  </Collapsible>
                </View>
              </View>
            );
          })}
        {/* </View> */}
      </ImageBackground>
    </ScrollView>
  );
};

export default ChallengesHome;