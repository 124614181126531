import React, { useContext } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, TextInput } from 'react-native'
import Button from '../common/Button'

import AuthContext from '../../context/AuthContext'
import { Ionicons } from '@expo/vector-icons';

const ValorationForm = ({ disabled = false, template = [], form = {}, setForm, submitCallback, completed, open, toogleCallback }) => {

    const { customization } = useContext(AuthContext)
    return (
        <View style={[styles.shadow, { backgroundColor: "white", borderRadius: 10, width: "100%", marginTop: 20 }]}>

            <TouchableOpacity disabled={completed} onPress={() => toogleCallback()} style={{ flexDirection: "row", backgroundColor: "black", alignItems: "center", justifyContent: "space-between", paddingHorizontal: 15, paddingVertical: 10, borderRadius: 7 }}>
                <Text style={{ fontFamily: "DemiBold", fontSize: 18, color: "white" }}>{template.name}</Text>
                <View style={{ alignItems: "center" }}>
                    {completed ?
                        <Ionicons color="white" size={30} name="md-checkmark-circle-outline" />
                        :
                        <Ionicons color="white" size={30} name={open ? "md-remove" : "md-add"} />
                    }
                </View>
            </TouchableOpacity>

            <View style={{ height: open ? "auto" : 0, overflow: "hidden", paddingHorizontal: 20, paddingBottom: !open ? 0 : 20 }}>
                {template.questions && template.questions.map((e, i) => {
                    const question = i
                    return (
                        <View key={i} style={{ marginTop: 10 }}>
                            {e.type === "stars" ?
                                <View>
                                    <Text style={{ fontFamily: "DemiBold", fontSize: 16, marginVertical: 20 }}>{e.question}</Text>
                                    <View style={{ flexDirection: "row", width: "100%", flexWrap: "wrap", justifyContent: "center" }}>
                                        {Array.from({ length: e.max }).map((q, i) => {
                                            return <StarButton disabled={disabled} key={i} checked={form[question] >= i + 1} onPress={() => setForm({ ...form, [question]: i + 1 })} />
                                        })}
                                    </View>
                                </View>
                                :
                                e.type === "options" ?
                                    <View>
                                        <Text style={{ fontFamily: "DemiBold", fontSize: 16, marginVertical: 20 }}>{e.question}</Text>
                                        <View style={{ flexDirection: "row", justifyContent: "center", flexWrap: "wrap" }}>
                                            {e.options.map((o, i) => <LabelButton disabled={disabled} key={i} selected={form[question] === o} onPress={() => setForm({ ...form, [question]: o })} label={o} />)}
                                        </View>
                                    </View>
                                    :
                                    <View>
                                        <Text style={{ fontFamily: "DemiBold", fontSize: 16, marginVertical: 20 }}>{e.question}</Text>
                                        <View style={{ flexDirection: "row", width: "100%", justifyContent: "center" }}>
                                            {disabled ?
                                                <View style={{width:"100%"}}>
                                                    <Text style={{ fontFamily: "Regular", textAlign: "left" }}>{form[question]}</Text>
                                                </View>
                                                :
                                                <TextInput value={form[question]} onChangeText={(ev) => setForm({ ...form, [question]: ev })} placeholder="Comentario..." multiline style={{ borderWidth: 2, borderRadius: 7, borderColor: "gainsboro", width: "100%", minHeight: 150, padding: 10, paddingTop: 10 }} />
                                            }
                                        </View>
                                    </View>
                            }
                        </View>
                    )
                })}

                {!disabled && <View style={{ marginHorizontal: 15, marginTop: 30, alignItems: "center" }}>
                    <Button onPress={submitCallback} label="Valorar" color={customization.mainColor} round={25} />
                </View>}
            </View>
        </View>
    )
}

export default ValorationForm;


const StarButton = ({ disabled, checked, onPress }) => {
    return (
        <TouchableOpacity disabled={disabled} onPress={onPress} style={{ marginHorizontal: 5 }}>
            <Ionicons name="md-star" color={checked ? "orange" : "gainsboro"} size={55} />
        </TouchableOpacity>
    )
}

const LabelButton = ({ disabled, label, onPress, selected }) => {
    return (
        <TouchableOpacity disabled={disabled} onPress={onPress} style={{ backgroundColor: selected ? "black" : "transparent", borderRadius: 7, paddingHorizontal: 10, paddingVertical: 10, borderWidth: 2, margin: 5, borderColor: selected ? "black" : "gainsboro" }}>
            <Text style={{ color: selected ? "white" : "black", fontFamily: "DemiBold", fontSize: 16 }}>{label}</Text>
        </TouchableOpacity>
    )
}

const styles = StyleSheet.create({
    shadow: {
        shadowColor: "rgba(0, 0, 0, 0.3)",
        shadowOffset: { width: 0, height: 5 },
        shadowOpacity: 0.4,
        shadowRadius: 10,
        elevation: 10,
    }
});
