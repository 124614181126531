import React, { useState, useEffect, useRef, useContext } from "react";
import { View, Text, ImageBackground, StyleSheet, TouchableOpacity, Dimensions, Button } from "react-native";

import LinearBarIndicator from "../components/LinearBarIndicator";
import URL from "../constants/Servers";
import Element from "../constants/Elements";
import AuthContext from '../context/AuthContext'

import * as Localization from "expo-localization";
import i18n from "i18n-js";
import translations from "../locale/translations";
import { Ionicons } from "@expo/vector-icons";
import moment from "moment";

import HTML from "react-native-render-html";
import { useNavigation } from "@react-navigation/native";

i18n.translations = translations;
// Set the locale once at the beginning of your app.
i18n.locale = Localization.locale;

i18n.fallbacks = true;

const screenWidth = Dimensions.get("window").width;

const CardActiveCourse = ({ name, clickCallback, image, data, description, visible, initDate, endDate, forumID }) => {


	const { customization } = useContext(AuthContext);
	const placeholder = Element("placeholder");
	const { navigate } = useNavigation();

	
	const [totalContents, setTotalContents] = useState(data.reduce((totalContents, section) => (totalContents === undefined ? 0 : totalContents) + section.contents.length, 0));
	const [completed, setCompleted] = useState(data.reduce((total, section) => (total === undefined ? 0 : total) + section.contents.filter((content) => content.status === "COMPLETED").length, 0));
	const [percentage, setPercentage] = useState((completed / totalContents) * 100);
	useEffect(() => {
		const com = data.reduce((total, section) => (total === undefined ? 0 : total) + section.contents.filter((content) => content.status === "COMPLETED").length, 0);
		setCompleted(com);
		setPercentage((com / totalContents) * 100);
	}, [data]);

	return (
		<View
			style={{
				height: 170,
				width: "95%",
				marginBottom: 30,
				maxWidth: 800,
				backgroundColor: "white",
				alignSelf: "center",
				paddingTop: 20,
				// shadowColor: "#000", shadowOffset: { width: 0, height: 3, }, shadowOpacity: 0.29, shadowRadius: 4.65,
				borderRadius: 15,
				borderBottomLeftRadius: 15,
				borderBottomRightRadius: 15,
			}}>
			<TouchableOpacity disabled={initDate ? !moment(moment()).isAfter(initDate) : false} onPress={() => clickCallback()} style={{ flex: 1, padding: 15, paddingVertical: 20 }}>
				<View style={{ flex: 1, flexDirection: "row" }}>
					<ImageBackground
						source={{ uri: image === null ? placeholder : image.includes("http") ? image : URL.URL_API + image }}
						imageStyle={{ backgroundColor: "gainsboro", borderRadius: 15 }}
						style={[styles.shadow, { position: "absolute", left: -20, bottom: -40, alignSelf: "center", borderRadius: 15, width: 120, height: 150, alignItems: "center", justifyContent: "center" }]}>
						{initDate && !moment(moment()).isAfter(initDate) && <View style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0, backgroundColor: "rgba(0,0,0,.6)", borderRadius: 15 }} />}
						{initDate && !moment(moment()).isAfter(initDate) && <Ionicons style={{ zIndex: 3 }} name={"md-lock-closed"} size={40} color={"white"} />}
					</ImageBackground>
					<View style={{ flex: 1, marginLeft: 120, justifyContent: "space-between", paddingRight: 15 }}>
						{initDate &&
							(moment(moment()).isAfter(initDate) ? (
								<Text style={{ color: "black", fontSize: 14, fontFamily: "Regular", textAlign: "right" }}>
									{completed} de {totalContents} {i18n.t("completed")}
								</Text>
							) : (
								<Text style={{ color: "black", fontSize: 14, fontFamily: "Regular", textAlign: "right" }}>Disponible el {moment(initDate).format("DD MMMM [a las] hh:mm")}</Text>
							))}
						<Text style={{ color: "black", fontSize: 20, fontFamily: "DemiBold" }}>{name}</Text>
						<View style={{height:86, overflow:"hidden" }}>
						<HTML
							baseFontStyle={{ fontFamily: "Regular", fontSize: 18, color: "#303030", }}
							html={description ? description.slice(0,130)+ "..." : "<p></p>"}
							imagesMaxWidth={800}
							staticContentMaxWidth={800}
							onLinkPress={async (evt, href) => await WebBrowser.openBrowserAsync(href.toLowerCase().includes("://") ? href : `https://${href}`)}
						/>
						</View>
					</View>
						{/* <TouchableOpacity 
							onPress={() => console.log('button crear hilo')}
							style={{backgroundColor:"gainsboro", width:"30%", height:"30%", alignItems:"center", justifyContent:"center"}}
						>
							<Text style={{fontFamily:"Regular", fontSize:18, color:"black"}} >Crear Hilo</Text>
						</TouchableOpacity> */}
                        {/* <TouchableOpacity
                            style={{
								alignSelf:"flex-end",
                                width: "auto",
                                flexDirection: "row",
                                paddingRight: 10,
                                height: 50,
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                            onPress={() => navigate("CreateTopicPage", { id: forumID, name })}
                        >
                            <Text style={{ fontFamily: "DemiBold", fontSize: 18, color: customization.headerElementsColor}}>Nuevo Hilo</Text>
                            <Ionicons style={{ marginLeft: 10 }} name="md-add-circle" size={30} color={customization.headerElementsColor}/>
                        </TouchableOpacity> */}
				</View>
			</TouchableOpacity>
			<LinearBarIndicator backgroundColor="gainsboro" width={"95%"} rounded={8} height={15} gradient={["#B4EC51", "#429321"]} percentage={percentage} styles={{ position: "absolute", left: "2.5%", top: 10 }} />
		</View>
	);
};

export default CardActiveCourse;

const styles = StyleSheet.create({
	shadow: {
		shadowColor: "rgba(0, 0, 0, 1)",
		shadowOffset: { width: 0, height: 7 },
		shadowOpacity: 0.1,
		shadowRadius: 10,
		elevation: 10,
	},
});
