import React, { useContext } from "react";
import { Image, Text, View, TouchableOpacity, Dimensions, TouchableWithoutFeedback, Alert } from "react-native";
import ImageRel from "../common/ImageRel";
import Svg, { G, Path, Line as LineSvg, Circle } from "react-native-svg";
import * as Animatable from "react-native-animatable";
import AuthContext from "../../context/AuthContext";

const SCREEN_WIDTH = Math.round(Dimensions.get("window").width);

const MissionComponent = ({ index, mission, itineraryav, setMission, disabled, navigation, pathWidth, style }) => {
	const { customization } = useContext(AuthContext);

	const anterior = itineraryav.advance[index - 1] ? itineraryav.advance[index - 1].userResponse : undefined;

	const current = itineraryav.advance.filter((ev) => ev.userResponse).length;

	const grow = {
		0: {
			scale: 1,
		},
		1: {
			scale: 1.3,
		},
	};

	return (
		<TouchableOpacity
			disabled={disabled ? disabled : !mission.userResponse && index !== 0 && !anterior}
			onPress={() => {
				if (!mission.userResponse) {
					if (index !== 0) {
						if (anterior) {
							setMission({
								missionID: mission.id,
								itineraryavID: itineraryav.id,
								missionType: mission.type,
							});
						} else {
							Alert.alert("😢 ¡Lo siento!", "Debes completar las misiones anteriores, para poder acceder a esta", [{ text: "OK" }], { cancelable: false });
						}
					} else {
						setMission({
							missionID: mission.id,
							itineraryavID: itineraryav.id,
							missionType: mission.type,
						});
					}
				} else {
					navigation.navigate("Mission", {
						id: mission.id,
						itineraryavID: itineraryav.id,
						missionType: mission.type,
					});
				}
			}}
			style={{
				flex: 1,
				opacity: disabled ? 0.2 : 1,
				elevation: index,
				zIndex: index,
        position:"relative",
        marginTop:-10
			}}>
			<View style={{ height: 170 }}>
				{/* SVG FONDO ABSOLUTE */}
				<View style={{ position: "absolute", height: 170,  width: "100%", transform: [{ rotateY: index % 2 === 1 ? "180deg" : "0deg" }] }}>
					{/* <Svg width="100%" height="170px" >
            <Path d={`M 85 5 L ${SCREEN_WIDTH - 100} 5`} stroke={!mission.userResponse && index !== 0 && !anterior ? "gainsboro" : disabled ? "gainsboro" : customization.mainColor} strokeDasharray={"1 17"} strokeLinecap="round" strokeWidth="10" fill="none" />
            <Path d="M 85 5 a 40 40 0 1 0 0 160" stroke={!mission.userResponse && index !== 0 && !anterior ? "gainsboro" : disabled ? "gainsboro" : customization.mainColor} strokeDasharray={"1 17"} strokeLinecap="round" strokeWidth="10" fill="none" />
          </Svg> */}

					<Svg width="100%" height="170px" style={{ position: "absolute" }}>
						<LineSvg x1="85" y1="5" x2={pathWidth - 90} y2="5" stroke={!mission.userResponse && index !== 0 && !anterior ? "gainsboro" : disabled ? "gainsboro" : customization.mainColor} strokeDasharray={"1 17"} strokeLinecap="round" strokeWidth="10" fill="none" />
						<Svg width="85" height="100%">
							<Circle cx="85" cy="85" r="80" stroke={!mission.userResponse && index !== 0 && !anterior ? "gainsboro" : disabled ? "gainsboro" : customization.mainColor} strokeDasharray={"1 17"} strokeLinecap="round" strokeWidth="10" fill="none" />
						</Svg>
					</Svg>

				</View>

				{/* CONTENIDO DEL RETO */}
				<View
					style={{
						height: 170,
						width: "100%",
						paddingHorizontal: 40,
						justifyContent: "space-between",
						alignItems: "center",
						flexDirection: index % 2 === 1 ? "row-reverse" : "row",
					}}>
					<Animatable.View
						animation={current === index ? (disabled ? null : grow) : null}
						easing="ease-out"
						iterationCount="infinite"
						direction="alternate"
						duration={1000}
						style={{
							backgroundColor: "gainsboro",
							borderRadius: 50,
							width: 100,
							height: 100,
							alignItems: "center",
							justifyContent: "center",
							marginRight: index % 2 == 1 ? 0 : 15,
							marginLeft: index % 2 == 1 ? 15 : 0,
						}}>
						<ImageRel
							style={{
								width: 90,
								height: 90,
								borderRadius: 45,
								// resizeMode: "cover",
								opacity: mission.userResponse ? 0.6 : 1.0,
							}}
							resizeMode="cover"
							source={mission.image}
						/>
					</Animatable.View>

					<View style={{ flex: 1 }}>
						<Text numberOfLines={4} style={{ fontSize: 16, fontFamily: "DemiBold" }}>
							{mission.title}
						</Text>
						<Text style={{ color: "gray", marginTop: 10 }}>+{mission.score} Puntos</Text>
					</View>
				</View>
			</View>
		</TouchableOpacity>
	);
};

export default MissionComponent;
