import React, { useState, useEffect, useRef, useContext } from "react";
import {
  Text,
  View,
  ActivityIndicator,
  TouchableOpacity,
  ScrollView
} from "react-native";
import _ from 'lodash'
import { useSafeArea } from 'react-native-safe-area-context';


import CamModal from "../common/CamModal";
import { Ionicons } from "@expo/vector-icons";
import { Video } from "expo-av";
import moment from "moment"

import { storage, firestore } from 'firebase'
import AuthContext from "../../context/AuthContext";
import { decideColor } from "../../utils/functions"
import Modal from '../common/Modal'

const UploadMission = ({ data, responseCallback, userResponse, id }) => {

  const { userData, customization } = useContext(AuthContext)

  // const [userVideo, setUserVideo] = useState(_.get(advance, "userResponse"));
  const [showCamera, setShowCamera] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [video, setVideo] = useState(null)

  const insets = useSafeArea();

  useEffect(() => {
    userResponse && setVideo(userResponse)
  })

  const handleUpload = async (media) => {
    setUploading(true)
    let result = await fetch(media.uri)
    const blob = await result.blob();
    var storageRef = storage().ref().child(`challenges/videos/user-${userData.id}-${moment().format()}`)
    const data = storageRef.put(blob)
      .then((snapshot) => responseCallback(snapshot.ref.fullPath))
      .catch(err => err);
  };

  return (
    <View style={{ flex: 1, width: "100%"}}>
      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ flexGrow: 1, paddingBottom: 40 }}>

        <View style={{ backgroundColor: customization.mainColor, paddingTop: insets.top + 100, paddingHorizontal: 15, paddingBottom: 160 }} >

          <Text style={{ fontFamily: "Bold", fontSize: 24, color: decideColor(customization.mainColor), textAlign: "center", marginBottom: 20 }}>
            Sube el siguiente contenido
        </Text>
          <Text style={{ fontFamily: "Regular", textAlign: "center", fontSize: 16, color: decideColor(customization.mainColor), marginBottom: 20 }}>
            {data.content.description}
          </Text>
        </View>

        <View style={{ alignItems: "center", marginTop: -140 }}>
          <TouchableOpacity disabled={userResponse ? true : false} onPress={() => setShowCamera(true)}>
            <View style={{ height: 250, backgroundColor: "gray", width: 250, borderRadius: 125, marginBottom: 20, overflow: "hidden", alignItems: "center", justifyContent: "center" }}>
              {video && <Video source={{ uri: video.uri ? video.uri : video.url }} isMuted={true} style={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0 }} rate={1} shouldPlay={true} isLooping={true} muted={true} resizeMode="cover" />}
              <View style={{ position: "absolute", top: 0, bottom: 0, left: 0, right: 0, backgroundColor: "rgba(0,0,0,.5)" }} />
              <Ionicons name={video ? "ios-checkmark-circle" : "ios-camera"} color="white" size={80} />
            </View>
          </TouchableOpacity>
        </View>
      </ScrollView>
      {/* 

      <Text style={{ fontFamily: "DemiBold", fontSize: 22, color: "white", textAlign: "center", marginVertical: 25 }}>
        {advance.content.text}
      </Text>
      <View style={{ alignItems: "center" }}>

      
      </View>

      {!advance.userResponse && (
        <TouchableOpacity
          onPress={() => setShowCamera(true)}
          style={{ height: 50, backgroundColor: "#017676", borderRadius: 25, width: 250, alignItems: "center", justifyContent: "center" }}
        >
          <Text style={{ fontFamily: "DemiBold", color: "white", fontSize: 16 }}>
            {userVideo
              ? "Grabar de nuevo"
              : "Nuevo video"}
          </Text>
        </TouchableOpacity>
      )}

      {userVideo && !advance.userResponse && (
        <TouchableHighlight
          style={{ height: 50, borderColor: "#017676", borderWidth: 3, borderRadius: 25, marginTop: 20, width: 250, alignItems: "center", justifyContent: "center" }}
          onPress={() => { update({ ...userVideo, type: "Video" }) }}
        >
          {loading ?
            < ActivityIndicator color="white" />
            :
            <Text style={{ fontFamily: "DemiBold", color: "#017676", fontSize: 16 }}>Completar Reto</Text>
          }
        </TouchableHighlight>
      )} */}

      {!userResponse &&
        <View style={{
          position: "absolute", top: 0, right: -20, marginBottom: insets.bottom + 10, marginTop: insets.top + 15,
          opacity: video === null ? .5 : 1,
        }}>
          <TouchableOpacity
            onPress={() => handleUpload(video)}
            disabled={video === null}
            style={{ backgroundColor: "white", height: 50, paddingHorizontal: 35, borderRadius: 25, alignItems: "center", justifyContent: "center" }}>
            {uploading ?
              <ActivityIndicator />
              :
              <Text style={{
                fontFamily: "DemiBold",
                fontSize: 16,
                color: "black"
              }}>
                Enviar Medios
            </Text>
            }
          </TouchableOpacity>
        </View>}


      {showCamera &&
        <Modal >
          <CamModal maxDuration={data.content.seconds} AV={"Video"} initialCamera={"front"} closeCallback={() => setShowCamera(false)} response={ev => setVideo(ev)} />
        </Modal>
      }
    </View>
  );
};

export default UploadMission;
