import React from "react";
import { useContext, useEffect, useState } from "react";
import { ActivityIndicator, View } from "react-native";
import AuthContext from "../context/AuthContext";

const ScormContainer = ({ scormCallback, loadCallback, data = {}, initRoute, height = 700, width = "100%" }) => {
	const { userData } = useContext(AuthContext);
	const [loaded, setLoaded] = useState(false)
	const d = {
		// interactions: [{ id: 1, time: "0000:00:99.99", type: "asd", correct_responses: 5, student_response: "o" },{ id: 2, time: "0000:00:99.99", type: "asd", correct_responses: 5, student_response: "o" }],
		lesson_status: data?.core?.lesson_status || "incomplete",
		points: data?.core?.score?.raw || 0,
		student_name: userData.name,
		session_time: data?.core?.session_time || "",
		entry: "resume",
		launch_data: data.suspend_data || "",
		suspend_data: data.suspend_data || ""
	};
	useEffect(() => {
		window.addEventListener("message", function (event) {
			scormCallback(event.data);
		});
	}, []);

	// console.log(initRoute)

	if (initRoute) {
		return <iframe onLoadedData={() => console.log("MECAGOENLAPUTA")} id="sco" type="text/html" style={{ border: 0, margin: 0 }} height={height} width={width} onLoad={loadCallback} src={initRoute.replace("test", userData.id) + "&params=" + encodeURIComponent(JSON.stringify(d))} />;
	} else {
		return (
			<View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
				<ActivityIndicator />
			</View>
		);
	}
};

export default ScormContainer;
