import { Platform } from 'react-native'
import React from 'react'
import Header from '../components/AppHeader'

import { createStackNavigator } from '@react-navigation/stack';
const Stack = createStackNavigator();

import ProfileHome from "../screens/stackProfile/ProfileHome";
import EditProfile from "../screens/stackProfile/EditProfile";
import MyReviews from "../screens/stackProfile/MyReviews";
import HeaderWeb from '../components/HeaderWeb';

const StackProfile = () => {
    return (
        <Stack.Navigator
            headerMode="screen"
            screenOptions={{
                cardStyle: { flex: 1 },
                header: ({ scene, previous, navigation }) => {
                    // console.log("scene", previous)
                    return (
                        Platform.OS === "web" ?
                            <HeaderWeb navigation={navigation} />
                            :
                            <Header
                                navigation={navigation}
                                logo={previous ? false : true}
                                backCallback={() => navigation.goBack()}
                                backButton={previous ? true : false}
                                editProfile={true}
                                logout={true}
                            />
                    )
                }
            }}
        >
            <Stack.Screen name="ProfileHome" component={ProfileHome} options={{
                title: "Mi Perfil"
                // header: props => {
                //     Platform.OS === "web" ?
                //         <HeaderWeb navigation={props.navigation} />
                //         :
                //         <Header social socialCallback={() => props.navigation.navigate("MyReviews")} navigation={props.navigation} logo={true} backButton={false} logout={true} editProfile={true} notificationButton={false} searchButton={false} />
                // }
            }} />
            <Stack.Screen name="MyReviews" component={MyReviews} options={{ header: ({ scene, previous, navigation }) => <Header navigation={navigation} backCallback={() => navigation.goBack()} backButton={true} /> }} />
            <Stack.Screen name="EditProfile" component={EditProfile}
                options={{
                    title: "Editar mi Perfil",
                    header: ({ scene, previous, navigation }) => {
                        return (
                            <Header
                                logo={false}
                                backButton={true}
                                backCallback={() => navigation.goBack()}
                                logout={false}
                                editProfile={false}
                                notificationButton={false}
                                addUsers={false}
                                searchButton={false}
                                saveChanges={true}
                                saveDeactivated={scene.route.params && scene.route.params.disabled}
                                saveChangesCallback={() => scene.route.params && scene.route.params.handleSave()}
                            />
                        )
                    }
                }}
            />
        </Stack.Navigator>
    );
}

export default StackProfile;