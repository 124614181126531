import React from "react";
import { View, Text, TouchableOpacity, Platform, ActivityIndicator, TextInput, ScrollView } from "react-native";
import Button from "../common/Button";

const CreateTopicForum = ({
  title,
  setTitle,
  body,
  setBody,
  sendNewTopic,
  styles,
  forum,
  loading = false,
  setForum,
  select = false,
  dataForum,
  complete
}) => {
  return (
    <ScrollView contentContainerStyle={{ padding: 15, flexGrow: 1, width: "100%", maxWidth: 900, alignSelf: "center" }}>

      <Text style={{ fontFamily: "DemiBold", fontSize: 20, color: "darkgray" }}>Título: </Text>

      <TextInput
        placeholder="Título"
        style={{
          marginTop: 10,
          borderWidth: 1,
          backgroundColor: "white",
          borderColor: "gainsboro",
          padding: 10,
          paddingHorizontal: 15,
          borderRadius: 25,
          height: 50,
          fontSize: 16,
          fontFamily: "Regular",
          marginBottom: 15
        }}
        value={title || ""}
        onChangeText={e => setTitle(e)}
      />

      <Text style={{ fontFamily: "DemiBold", fontSize: 20, color: "darkgray" }}>Temática del Hilo</Text>

      <TextInput
        value={body || ""}
        onChangeText={e => setBody(e)}
        multiline
        numberOfLines={6}
        {...Platform.OS !== "web" && { textAlignVertical: "top" }}
        placeholder="Descripción del Hilo"
        style={{
          marginTop: 10,
          borderWidth: 1,
          borderColor: "gainsboro",
          padding: 10,
          paddingHorizontal: 15,
          borderRadius: 25,
          height: 150,
          backgroundColor: "white",
          maxHeight: 250,
          paddingTop: 15,
          fontSize: 16,
          fontFamily: "Regular",
          marginBottom: 15
        }}
      />

      <View style={{ alignItems: "center" }}>
        <Button
          onPress={() => (sendNewTopic())}
          label="Crear Hilo"
          style={{maxWidth: 250, alignSelf:"center"}}
          loading={loading}
          disabled={title === "" || body === ""}
        />
      
      </View>
    </ScrollView>
  );
};

export default CreateTopicForum;
