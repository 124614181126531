import React, { useContext } from "react";
import {
  Text,
  View,
  Dimensions,
  StyleSheet,
  ScrollView,
  TouchableOpacity,
  Image
} from "react-native";

import { LinearGradient } from 'expo-linear-gradient';
import { Ionicons } from "@expo/vector-icons";

import { useNavigation } from '@react-navigation/native';

import AuthContext from "../../context/AuthContext";

import Modal from '../../components/common/Modal'
import Button from "../../components/common/Button";
import { decideColor } from "../../utils/functions"

const SCREEN_WIDTH = Dimensions.get("window").width;
const SCREEN_HEIGHT = Dimensions.get("window").height;

const WelcomeTraining = ({ mission, visible, missionID, setVisible, itineraryavID }) => {

  const { navigate } = useNavigation();
  const { customization } = useContext(AuthContext)

  return (
    visible &&
    <Modal
      padding={0}
      onRequestClose={() => setVisible(false)}
    >
      <View style={{ maxWidth: 400, minWidth: 400}}>
        <View
          // colors={[customization.mainGradientPrimaryColor, customization.mainGradientSecondaryColor]}
          style={{
            width: "100%",
            paddingBottom: 50,
            paddingTop: 40,
            paddingHorizontal: 10,
            borderTopRightRadius: 10,
            borderTopLeftRadius: 10,
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: customization.mainColor
          }}>

          <TouchableOpacity
            onPress={() => { setVisible(false); }}
            style={{ height: 50, width: 50, alignItems: "center", justifyContent: "center", position: "absolute", top: 10, right: 10 }}
          >
            <Ionicons name={"ios-close"} size={50} style={{ color: decideColor(customization.mainColor) }} />
          </TouchableOpacity>

          <View style={{ margin: 25, marginHorizontal: 15, alignItems: "center" }}>
            {/* <Text style={styles.title}>Misión</Text> */}
            <Text numberOfLines={3} style={[styles.title, { color: decideColor(customization.mainColor) }]}>{mission.title}</Text>
          </View>

          <View style={{ alignItems: "center", justifyContent: "center", marginTop: 20 }}>
            <View style={styles.imageCircleTwo} />
            <View style={styles.imageCircle} />
            {/* <ImageRel style={styles.image} image={mission.image} /> */}
            <Image style={styles.image} source={{ uri: mission.image }} />
          </View>

        </View>

        <View style={{ paddingBottom: 25, alignItems: "center" }}>

          {/* INFO PUNTOS */}
          <View style={{ height: 50, marginVertical: 10, flexDirection: "row", alignItems: "center" }}>
            <Text style={{ fontFamily: "DemiBold", fontSize: 22 }}>+{mission.score}</Text>
            <LinearGradient
              colors={['#F49D44', '#FED25A']}
              style={{ marginLeft: 10, height: 30, width: 30, borderRadius: 15, backgroundColor: "#FCB84F", alignItems: "center", justifyContent: "center" }}>
              <Ionicons name={"ios-star"} size={18} style={{ color: "white" }} />
            </LinearGradient>
          </View>



          {/* DESCRIPCION */}
          <View style={{ flexDirection: "row", maxWidth: 500, paddingHorizontal: 20, justifyContent: "space-between" }}>
            <Ionicons name={"ios-information-circle"} size={25} color="black" style={{ marginRight: 10, marginTop: 7 }} />
            <ScrollView showsVerticalScrollIndicator={false} contentContainerStyle={{ padding: 10 }} style={{ backgroundColor: "#f3f3f3", maxHeight: 150, borderRadius: 10, width: "90%" }}>
              <Text style={{ fontFamily: "DemiBold", fontSize: 14 }}>{mission.description}</Text>
            </ScrollView>
          </View>


          <Button
            style={{ ...styles.startButton, marginTop:20 }}
            onPress={() => {
              navigate("Mission", {
                itineraryavID,
                id: missionID,
                missionType: mission.type
              });
              setVisible(false);
            }}
            color={customization.mainColor}
            round={10}
            height={50}
            width={200}
            label="Comienza"
          />

        </View>
      </View>

    </Modal>
  );
};

export default WelcomeTraining;

const styles = StyleSheet.create({
  view: {
    height: "80%",
    width: SCREEN_WIDTH,
    justifyContent: "space-between",
    alignItems: "center"
  },
  image: {
    width: SCREEN_WIDTH / 2,
    height: SCREEN_WIDTH / 2,
    maxWidth: 250,
    maxHeight: 250,
    borderRadius: SCREEN_WIDTH / 4,
  },
  imageCircle: {
    width: (SCREEN_WIDTH / 2) + 30,
    height: (SCREEN_WIDTH / 2) + 30,
    borderRadius: (SCREEN_WIDTH / 4) + 30,
    maxWidth: 300,
    maxHeight: 300,
    backgroundColor: "white",
    position: "absolute",
    opacity: .3
  },
  imageCircleTwo: {
    width: (SCREEN_WIDTH / 2) + 60,
    height: (SCREEN_WIDTH / 2) + 60,
    maxWidth: 320,
    maxHeight: 320,
    borderRadius: (SCREEN_WIDTH / 4) + 60,
    backgroundColor: "white",
    position: "absolute",
    opacity: .2
  },
  title: {
    color: "white",
    fontFamily: "DemiBold",
    fontSize: 22
  },
  description: {
    fontSize: 20
  },
  startButton: {
    marginTop: 30,
    height: 50,
    borderRadius: 25,
    alignItems: "center",
    justifyContent: "center",
    width: 300
  },
  startButtonText: {
    fontFamily: "DemiBold",
    fontSize: 16,
    color: "white"
  }
});
