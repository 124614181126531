import React, { useState, useContext, useEffect, useRef } from 'react';
import { View, Text, TouchableOpacity, Image, Dimensions, Platform, StyleSheet, StatusBar, ActivityIndicator } from 'react-native'
import { Ionicons } from '@expo/vector-icons'
import * as Animatable from "react-native-animatable"
import ImageRel from "./common/ImageRel"
import AuthContext from '../context/AuthContext'
import Svg, { Path, Defs } from 'react-native-svg';

import { useSafeArea } from 'react-native-safe-area-context';


let SCREEN_WIDTH = Dimensions.get("window").width;
let SCREEN_HEIGHT = Dimensions.get("window").height;

const Header = ({
    backCallback,
    navigation,
    searchCallback,
    logo,
    minimal,
    style,
    drawerStatus,
    myCourses,
    exam,
    callbackDrawer,
    showLast,
    addPeople,
    toogleSmartCallback,
    smartContents,
    callbackTab,
    editProfile,
    logout,
    userAvatar,
    backWhite,
    chatTitle,
    isTransparent,
    backButton,
    searchButton,
    saveChanges,
    saving,
    saveDeactivated,
    saveChangesCallback,
    notificationButton,
    newThreadCallback,
    newThread,
    social,
    socialCallback
}) => {

    const { authFunctions, userData, customization } = useContext(AuthContext);

    const [toogled, setToogled] = useState(false)
    // const [logoSize, setLogoSize] = useState({
    //     width: 50,
    //     height: 50
    // })

    const waveRef = useRef()
    const transparent = isTransparent || minimal && { position: 'absolute', top: 0, left: 0, zIndex: 1 }
    const shadow = minimal && { shadowColor: "#000", shadowOffset: { width: 0, height: 2, }, shadowOpacity: 0.23, shadowRadius: 2.62, }

    const insets = useSafeArea();

    const getRandomInt = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    // useEffect(() => {
    //     customization.logo && Image.getSize(customization.logo, (width, height) => { setLogoSize({ width, height }) })
    // }, [customization])

    const randomPoints = (minSpread, maxSpread) => {

        const iteractions = 6
        const a = new Array(iteractions).fill(5)
        const variation = 30
        let t = .5;

        const points = a.map((e, i, arr) => {
            const sep = SCREEN_WIDTH / iteractions
            const randP = getRandomInt(i * sep, sep * (i + 1))
            const point = i === arr.length - 1 ? [SCREEN_WIDTH, getRandomInt(0, variation)] : [randP, getRandomInt(0, variation)]
            return point
        })

        return `
        M0,0
        ${svgPath(points, bezierCommand, variation)}
        L${SCREEN_WIDTH},0
        Z
        `
        // L${SCREEN_WIDTH},${getRandomInt(0, variation)}
    }

    const svgPath = (points, command, variation) => {
        // build the d attributes by looping over the points
        const d = points.reduce((acc, point, i, a) => i === 0
            // if first point
            ? `L0,${getRandomInt(0, variation)}`
            // `L ${point[0]},${point[1]}`
            // else
            : `${acc} ${command(point, i, a)}`
            , '')
        return d
    }

    const bezierCommand = (point, i, a) => {
        // start control point
        const [cpsX, cpsY] = controlPoint(a[i - 1], a[i - 2], point)
        // end control point
        const [cpeX, cpeY] = controlPoint(point, a[i - 1], a[i + 1], true)
        return `C ${cpsX},${cpsY} ${cpeX},${cpeY} ${point[0]},${point[1]}`
    }

    const controlPoint = (current, previous, next, reverse) => {
        // When 'current' is the first or last point of the array
        // 'previous' or 'next' don't exist.
        // Replace with 'current'
        const p = previous || current
        const n = next || current
        // The smoothing ratio
        const smoothing = 0.15
        // Properties of the opposed-line
        const o = line(p, n)
        // If is end-control-point, add PI to the angle to go backward
        const angle = o.angle + (reverse ? Math.PI : 0)
        const length = o.length * smoothing
        // The control point position is relative to the current point
        const x = current[0] + Math.cos(angle) * length
        const y = current[1] + Math.sin(angle) * length
        return [x, y]
    }

    const line = (pointA, pointB) => {

        const lengthX = pointB[0] - pointA[0]
        const lengthY = pointB[1] - pointA[1]
        return {
            length: Math.sqrt(Math.pow(lengthX, 2) + Math.pow(lengthY, 2)),
            angle: Math.atan2(lengthY, lengthX)
        }
    }


    return (
        <View >
            <View
                style={[customization.headerWithShadow ? styles.shadow : null, {
                    width: minimal ? 80 : "100%",
                    flexDirection: 'row',
                    justifyContent: "space-between",
                    alignItems: 'flex-end',
                    paddingTop: insets.top,
                    paddingLeft: insets.left,
                    paddingRight: insets.right,
                    paddingBottom: 5,
                    height: 55 + insets.top,
                    backgroundColor: transparent ? "transparent" : customization.headerBackgroundColor,
                    ...style
                }]}
            >


                {/* BOTONES SELECCION  */}
                {backButton && <TouchableOpacity
                    style={{
                        alignSelf: 'flex-start',
                        borderRadius: minimal ? 25 : 0,
                        backgroundColor: minimal ? "white" : 'transparent',
                        width: 50,
                        height: 50,
                        marginLeft: minimal ? 10 : 0,
                        marginTop: minimal ? 10 : 0,
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                    onPress={() => backCallback()}
                >
                    <Ionicons name="ios-arrow-back" size={30} color={minimal ? customization.mainColor : customization.headerElementsColor} />
                </TouchableOpacity>}

                {logo &&
                    <View style={{ height: 40, marginBottom: 5, marginLeft: 10, alignItems: "center", justifyContent: "flex-start" }}>
                        {customization.logo ?
                            <Image
                                source={{ uri: customization.logo }}
                                resizeMode={"contain"}
                                style={{ height: 40, aspectRatio: customization.logoSize ? customization.logoSize.width / customization.logoSize.height : 1 }}
                            /> :
                            null
                        }
                    </View>
                }



                <View style={{ flexDirection: 'row' }}>

                    {userAvatar !== undefined &&
                        <Animatable.View animation="fadeInUp" duration={400} style={{ flexDirection: "row", alignItems: "center", justifyContent: "flex-end", minWidth: 200 }}>
                            <View style={{ marginLeft: 20, maxWidth: 200 }}>
                                <Text style={{ fontSize: 16, color: customization.headerElementsColor, textAlign: "right", fontFamily: "DemiBold" }}>{chatTitle}</Text>
                            </View>
                            <ImageRel
                                image={userAvatar}
                                style={{ width: 40, height: 40, borderRadius: 20, marginHorizontal: 10 }}
                            />
                        </Animatable.View>
                    }

                    {/* {searchButton && <TouchableOpacity
                    style={{
                        width: 50,
                        height: 50,
                        alignItems: "center",
                        justifyContent: "center"
                    }}
                    onPress={() => searchCallback()}
                >
                    <Ionicons name="md-search" size={30} color={isTransparent ? "white" : "black"} />
                </TouchableOpacity>} */}

                    {/* NOTIFICACIONES */}
                    {notificationButton && <TouchableOpacity
                        style={{
                            width: 50,
                            height: 50,
                            alignItems: "center",
                            justifyContent: "center"
                        }}
                        onPress={() => navigation.navigate("Notifications")}
                    >
                        <Ionicons name="md-notifications" size={30} color={isTransparent ? "white" : customization.headerElementsColor} />
                    </TouchableOpacity>}
                    {social &&
                        <TouchableOpacity onPress={() => { socialCallback() }} style={{ borderRadius: 25, height: 45, flexDirection: "row", width: 50, alignItems: 'center', justifyContent: 'center' }}>
                            {/* <Text style={{ fontFamily: 'DemiBold', marginRight:10 }}>{!toogled ? "Ver Contenidos" : "Ocultar Contenidos"}</Text> */}
                            <Ionicons color={customization.headerElementsColor} name={"md-medal"} size={32} />
                        </TouchableOpacity>
                    }
                    {editProfile && <TouchableOpacity
                        style={{
                            width: 50,
                            height: 50,
                            alignItems: "center",
                            justifyContent: "center"
                        }}
                        onPress={() => navigation.navigate('EditProfile')}
                    >
                        <Ionicons name="md-contact" size={30} color={isTransparent ? "white" : customization.headerElementsColor} />
                    </TouchableOpacity>}

                    {myCourses && <TouchableOpacity
                        style={{
                            width: 50,
                            height: 50,
                            alignItems: "center",
                            justifyContent: "center"
                        }}
                        onPress={() => navigation.navigate('MyCourses')}
                    >
                        <Ionicons name="md-trophy" size={30} color={isTransparent ? "white" : customization.headerElementsColor} />
                    </TouchableOpacity>}

                    {/* {addPeople && <TouchableOpacity
                    style={{
                        width: 50,
                        height: 50,
                        alignItems: "center",
                        justifyContent: "center"
                    }}
                    onPress={() => navigation.navigate('UserList')}
                >
                    <Ionicons name="md-person-add" size={30} color={isTransparent ? "white" : "black"} />
                </TouchableOpacity>
                } */}


                    {/* GUARDAR CAMBIOS */}
                    {saveChanges && (
                        <TouchableOpacity
                            disabled={!saveDeactivated}
                            style={{
                                width: "auto",
                                paddingRight: 10,
                                height: 50,
                                alignItems: "center",
                                justifyContent: "center",

                            }}
                            onPress={() => saveChangesCallback()}
                        >
                            {!saving ?
                                <View style={{
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexDirection: "row"
                                }}>
                                    <Text style={{ opacity: !saveDeactivated ? 0.5 : 1.0, marginRight: 5, fontFamily: "Bold", fontSize: 16, color: saveDeactivated ? "white" : customization.headerElementsColor }}>Guardar</Text>
                                    <Ionicons
                                        name="ios-checkmark-circle"
                                        size={30}
                                        style={{ opacity: !saveDeactivated ? 0.5 : 1.0 }}
                                        color={saveDeactivated ? "white" : isTransparent ? "white" : customization.headerElementsColor}
                                    />
                                </View>
                                :
                                <ActivityIndicator />}
                        </TouchableOpacity>
                    )}


                    {/* Nuevo Hilo */}
                    {newThread && (
                        <TouchableOpacity
                            style={{
                                width: "auto",
                                flexDirection: "row",
                                paddingRight: 10,
                                height: 50,
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                            onPress={() => newThreadCallback()}
                        >
                            <Text style={{ fontFamily: "DemiBold", fontSize: 18, color: customization.headerElementsColor }}>Nuevo Hilo</Text>
                            <Ionicons style={{ marginLeft: 10 }} name="md-add-circle" size={30} color={customization.headerElementsColor} />
                        </TouchableOpacity>
                    )}

                    {logout && <TouchableOpacity
                        style={{
                            width: 50,
                            height: 50,
                            alignItems: "center",
                            justifyContent: "center"
                        }}
                        onPress={() => authFunctions.signOut()}
                    >
                        <Ionicons name="md-exit" size={30} color={isTransparent ? customization.mainColor : customization.headerElementsColor} />
                    </TouchableOpacity>}

                    {smartContents &&
                        <TouchableOpacity onPress={() => { toogleSmartCallback(); setToogled(!toogled) }} style={{ borderRadius: 25, height: 45, flexDirection: "row", width: 50, alignItems: 'center', justifyContent: 'center' }}>
                            {/* <Text style={{ fontFamily: 'DemiBold', marginRight:10 }}>{!toogled ? "Ver Contenidos" : "Ocultar Contenidos"}</Text> */}
                            <Ionicons color={customization.headerElementsColor} name={toogled ? "md-close" : "md-more"} size={32} />
                        </TouchableOpacity>
                    }

                </View>

            </View>
        </View>
    )
};



const styles = StyleSheet.create({
    shadow: {
        shadowColor: "rgba(0, 0, 0, 0.3)",
        shadowOffset: { width: 0, height: 5 },
        shadowOpacity: 0.4,
        shadowRadius: 10,
        elevation: 10,
    }
});

export default Header