// 
// ESTE HIGHER ORDER COMPONENT HACE DE MIDDLEWARE PARA POODER PASAR ASINCROONAMENTE 
// EL ID DEL USUARIO GUARDADO EN EL ASYNCSTORAGE
// 

import React, { Component, useState, useEffect } from 'react';
import { AsyncStorage, Platform } from 'react-native'
import moment from 'moment'


export default function withLocalStorage(WrappedComponent) {
    return class extends Component {
        constructor(props) {
            super(props)
            this.state = {
                jwt: '',
                id: '',
                cache: [],
                filtro: [],
                resetPass: false
            }
            this.getjwt()
        }

        async getjwt() {
            if(Platform.OS !== "web"){
                this.setState({
                    id: await AsyncStorage.getItem('id'),
                    jwt: await AsyncStorage.getItem('jwt'),
                    cache: await AsyncStorage.getItem('cache'),
                    filtro: await AsyncStorage.getItem('user'),
                    resetPass:  await AsyncStorage.getItem('resetPass') === "true" ? true : false,
                    company: await AsyncStorage.getItem('company'),
                    today : moment().format()
                })
            } else {
                this.setState({
                    id: await localStorage.getItem('id'),
                    jwt: await localStorage.getItem('jwt'),
                    cache: await localStorage.getItem('cache'),
                    filtro: await localStorage.getItem('user'),
                    resetPass:  await localStorage.getItem('resetPass') === "true" ? true : false,
                    company: await localStorage.getItem('company'),
                    today : moment().format()
                })
            }

        }


        render() {
            if (this.state.id === "") {
                return null
            }
            return <WrappedComponent cache={this.state.cache} today={this.state.today} company={this.state.company} id={this.state.id} jwt={this.state.jwt} filtro={this.state.filtro} resetPass={this.state.resetPass} {...this.props} />
        }

    }
}