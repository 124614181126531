import React, { useContext } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  ActivityIndicator
} from "react-native";
import { useNavigation } from '@react-navigation/native';
import { useSafeArea } from 'react-native-safe-area-context';
import { LinearGradient } from "expo-linear-gradient";

import CardFeaturedPost from "../CardFeaturedPost";
import AuthContext from "../../context/AuthContext";
import { decideColor } from "../../utils/functions"
import { Video } from 'expo-av'
import { useEffect } from "react";
import { useState } from "react";
import { storage } from "firebase";

const PostMission = ({ data, userResponse, completed, loader, responseCallback }) => {


  const { customization } = useContext(AuthContext)
  const [video, setVideo] = useState(null)
  const { navigate } = useNavigation();
  const insets = useSafeArea();

  useEffect(() => {
    const v = data.content.video
    v  ? storage().ref(v).getDownloadURL().then(res => setVideo(res)).catch(err => "") : null
    console.log(data.content.video)
  },[data])

  return (
    <View>

      <View style={{ backgroundColor: customization.mainColor, paddingTop: insets.top + 120, paddingHorizontal: 15, paddingBottom: 120 }} >

        <Text style={{ fontFamily: "DemiBold", fontSize: 22, color: decideColor(customization.mainColor), textAlign: "center", marginBottom: 10 }}>
          Visualiza este contenido para completar la misión
          </Text>

        <Text style={{ fontFamily: "Regular", fontSize: 14, color: decideColor(customization.mainColor), textAlign: "center", marginTop: 20 }}>
          Haz click en la caja del contenido para ir al mismo, una vez lo hayas completado haz click en el botón superior para completar la misión.
          </Text>

      </View>


      <View style={{ alignItems: "center", marginTop: -80, height:450, width: "100%", maxWidth:800, alignSelf:"center" }}>
        <Video
            style={{ width:"100%", height:"100%" }}
            resizeMode={"cover"}
            useNativeControls={true}
            progressUpdateIntervalMillis={1000}
            source={{ uri: video }}
        />
      </View>

      {!userResponse &&
        <View style={{ position: "absolute", top: 0, right: -20, marginBottom: insets.bottom + 10, marginTop: insets.top + 15 }}>
          <TouchableOpacity
            onPress={() =>
              userResponse ? navigate("TrainingHome") : responseCallback()
            }
            style={{ backgroundColor: "white", height: 50, paddingHorizontal: 35, borderRadius: 25, alignItems: "center", justifyContent: "center" }}>
            {loader ?
              <ActivityIndicator />
              :
              <Text style={{
                fontFamily: "DemiBold",
                fontSize: 16,
                color: "black"
              }}>Completar Misión</Text>
            }
          </TouchableOpacity>
        </View>}
    </View>
  );
};

export default PostMission;

const styles = StyleSheet.create({
  shadow: {
    shadowColor: "rgba(0, 0, 0, 0.3)",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.4,
    shadowRadius: 3,
    elevation: 10
  }
});