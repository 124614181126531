import React, { useContext } from 'react'
import {Platform } from 'react-native'



import ContentHome from "../screens/stackContent/ContentHome";
import Post from "../screens/stackContent/Post";
import Comments from "../screens/stackContent/Comments";
import Category from "../screens/stackContent/Category"
import Search from "../screens/stackContent/Search"
import Notifications from "../screens/stackContent/Notifications"
// import SubCategory from "../screens/stackContent/SubCategory"
import Header from '../components/AppHeader'

import { createStackNavigator } from '@react-navigation/stack';
import HeaderWeb from '../components/HeaderWeb';

const Stack = createStackNavigator();



const StackContent = ({ route, navigation }) => {

    return (
        <Stack.Navigator
            // headerMode="screen"
            screenOptions={{
                cardStyle: { flex: 1 },
                
                header: ({ scene, previous, navigation }) => {
                    return (
                        Platform.OS === "web" ?
                            <HeaderWeb navigation={navigation} />
                            :
                            <Header
                                backCallback={() => navigation.goBack()}
                                backButton={previous}
                                logo={!previous}
                            />
                    )
                }
            }}

        >
            <Stack.Screen name="ContentHome" component={ContentHome} />
            <Stack.Screen name="Post" component={Post} options={{ title: "Contenido" }}/>
            <Stack.Screen name="Comments" component={Comments} options={{ title: "Comentarios" }} />
            <Stack.Screen name="Category" component={Category} options={{ title: "Categoría" }}/>
            {/* <Stack.Screen name="Search" component={Search} options={{header: () => <View />}}/> */}
            {/* <Stack.Screen name="Notifications" component={Notifications} /> */}
        </Stack.Navigator>
    );
}


export default StackContent;



// const config = {
//     animation: 'spring',
//     config: {
//         stiffness: 1000,
//         damping: 50,
//         mass: 3,
//         overshootClamping: false,
//         restDisplacementThreshold: 0.01,
//         restSpeedThreshold: 0.01,
//     },
// };

// const MyTransition = {
//     gestureDirection: 'horizontal',
//     transitionSpec: {
//         open: TransitionSpecs.TransitionIOSSpec,
//         close: TransitionSpecs.TransitionIOSSpec,
//     },
//     headerStyleInterpolator: HeaderStyleInterpolators.forFade,
//     cardStyleInterpolator: ({ current, next, layouts }) => {
//         return {
//             cardStyle: {
//                 transform: [
//                     {
//                         translateY: current.progress.interpolate({
//                             inputRange: [0, 1],
//                             outputRange: [layouts.screen.width, 0],
//                         }),
//                     }
//                 ],
//                 opacity: next
//                     ? next.progress.interpolate({
//                         inputRange: [0, 1],
//                         outputRange: [1, 0],
//                     })
//                     : current.progress.interpolate({
//                         inputRange: [0, 1],
//                         outputRange: [0, 1],
//                     }),
//             },
//             overlayStyle: {
//                 backgroundColor: "red",
//                 opacity: current.progress.interpolate({
//                     inputRange: [0, 1],
//                     outputRange: [0, 0.5],
//                 }),
//             },
//         };
//     },
// }

// const forFade = ({ current, next }) => {
//     const opacity = Animated.add(
//         current.progress,
//         next ? next.progress : 0
//     ).interpolate({
//         inputRange: [0, 1, 2],
//         outputRange: [0, 1, 0],
//     });

//     const top = Animated.add(
//         current.progress,
//         next ? next.progress : 0
//     ).interpolate({
//         inputRange: [0, 1, 2],
//         outputRange: [-100, 0, 100],
//     });

//     return {
//         leftButtonStyle: { opacity, transform: [{ translateY: top }] },
//         rightButtonStyle: { opacity },
//         titleStyle: { opacity },
//         backgroundStyle: { opacity },
//     };
// };

// const getActiveRouteName = state => {
//     const route = state.routes[state.index];

//     if (route.state) {
//         // Dive into nested navigators
//         return getActiveRouteName(route.state);
//     }

//     return route.name;
// };