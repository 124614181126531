const URL_API_DEV = "http://52.50.65.127:1350"
const URL_API_PROD = "https://apilapolar.uno.work"

export default {

    URL_API: URL_API_PROD,

    Scorm_view: "",
    Webinar_view: "http://webinarview.s3-website-eu-west-1.amazonaws.com"
};
