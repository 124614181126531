import React from 'react';
import {
    View,
    Text,
    TouchableHighlight,
    StyleSheet,
    Dimensions,
    Platform,
} from "react-native";
import * as Animatable from "react-native-animatable";
import moment from 'moment'
import ImageRel from '../components/common/ImageRel'

let SCREEN_HEIGHT = Dimensions.get("window").height;

const postdestacado = ({clickCallback, position, title, image, publishDate, cardStyle }) => {

    const date = moment(publishDate).format("DD [de] MMMM YY")


    return (
        <Animatable.View
            key={position}
            delay={300}
            animation="fadeIn"
            style={[
                styles.shadow,
                {
                    height: SCREEN_HEIGHT/2.3,
                    marginTop: 5,
                    marginHorizontal: 10,
                    borderRadius: 15,
                    marginBottom: Platform.OS === "android" ? 15 : 0,
                    ...cardStyle
                }
            ]}
        >
            <TouchableHighlight
                onPress={() => clickCallback()}
                style={{ flex: 1, borderRadius: 15 }}
            >
                <View
                    style={{
                        backgroundColor: "#fff",
                        flex: 1,
                        borderRadius: 15,
                    }}
                >

                    <ImageRel
                        source={image}
                        style={{
                            flex: 1,
                            width:"100%",
                            height: SCREEN_HEIGHT/2.3,
                            borderRadius: 15,
                            resizeMode: "cover",
                            position:'absolute',
                            top:0,
                            left:0,
                            zIndex:1,
                            
                        }}
                    />
                    <View
                        style={{
                            position: 'absolute',
                            bottom:15,
                            left:15,
                            right:15,
                            zIndex:2,
                            elevation:2,
                            backgroundColor:'white',
                            borderRadius:15,
                            paddingHorizontal:10,
                            paddingVertical:15
                        }}
                    >
                        <Text style={{ fontSize:18, fontFamily:'DemiBold' }} >{title}</Text>
                        <Text style={{ textAlign:'left',fontSize: 14, marginTop:10, color: 'gray', fontFamily: 'Regular' }}>{date}</Text>
                    </View>
                </View>
            </TouchableHighlight>
        </Animatable.View>
    )
}

export default postdestacado;

const styles = StyleSheet.create({
    shadow: {
        shadowColor: "rgba(0, 0, 0, 0.3)",
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.4,
        shadowRadius: 3,
        elevation: 10
    }
});